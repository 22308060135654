import React, { ReactNode, memo } from "react"
import { FormattedMessage } from "react-intl"

import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import MuiBox from "@mui/material/Box"
import Icon from "@stakefish/ui/components/Icon"
import Button from "@stakefish/ui/components/Button"
import Typography from "@stakefish/ui/components/Typography"
import Status from "@stakefish/ui/components/Status"

import { Explanation } from "../assets/Explanation"
import { LINK_CONTACT } from "../consts/stakefish"
import { BroadcastState, StakingStates, StakingState } from "../types/staking"
import BlockLayout from "../components/BlockLayout"

/**
 * Types
 */
interface TxBroadcastedProps {
  data: {
    [StakingState.TX_BROADCAST_STATE]: StakingStates["txBroadcastState"]
    [StakingState.STAKE_AMOUNT]: Exclude<StakingStates["stakeAmount"], undefined>
    [StakingState.GAS_COST]: StakingStates["gasCost"]
    [StakingState.TX_BROADCAST_RESULT]: StakingStates["txBroadcastResult"]
    dashboardUrl?: string
  }
}

/**
 * Constants
 */
const copyData = {
  [BroadcastState.Positive]: ({ dashboardUrl }: { dashboardUrl?: string }) => ({
    title: <FormattedMessage id="DEPOSIT_TRANSACTION_HAS_BEEN_BROADCASTED" />,
    blurb: (
      <FormattedMessage
        id="WE_ARE_WAITING_FOR_YOUR_TRANSACTION"
        values={{
          // TODO: handle undefined URL
          url: (...chunks: string[]) => (
            <a href={dashboardUrl ?? "#"} target="_blank" rel="noopener noreferrer">
              {chunks}
            </a>
          )
        }}
      />
    ),
    summary: {
      title: <FormattedMessage id="TRANSACTION_SUMMARY" />,
      amount: <FormattedMessage id="DEPOSIT_AMOUNT" />,
      gas: <FormattedMessage id="GAS_COST" />,
      validatorsAmount: <FormattedMessage id="ESTIMATED_AMOUNT_OF_VALIDATORS" />,
      tx: <FormattedMessage id="ETH1_DEPOSIT_TRANSACTION_ID" />,
      txLink: <FormattedMessage id="VIEW_ON_ETHERSCAN" />
    },
    ctaButton: <FormattedMessage id="VIEW_YOUR_VALIDATORS_ON_THE_DASHBOARD" />,
    status: (
      <Status variant="secondary" color="positive" iconKey="successCircle">
        <FormattedMessage id="SUCCESS" />
      </Status>
    )
  }),
  [BroadcastState.Negative]: ({ errorMsg }: { errorMsg?: ReactNode }) => ({
    title: <FormattedMessage id="DEPOSIT_TRANSACTION_HASNT_BEEN_BROADCASTED" />,
    // TODO: replace this default error message.
    blurb: (
      <FormattedMessage
        id="GO_BACK_TO_THE_PREVIOUS_STEP_AND_TRY_AGAIN"
        values={{
          break: <br />,
          error: errorMsg ?? <FormattedMessage id="ERR_GENERAL" />,
          url: (...chunks: string[]) => (
            <a href={LINK_CONTACT} target="_blank" rel="noopener noreferrer">
              {chunks}
            </a>
          )
        }}
      />
    ),
    summary: null,
    ctaButton: <FormattedMessage id="BACK_TO_SIGN_AND_BROADCAST" />,
    status: (
      <Status variant="secondary" color="negative" iconKey="errorCircle">
        <FormattedMessage id="ERROR" />
      </Status>
    )
  }),
  // TODO: add a UI state for pending status
  [BroadcastState.Pending]: () => ({
    title: <FormattedMessage id="DEPOSIT_IS_STILL_BROADCASTING" />,
    blurb: (
      <FormattedMessage
        id="IF_THIS_TAKES_AN_EXCESSIVE_AMOUNT_OF_TIME"
        values={{
          url: (...chunks: string[]) => (
            <a href={LINK_CONTACT} target="_blank" rel="noopener noreferrer">
              {chunks}
            </a>
          )
        }}
      />
    ),
    summary: null,
    ctaButton: <FormattedMessage id="BACK_TO_SIGN_AND_BROADCAST" />,
    status: null
  })
}

/**
 * Styles
 */
const IdLinkBox = styled(MuiBox)({
  "& button": {
    paddingLeft: 0
  }
})

const TableBox = styled(MuiBox)(({ theme }) => ({
  paddingBlockStart: theme.spacing(5),
  wordBreak: "break-word",

  "& table": {
    textAlign: "left",
    tableLayout: "fixed",
    width: "100%",
    borderSpacing: theme.spacing(0, 1)
  },
  "& caption": {
    textAlign: "left",
    marginBottom: theme.spacing(2)
  },
  "& th": {
    width: "30%",
    verticalAlign: "baseline"
  },
  "& td": {
    verticalAlign: "baseline",
    paddingLeft: theme.spacing(3),
    wordWrap: "break-word",

    ".MuiButton-text": {
      background: "none",
      fontWeight: theme.typography.fontWeightMedium
    }
  },

  [theme.breakpoints.down("sm")]: {
    paddingBlockStart: theme.spacing(2),

    "table, tr, td, th": {
      display: "block",
      width: "100%",
      padding: 0
    },
    th: {
      marginBottom: theme.spacing(0.75)
    },
    tr: {
      marginBottom: theme.spacing(2),

      ".MuiButton-text": {
        fontSize: "inherit",
        flexDirection: "row-reverse",
        display: "inline-flex",

        ".MuiButton-startIcon": {
          marginLeft: 5
        }
      }
    }
  }
}))

const StyledTypography = styled("span")(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightRegular
}))

const StepBox = styled("div")<{ $state: BroadcastState }>(({ theme, $state }) => {
  const bgColor = () => {
    if ($state === BroadcastState.Pending) return theme.palette.gray[300]
    if ($state === BroadcastState.Positive) return theme.palette.positive.light
    return theme.palette.negative.light
  }

  return {
    "& .MuiPaper-root": {
      backgroundColor: bgColor()
    }
  }
})

/**
 * Renderers
 */
const getStakeSummaryData = (props: TxBroadcastedProps["data"]) => {
  const { stakeAmount, gasCost, txBroadcastResult } = props
  const copies = copyData.positive({}).summary

  return [
    {
      key: <StyledTypography>{copies.amount}</StyledTypography>,
      value: `${stakeAmount} ETH`
    },
    gasCost
      ? {
          key: <StyledTypography>{copies.gas}</StyledTypography>,
          value: `${gasCost} ETH`
        }
      : null,
    txBroadcastResult?.validatorsAmount
      ? {
          key: copies.validatorsAmount,
          value: txBroadcastResult?.validatorsAmount
        }
      : null,
    {
      key: <StyledTypography>{copies.tx}</StyledTypography>,
      value: (
        <IdLinkBox>
          <div>{txBroadcastResult?.eth1Id}</div>
          <Button
            align="center"
            color="secondary"
            startIcon={<Icon color="primary" iconKey="arrowRight" rotate={-45} size="xs1" />}
            size="sm"
            variant="text"
            href={txBroadcastResult?.eth1TxUrl}
            linkProps={{
              target: "_blank",
              rel: "noopener noreferrer"
            }}
            style={{ padding: 0 }}
          >
            {copies.txLink}
          </Button>
        </IdLinkBox>
      )
    }
  ]
}

/**
 * Main
 */
const TxBroadcasted: React.FC<TxBroadcastedProps> = (props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const {
    data: { txBroadcastState, txBroadcastResult, dashboardUrl }
  } = props
  const copies = copyData[txBroadcastState]({
    dashboardUrl,
    errorMsg: txBroadcastResult?.errorMsg
  })
  const status = {
    isPositive: txBroadcastState === BroadcastState.Positive,
    isNegative: txBroadcastState === BroadcastState.Negative,
    isPending: txBroadcastState === BroadcastState.Pending
  }
  const showStatus = isMobile && (status.isPositive || status.isNegative)

  return (
    <StepBox $state={txBroadcastState}>
      {showStatus && (
        <MuiBox mt={{ xs: 0, md: -2 }} mb={0.5}>
          {copies.status}
        </MuiBox>
      )}

      <BlockLayout title={copies.title}>
        <MuiBox>
          <Typography
            variant={isMobile ? "caption" : "callout"}
            color={isMobile ? "secondary" : "primary"}
            component="p"
          >
            {copies.blurb}
          </Typography>
        </MuiBox>
      </BlockLayout>

      <MuiBox>
        {status.isPending && (
          <MuiBox my={5} mx={{ xs: 0, sm: 3 }}>
            <Explanation />
          </MuiBox>
        )}

        {status.isPositive && (
          // TODO: extract this `TableBox` component.
          // It can be reused at `Sign` step as well.
          <TableBox>
            <Typography variant={isMobile ? "caption" : "callout"} component="div">
              <table>
                <Typography variant={isMobile ? "calloutBold" : "descriptionBold"} mb={{ xs: 0.5, md: 1 }}>
                  {copies.summary?.title}
                </Typography>
                <tbody>
                  {getStakeSummaryData(props.data).map((pair, index) =>
                    pair ? (
                      <tr key={index}>
                        <th>{pair.key}</th>
                        <td>{pair.value}</td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </Typography>
          </TableBox>
        )}
      </MuiBox>
    </StepBox>
  )
}

export default memo(TxBroadcasted)
