import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import { useTheme, styled } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useClipboard } from "use-clipboard-copy"
import MuiBox from "@mui/material/Box"
import Icon from "@stakefish/ui/components/Icon"
import Button from "@stakefish/ui/components/Button"
import Typography from "@stakefish/ui/components/Typography"
import { getTruncatedAddress } from "../../utils/addressTool"
import { WalletStates } from "../../types/wallet"
import { TransactionList } from "./private"

/**
 * Types
 */
export interface ConnectedViewProps {
  walletStates: WalletStates
  onChangeClick: React.MouseEventHandler<HTMLButtonElement>
}

/**
 * Consts
 */
const ADDRESS_LENGTH_MOBILE = 8
const ADDRESS_LENGTH_DESKTOP = 14

/**
 * Styles
 */
const ActionButtonBox = styled(MuiBox)({
  "& button": {
    paddingLeft: 0
  },
  "& .MuiButton-startIcon": {
    marginLeft: 0
  }
})

export const ConnectedView = ({ walletStates, onChangeClick }: ConnectedViewProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const intl = useIntl()

  const clipboard = useClipboard({
    copiedTimeout: 1000
  })

  const handleCopyClick = useCallback(() => {
    clipboard.copy(walletStates.address)
  }, [clipboard.copy, walletStates.address])

  const [connectedWith, change, copyAddress, addressCopied, viewOnExplorer, balanceLabel, balanceValue] = [
    intl.formatMessage({ id: "CONNECTED_WITH" }, { name: walletStates.connectedWallet?.name }),
    intl.formatMessage({ id: "CHANGE" }),
    intl.formatMessage({ id: "COPY_ADDRESS" }),
    intl.formatMessage({ id: "ADDRESS_COPIED" }),
    intl.formatMessage({ id: "VIEW_ON_EXPLORER" }),
    intl.formatMessage({ id: "WALLET_BALANCE_LABEL" }),
    intl.formatMessage({ id: "WALLET_BALANCE_VALUE" }, { amount: walletStates.balance })
  ]

  if (isMobile) {
    // TODO: adjust styles [mateusz]
    return (
      <MuiBox>
        <MuiBox mb={1}>
          <Typography variant="callout">{balanceLabel}</Typography>
          <Typography variant="calloutBold">{balanceValue}</Typography>
        </MuiBox>
        <MuiBox mb={2}>
          <Typography variant="callout">{connectedWith}</Typography>
          <Typography variant="calloutBold">
            {getTruncatedAddress(walletStates.address ?? "", ADDRESS_LENGTH_MOBILE)}
          </Typography>
        </MuiBox>
        <MuiBox>
          <Button variant="outlined" size="sm" fullWidth onClick={onChangeClick}>
            {change}
          </Button>
        </MuiBox>
        <MuiBox display="flex" alignItems="center">
          <ActionButtonBox>
            <Button
              variant="text"
              color="secondary"
              size="xs"
              startIcon={<Icon iconKey="copy" size="xs1" />}
              onClick={handleCopyClick}
            >
              {clipboard.copied ? addressCopied : copyAddress}
            </Button>
          </ActionButtonBox>
          <ActionButtonBox>
            <Button
              variant="text"
              color="secondary"
              size="xs"
              startIcon={<Icon iconKey="arrowRight" size="xs1" rotate={-45} />}
              href={`https://etherscan.io/address/${walletStates.address ?? ""}`}
            >
              {viewOnExplorer}
            </Button>
          </ActionButtonBox>
        </MuiBox>
        <TransactionList walletStates={walletStates} />
      </MuiBox>
    )
  }

  return (
    <MuiBox>
      <MuiBox display="flex" justifyContent="space-between" alignItems="center">
        <MuiBox>
          <Typography variant="callout">{connectedWith}</Typography>
        </MuiBox>
        <MuiBox>
          <Button variant="outlined" size="xs" onClick={onChangeClick}>
            {change}
          </Button>
        </MuiBox>
      </MuiBox>
      <MuiBox>
        <Typography variant="descriptionBold" data-test-id="walletWidget__address">
          {getTruncatedAddress(walletStates.address ?? "", ADDRESS_LENGTH_DESKTOP)}
        </Typography>
      </MuiBox>
      <MuiBox display="flex" alignItems="center" mt={1}>
        <ActionButtonBox>
          <Button
            variant="text"
            color="secondary"
            size="xs"
            startIcon={<Icon iconKey="copy" size="xs1" />}
            onClick={handleCopyClick}
          >
            {clipboard.copied ? addressCopied : copyAddress}
          </Button>
        </ActionButtonBox>
        <ActionButtonBox>
          <Button
            variant="text"
            color="secondary"
            size="xs"
            startIcon={<Icon iconKey="arrowRight" size="xs1" rotate={-45} />}
            href={`https://etherscan.io/address/${walletStates.address ?? ""}`}
            linkProps={{ target: "_blank", rel: "noopener noreferrer" }}
          >
            {viewOnExplorer}
          </Button>
        </ActionButtonBox>
      </MuiBox>
      {/* TODO: Hiding history temporarily due to rate limit. */}
      {/* <TransactionList walletStates={walletStates} /> */}
    </MuiBox>
  )
}
