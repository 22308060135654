import { DepositPlan } from "../types/staking"
import { SupportedNetwork, SupportedNetworks } from "../types/wallet"

/**
 * Types
 */
declare global {
  interface Window {
    ethereum: any
  }
}

/**
 * Main
 */
export const isSupportedNetwork = (value: string): value is SupportedNetwork =>
  SupportedNetworks.includes(value as SupportedNetwork)

export const retrieveSaltAddressesFromContract = (contracts: DepositPlan[]) => {
  return contracts.map(({ salt }) => salt)
}
