import { Helmet } from "react-helmet"
import { META_DATA } from "../consts/stakefish"

export interface SeoProps {
  name?: string
  title?: string
  description?: string
  keywords?: string
}

export const HTML = () => {
  return (
    <Helmet>
      {/* Matomo */}
      <script>{`
        if (window.location.hostname !== 'localhost') {
          var _paq = window._paq = window._paq || [];
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="https://analytics.stake.fish/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '2']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
          })();
        }
      `}</script>
    </Helmet>
  )
}

export const SEO = (props: SeoProps) => {
  const name = props.name ?? META_DATA.name
  const title = props.title ?? META_DATA.title
  const description = props.description ?? META_DATA.description
  const keywords = props.keywords ?? META_DATA.keywords
  const suffixedTitle = `${title} | ${name}`

  return (
    <Helmet
      title={suffixedTitle}
      meta={[
        {
          name: "description",
          content: description
        },
        {
          name: "keywords",
          content: keywords
        },
        {
          property: "og:title",
          content: suffixedTitle
        },
        {
          property: "og:description",
          content: description
        },
        {
          property: "og:image",
          content: "https://stake.fish/images/website_preview_image.png"
        },
        {
          property: "og:image:type",
          content: "image/png"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:creator",
          content: "@stakefish"
        },
        {
          name: "twitter:title",
          content: suffixedTitle
        },
        {
          name: "twitter:description",
          content: description
        },
        {
          name: "twitter:image",
          content: "https://stake.fish/images/website_preview_image.png"
        }
      ]}
    />
  )
}
