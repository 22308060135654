import React, { memo, useContext, useMemo } from "react"
import { AppContext, DefaultContext } from "../context"
import { SetStep } from "../hooks/useStep"
import { useStepWizard } from "../hooks/useStepWizard"
import { GlobalState } from "../types/global"
import { snackPackProps } from "../types/snackbar"
import { AppStates, Dispatch } from "../types/context"
import { StakingStep, StakingSteps, StakingState, BroadcastState } from "../types/staking"
import { DASHBOARD_URL, MINIMUM_STAKING_VALUE } from "../consts/staking"
import { FEEDBACK_EMAIL } from "../consts/stakefish"
import ActionButtons from "../components/ActionButtons"
import FeedbackForm from "../components/FeedbackForm"
import Sign from "./Sign"
import Review from "./Review"
import StakeAmount from "./StakeAmount"
import Broadcasted from "./TxBroadcasted"

/**
 * Types
 */
export interface StepWizardProps {
  prevStep: StakingStep
  steps: StakingSteps
  setStep: SetStep
}

/**
 * Renderers
 */
const getRenderer = (state: AppStates, dispatch: Dispatch) => (currentStep: StakingStep) => {
  const renderers = {
    [StakingStep.StakeAmount]: () => (
      <StakeAmount
        data={{
          balance: state.wallet.balance,
          stakeAmount: state.staking.stakeAmount,
          exitDate: state.staking.exitDate
        }}
        onAmountChange={(amount, errorMsg) => {
          dispatch({
            type: "SET_STAKE_AMOUNT",
            payload: {
              field: StakingState.STAKE_AMOUNT,
              value: amount ?? MINIMUM_STAKING_VALUE
            }
          })

          if (state.wallet.connectedWallet) {
            dispatch({
              type: "SET_SNACKBAR_PACK",
              payload: {
                field: GlobalState.SNACKBAR_PACK,
                value:
                  errorMsg && state.wallet.connectedWallet
                    ? {
                        ...snackPackProps.insufficientFund,
                        children: errorMsg
                      }
                    : undefined
              }
            })
          }
        }}
        onExitDateChange={(date) => {
          dispatch({
            type: "SET_EXIT_DATE",
            payload: {
              field: StakingState.EXIT_DATE,
              value: date
            }
          })
        }}
      />
    ),
    [StakingStep.Review]: () => <Review />,
    [StakingStep.Sign]: () => (
      <Sign
        data={{
          stakeAmount: state.staking.stakeAmount
        }}
      />
    ),
    [StakingStep.Broadcasting]: () => (
      <Broadcasted
        data={{
          txBroadcastState: state.staking.txBroadcastState,
          stakeAmount: state.staking.stakeAmount ?? MINIMUM_STAKING_VALUE,
          gasCost: state.staking.gasCost,
          txBroadcastResult: state.staking.txBroadcastResult
        }}
      />
    ),
    [StakingStep.Done]: (dashboardUrl?: string) => (
      <Broadcasted
        data={{
          txBroadcastState: state.staking.txBroadcastState,
          stakeAmount: state.staking.stakeAmount ?? MINIMUM_STAKING_VALUE,
          gasCost: state.staking.gasCost,
          txBroadcastResult: state.staking.txBroadcastResult,
          dashboardUrl
        }}
      />
    )
  }

  return renderers[currentStep] ?? renderers[StakingStep.StakeAmount]
}

/**
 * Main
 */
const StepWizard: React.FC<StepWizardProps> = (props) => {
  const { state, dispatch } = useContext(AppContext as DefaultContext)
  const { majorButtonCallbacks, subButtonCallbacks } = useStepWizard(props)
  const dashboardUrl = useMemo(
    () =>
      state?.wallet?.address
        ? `${DASHBOARD_URL}/?address=${state?.wallet?.address}&mtm_campaign=eth-staking-2`
        : undefined,
    [state?.wallet?.address]
  )
  const showFeedbackForm =
    state.staking.txBroadcastState === BroadcastState.Positive ||
    state.staking.txBroadcastState === BroadcastState.Negative

  /**
   * Main renderer
   */
  return (
    <div>
      {getRenderer(state, dispatch)(state.staking.currentStep)(dashboardUrl)}

      <ActionButtons
        data={{
          balance: state.wallet.balance,
          connectedWallet: state.wallet.connectedWallet,
          currentStep: state.staking.currentStep,
          disableNextButton: state.global.snackbarPack?.disableNextButton,
          txBroadcastState: state.staking.txBroadcastState,
          dashboardUrl
        }}
        majorButtonCallbacks={majorButtonCallbacks}
        subButtonCallbacks={subButtonCallbacks}
      />

      {showFeedbackForm && <FeedbackForm mailto={FEEDBACK_EMAIL} />}
    </div>
  )
}

export default memo(StepWizard)
