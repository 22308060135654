import React, { useState, useContext, useEffect } from "react"

import { IntlProvider, useIntl } from "react-intl"
import Typography from "@stakefish/ui/components/Typography"

import { STAKING_STEPS } from "../consts/staking"
import { Dispatch } from "../types/context"
import { GlobalState } from "../types/global"
import { WalletWidgetState } from "../types/wallet"
import { useWallet, useStep, useEmailAccount, useMatomo } from "../hooks"
import { getTruncatedAddress } from "../utils/addressTool"
import AppContextProvider, { AppContext, DefaultContext } from "../context"
import Layout from "../components/Layout"
import RouterWizard from "../containers/RouterWizard"
import WalletWidgetWizard, { WalletWidgetWizardProps } from "../containers/WalletWidgetWizard"
import copy from "../copy"

/**
 * Types
 */
export type SetWalletWidgetStateChangeType = (
  value: React.SetStateAction<WalletWidgetWizardProps["stateChangeType"] | null>
) => void

/**
 * TODO when we have translations this locale will need to either come
 * from users navigator or our own store / api
 */
const locale = "en-EN"

/**
 * Helpers
 */
const handleModalTrigger = (open: boolean, dispatch: Dispatch) => {
  dispatch({ type: "SET_MODAL_ID", payload: { field: GlobalState.MODAL_ID, value: "walletWidget" } })
  dispatch({ type: "SET_MODAL_OPEN", payload: { field: GlobalState.MODAL_OPEN, value: open } })
}

/**
 * Main
 */
const Main = () => {
  const intl = useIntl()
  const { state, dispatch } = useContext(AppContext as DefaultContext)
  const { prevStep, activeStep, setStep } = useStep()

  const [walletWidgetState, setWalletWidgetState] = useState(WalletWidgetState.DISCONNECTED)
  const [connectingWallet, setConnectingWallet] = useState<WalletWidgetWizardProps["connectingWallet"]>(null)
  const [walletWidgetStateChangeType, setWalletWidgetStateChangeType] =
    useState<WalletWidgetWizardProps["stateChangeType"]>(null)

  const { trackEvent } = useMatomo()

  const { walletChecked, connectWallet } = useWallet({
    setWalletWidgetState,
    setWalletWidgetStateChangeType,
    setConnectingWallet,
    setStep
  })
  useEmailAccount()

  useEffect(() => {
    dispatch({
      type: "SET_LOADING_COMPONENT",
      payload: {
        field: GlobalState.LOADING_COMPONENT,
        value: {
          walletCapsule: !walletChecked,
          mainSection: !walletChecked
        }
      }
    })
  }, [walletChecked])

  /**
   * Main
   */
  return (
    <Layout
      loading={state.global.loadingComponent.walletCapsule}
      stepperProps={{
        steps: STAKING_STEPS,
        currentStep: activeStep,
        stakeAmount: state.staking.stakeAmount,
        txBroadcastState: state.staking.txBroadcastState
      }}
      headerProps={{
        walletCapsuleProps: {
          balance: state.wallet.balance,
          network: state.wallet.network,
          ...state.wallet,
          connected: !!state.wallet.connectedWallet,
          address: getTruncatedAddress(state.wallet.address ?? "", 4),
          onClick: () => {
            handleModalTrigger(true, dispatch)

            // Matomo
            trackEvent({ category: "wallet", action: state.wallet.connectedWallet ? "clickConnect" : "openModal" })
          }
        }
      }}
      modal={{
        open: state.global.modalOpen,
        activeId: state.global.modalId,
        list: {
          walletWidget: {
            onClose: () => {
              handleModalTrigger(false, dispatch)

              // `WalletWidgetWizard`: reset widget state after Modal closes.
              setTimeout(() => {
                setWalletWidgetState(
                  state.wallet.connectedWallet ? WalletWidgetState.CONNECTED : WalletWidgetState.DISCONNECTED
                )
              }, 10)
            },
            size: "xs",
            children: (
              <WalletWidgetWizard
                walletStates={state.wallet}
                currentState={walletWidgetState}
                stateChangeType={walletWidgetStateChangeType}
                connectingWallet={connectingWallet}
                onWalletButtonClick={connectWallet}
                setState={(currentState, toState) => setWalletWidgetState(toState)}
              />
            )
          },
          walletInFocusView: {
            size: "xs",
            // TODO: confirm with design on this view
            children: (
              <Typography variant="callout" align="center">
                {intl.formatMessage({ id: "PLEASE_REVIEW_AND_SIGN_USING_CONNECTED_WALLET" })}
              </Typography>
            )
          }
        }
      }}
    >
      <RouterWizard
        stepWizardProps={{
          steps: STAKING_STEPS,
          prevStep,
          setStep
        }}
        loading={state.global.loadingComponent.mainSection}
      />
    </Layout>
  )
}

const App = () => {
  return (
    <IntlProvider locale={locale} messages={copy[locale]}>
      <AppContextProvider>
        <Main />
      </AppContextProvider>
    </IntlProvider>
  )
}

export default App
