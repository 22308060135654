import React from "react"
import { useIntl } from "react-intl"
import Skeleton from "react-loading-skeleton"

import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import MuiBox from "@mui/material/Box"
import Typography from "@stakefish/ui/components/Typography"
import WalletCapsule, { WalletCapsuleProps } from "@stakefish/ui/components/WalletCapsule"

import { WalletStates } from "../types/wallet"
import { isSupportedNetwork } from "../utils/web3"
import { SUPPORTED_NETWORK_MAP } from "../consts/wallet"
import Logo from "./Logo"

export interface HeaderProps {
  loading?: boolean
  walletCapsuleProps: Omit<WalletCapsuleProps, "renderNetwork">
}

const HeaderBox = styled("header")(({ theme }) => ({
  margin: "0 auto",
  padding: theme.spacing(2.2, 3),
  backgroundColor: theme.palette.gray[100],

  [theme.breakpoints.down("sm")]: {
    padding: "11px 20px",
    backgroundColor: theme.palette.gray.white
  }
}))

const networkRenderer = (network: WalletStates["network"]) => {
  if (!network?.name) return undefined

  if (!isSupportedNetwork(network.name)) {
    const chunks = network.name.split("")
    chunks[0] = chunks[0].toUpperCase()
    return chunks.join("")
  }

  return SUPPORTED_NETWORK_MAP[network.name]
}

const Header: React.FC<HeaderProps> = ({ loading, walletCapsuleProps }) => {
  const intl = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <HeaderBox>
      <MuiBox display="flex" justifyContent="space-between" alignItems="center">
        <MuiBox display="flex" justifyContent="space-between" alignItems="center" gap={3}>
          <Logo />
          {!isMobile && <Typography variant="title4">{intl.formatMessage({ id: "ETHEREUM_STAKING" })}</Typography>}
        </MuiBox>
        <MuiBox>{/* TODO: add language dropdown (low priority) */}</MuiBox>
        <MuiBox data-test-id="walletCapsuleBox">
          {loading && <Skeleton height={isMobile ? 24 : 48} width="20vw" />}
          {!loading && <WalletCapsule {...walletCapsuleProps} renderNetwork={networkRenderer} />}
        </MuiBox>
      </MuiBox>
    </HeaderBox>
  )
}

export default Header
