import * as matomo from "@datapunt/matomo-tracker-react"
import { TrackEventParams } from "@datapunt/matomo-tracker-react/lib/types"

interface Wallet extends TrackEventParams {
  category: "wallet"
  action: "clickConnect" | "openModal" | "connected" | "disconnected"
}
interface Snackbar extends TrackEventParams {
  category: "snackbar"
  action: "shown" | "hidden"
}
interface Transaction extends TrackEventParams {
  category: "transaction"
  action: "broadcastStarted" | "broadcastEnded" | "broadcastSuccess" | "broadcastFailure"
}
interface StepStakeAmount extends TrackEventParams {
  category: "stepStakeAmount"
  action: "selectExitDate" | "clickConnect" | "clickNextStep" | "clickPreviousStep"
}
interface StepReview extends TrackEventParams {
  category: "stepReview"
  action: "clickReviewAndSign" | "clickPreviousStep"
}
interface StepSign extends TrackEventParams {
  category: "stepSign"
  action: "clickSignAndBroadcast" | "clickPreviousStep"
}

type CategoryUnion = Wallet | Snackbar | Transaction | StepStakeAmount | StepReview | StepSign

export const useMatomo = () => {
  const { trackEvent } = matomo.useMatomo()

  return {
    trackEvent: (props: CategoryUnion) => trackEvent(props)
  }
}
