import React from "react"
import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react"
import { ThemeProvider } from "@stakefish/ui"
import colors from "@stakefish/ui/theme/colors"
import { borderRadius } from "@stakefish/ui/theme/borders"
import { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import App from "./core/App"
import { GlobalStyles } from "./core/GlobalStyles"
import { HTML } from "./core/MetaTags"

/**
 * This is the library entryfile.
 *
 * DO NOT REMOVE.
 */
export const StakingFlow = () => {
  return (
    <MatomoProvider
      value={createInstance({
        urlBase: "https://analytics.stake.fish/",
        siteId: 2,
        disabled: window.location.hostname === "localhost"
      })}
    >
      <HTML />
      <ThemeProvider>
        <GlobalStyles />
        <SkeletonTheme
          baseColor={colors.gray[100]}
          highlightColor={colors.gray.white}
          borderRadius={borderRadius.large}
          duration={1}
          width="100%"
        >
          <App />
        </SkeletonTheme>
      </ThemeProvider>
    </MatomoProvider>
  )
}
