import React, { ReactNode, useState, useEffect } from "react"
import MuiBox from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import NumericField from "@stakefish/ui/components/NumericField"
import { StakeAmountProps } from "../containers/StakeAmount"
import { WalletStates } from "../types/wallet"
import { AmountInputErrorState, StakingStates } from "../types/staking"
import { MINIMUM_STAKING_VALUE, STAKE_INPUT_ERROR_MESSAGES } from "../consts/staking"
import { getDecimalLength } from "../utils/mathTool"
import { FormattedMessage } from "react-intl"

/**
 * Types
 */
interface AmountFormProps {
  initialValue: StakingStates["stakeAmount"]
  walletBalance: WalletStates["balance"]
  onAmountChange: StakeAmountProps["onAmountChange"]
}
interface HandleValueChangeProps {
  value: number
  walletBalance: AmountFormProps["walletBalance"]
  setters: {
    setValue: React.Dispatch<React.SetStateAction<number>>
    setIsError: React.Dispatch<React.SetStateAction<boolean>>
    setHelperText: React.Dispatch<React.SetStateAction<ReactNode>>
    onAmountChange: AmountFormProps["onAmountChange"]
  }
}
interface HandleInputBlurProps {
  value: HandleValueChangeProps["value"]
  isError: boolean
  setValue: HandleValueChangeProps["setters"]["setValue"]
}
type HelperMessageState = "default" | AmountInputErrorState

/**
 * Constants
 */
const HELPER_MESSAGES: { [key in HelperMessageState]: ReactNode } = {
  default: <FormattedMessage id="STAKE_AMOUNT_MUST_BE_AT_LEAST" />,
  ...STAKE_INPUT_ERROR_MESSAGES
}

/**
 * Helpers
 */
const handleValueChange = ({ value, walletBalance, setters }: HandleValueChangeProps) => {
  const { setHelperText, setIsError, onAmountChange } = setters
  let isError = false
  let errorMessage = null

  if (!value || value === 0) {
    errorMessage = HELPER_MESSAGES.belowMinStakeValue
    isError = true
  }

  if (getDecimalLength(value) > 1) {
    errorMessage = HELPER_MESSAGES.overflowStepDigit
    isError = true
  }

  if (walletBalance !== undefined && value >= walletBalance) {
    errorMessage = HELPER_MESSAGES.overWalletBalance
    isError = true
  }

  if (isError && errorMessage) {
    setHelperText(errorMessage)
    setIsError(isError)
    onAmountChange(0, errorMessage)
    return
  }

  setHelperText(HELPER_MESSAGES.default)
  setIsError(false)
  onAmountChange(value, null)
}

const handleInputBlur = ({ value, isError, setValue }: HandleInputBlurProps) => {
  if (!isError) return null

  const formattedValue = (() => {
    if (!value) return MINIMUM_STAKING_VALUE

    const splitValue = value.toString().split(".")
    const digitValue = splitValue[1] ? Number(`0.${splitValue[1][0]}`) : 0

    return Number(splitValue[0]) + digitValue
  })()

  setValue(formattedValue)
}

/**
 * Main
 */
const AmountForm: React.FC<AmountFormProps> = ({ initialValue, walletBalance, onAmountChange }) => {
  const [value, setValue] = useState<number>(initialValue)
  const [isError, setIsError] = useState(false)
  const [helperText, setHelperText] = useState(HELPER_MESSAGES.default)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  useEffect(() => {
    handleValueChange({
      value: value,
      walletBalance,
      setters: {
        setValue,
        setIsError,
        setHelperText,
        onAmountChange
      }
    })
  }, [value, walletBalance])

  return (
    <MuiBox>
      <NumericField
        id="amount"
        fullWidth
        helperText={helperText}
        error={isError}
        size={isMobile ? "sm" : "xs"}
        InputProps={{
          placeholder: "",
          inputProps: {
            min: 0,
            step: MINIMUM_STAKING_VALUE,
            value,
            pattern: "^[0-9]*[.,]?[0-9]*$",
            minLength: 1,
            inputMode: "decimal",
            autoComplete: "off",
            autoCorrect: "off",
            spellCheck: false
          }
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(Number(event.target.valueAsNumber))}
        onBlur={() =>
          handleInputBlur({
            value,
            isError,
            setValue
          })
        }
      />
    </MuiBox>
  )
}

export default AmountForm
