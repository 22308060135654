import { ModalType } from "./modal"
import { ActionShape } from "./context"
import { SnackPackProps } from "./snackbar"

export type GlobalActionKey = "SET_MODAL_OPEN" | "SET_MODAL_ID" | "SET_SNACKBAR_PACK" | "SET_LOADING_COMPONENT"

export type GlobalActionsUnion = SetModalOpen | SetModalId | SetSnackbarPack | SetLoadingComponent

export enum GlobalState {
  MODAL_OPEN = "modalOpen",
  MODAL_ID = "modalId",
  SNACKBAR_PACK = "snackbarPack",
  LOADING_COMPONENT = "loadingComponent"
}

export interface GlobalStates {
  [GlobalState.MODAL_OPEN]: boolean
  [GlobalState.MODAL_ID]: ModalType
  [GlobalState.SNACKBAR_PACK]?: SnackPackProps
  [GlobalState.LOADING_COMPONENT]: {
    walletCapsule: boolean
    mainSection: boolean
  }
}

interface GlobalActionShape extends ActionShape<GlobalActionKey, GlobalState> {}

interface SetModalOpen extends GlobalActionShape {
  type: "SET_MODAL_OPEN"
  payload: {
    field: GlobalState.MODAL_OPEN
    value: GlobalStates[GlobalState.MODAL_OPEN]
  }
}
interface SetModalId extends GlobalActionShape {
  type: "SET_MODAL_ID"
  payload: {
    field: GlobalState.MODAL_ID
    value: GlobalStates[GlobalState.MODAL_ID]
  }
}
interface SetSnackbarPack extends GlobalActionShape {
  type: "SET_SNACKBAR_PACK"
  payload: {
    field: GlobalState.SNACKBAR_PACK
    value: GlobalStates[GlobalState.SNACKBAR_PACK]
  }
}
interface SetLoadingComponent extends GlobalActionShape {
  type: "SET_LOADING_COMPONENT"
  payload: {
    field: GlobalState.LOADING_COMPONENT
    value: GlobalStates[GlobalState.LOADING_COMPONENT]
  }
}
