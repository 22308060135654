import { useState, useCallback, ChangeEvent, useMemo, useEffect } from "react"
import { useIntl } from "react-intl"
import isEmail from "validator/lib/isEmail"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import MuiBox from "@mui/material/Box"
import { EndAdornmentProps } from "@stakefish/ui/components/EndAdornment"
import TextField from "@stakefish/ui/components/TextField"
import Button from "@stakefish/ui/components/Button"

import { useEmailAccount, useSnackPack } from "../hooks"
import { snackPackProps } from "../types/snackbar"

const SubscribeForm = () => {
  const { status: accountStatus, account, createAccount, removeAccount } = useEmailAccount()
  const { openSnackPack, hideSnackPack, isOpenSnackPack } = useSnackPack()
  const intl = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [validationError, setValidationError] = useState(false)
  const [emailAddress, setEmailAddress] = useState("")

  // ensures that email address is propagated correctly.
  // it is related to the useRef issue.
  useEffect(() => {
    if (emailAddress.length === 0 && account !== undefined) {
      setEmailAddress(account.email)
    }
  }, [account, emailAddress])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.value.length) {
        setValidationError(false)
      }
      setEmailAddress(event.target.value)
    },
    [setEmailAddress]
  )

  const handleSubmit = useCallback(async () => {
    const isEmailValid = isEmail(emailAddress)
    if (!isEmailValid) {
      setValidationError(true)
      return
    }

    try {
      switch (accountStatus) {
        case "confirmed":
          await removeAccount(emailAddress)
          break
        case "no_account":
          await createAccount(emailAddress)
          break
        default:
        // do nothing
      }

      if (isOpenSnackPack("emailError")) {
        hideSnackPack()
      }
    } catch (err) {
      const children = intl.formatMessage({
        id: accountStatus === "no_account" ? "ERR_EMAIL_SUBSCRIBE" : "ERR_EMAIL_UNSUBSCRIBE"
      })
      openSnackPack({
        ...snackPackProps.emailError,
        children
      })
    }
  }, [account, emailAddress])

  const [yourEmail, subscribe, unsubscribe, validEmail] = [
    intl.formatMessage({ id: "YOUR_EMAIL" }),
    intl.formatMessage({ id: "SUBSCRIBE" }),
    intl.formatMessage({ id: "UNSUBSCRIBE" }),
    intl.formatMessage({ id: "REQUIRE_VALID_EMAIL" })
  ]

  const helperText = useMemo(() => {
    if (validationError) {
      return validEmail
    }

    return null
  }, [validationError])

  const buttonText = useMemo(() => {
    switch (accountStatus) {
      case "confirmed":
        return unsubscribe
      case "no_account":
      default:
        return subscribe
    }
  }, [accountStatus])

  const actionButtonProps: EndAdornmentProps["actionButtonProps"] | undefined =
    accountStatus === "confirmed"
      ? undefined
      : { disabled: false, children: buttonText, onClick: handleSubmit, color: "inverse" }

  return (
    <>
      <TextField
        type="email"
        placeholder={yourEmail}
        helperText={helperText}
        value={emailAddress}
        onChange={handleChange}
        error={validationError}
        fullWidth={true}
        disabled={accountStatus === "confirmed"}
        size={isMobile ? "sm" : "xs"}
        actionButtonProps={isMobile ? undefined : actionButtonProps}
      />
      {isMobile && (
        <MuiBox my={1}>
          <Button size="sm" fullWidth {...actionButtonProps} color="secondary" />
        </MuiBox>
      )}
    </>
  )
}

export default SubscribeForm
