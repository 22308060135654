import combineReducers from "react-combine-reducers"
import { MINIMUM_STAKING_VALUE } from "../consts/staking"
import { StakingStates, StakingStep, ExitDate, StakingActionsUnion, BroadcastState } from "../types/staking"
import { WalletStates, WalletActionsUnion } from "../types/wallet"
import { AppStates, Reducer, AppReducer } from "../types/context"
import { GlobalStates, GlobalActionsUnion } from "../types/global"

export const INIT_STATES: AppStates = {
  staking: {
    contracts: [],
    currentStep: StakingStep.StakeAmount,
    stakeAmount: MINIMUM_STAKING_VALUE,
    exitDate: ExitDate.Fastest,
    gasCost: undefined,
    validatorsAmount: undefined,
    userEmail: undefined,
    txBroadcastState: BroadcastState.Pending,
    txBroadcastResult: {
      eth1Id: "",
      eth1TxUrl: "",
      validatorsAmount: 0,
      errorMsg: undefined
    }
  },
  wallet: {
    connectedWallet: null,
    web3Provider: null,
    balance: undefined,
    address: undefined,
    network: undefined,
    history: [],
    errorOnWalletConnection: undefined
  },
  global: {
    modalOpen: false,
    modalId: "walletWidget",
    snackbarPack: undefined,
    loadingComponent: {
      walletCapsule: false,
      mainSection: false
    }
  }
}

/**
 * Reducers
 */
const stakingReducer: Reducer<StakingStates, StakingActionsUnion> = (state, { type, payload }) => {
  switch (type) {
    case "SET_CONTRACTS":
    case "SET_CURRENT_STEP":
    case "SET_STAKE_AMOUNT":
    case "SET_EXIT_DATE":
    case "SET_AGREEMENTS_SIGNATURE":
    case "SET_GAS_COST":
    case "SET_VALIDATORS_AMOUNT":
    case "SET_USER_EMAIL":
    case "SET_TX_BROADCAST_STATE":
    case "SET_TX_BROADCAST_RESULT": {
      return {
        ...state,
        [payload.field]: payload.value
      }
    }
    default:
      return state
  }
}

const walletReducer: Reducer<WalletStates, WalletActionsUnion> = (state, { type, payload, payloads }) => {
  switch (type) {
    case "SET_CONNECTED_WALLET":
    case "SET_WEB3_PROVIDER":
    case "SET_BALANCE":
    case "SET_ADDRESS":
    case "SET_NETWORK":
    case "SET_HISTORY":
    case "SET_ERROR_ON_WALLET_CONNECTION": {
      return {
        ...state,
        [payload.field]: payload.value
      }
    }
    case "SET_WALLET_CONNECTED_INFO": {
      return {
        ...state,
        ...payloads
      }
    }
    default: {
      return state
    }
  }
}

const globalReducer: Reducer<GlobalStates, GlobalActionsUnion> = (state, { type, payload }) => {
  switch (type) {
    case "SET_MODAL_ID":
    case "SET_MODAL_OPEN":
    case "SET_SNACKBAR_PACK":
    case "SET_LOADING_COMPONENT": {
      return {
        ...state,
        [payload.field]: payload.value
      }
    }
    default: {
      return state
    }
  }
}

/**
 * Main reducer
 */
export const appCombinedReducer = combineReducers<AppReducer>({
  staking: [stakingReducer, INIT_STATES.staking],
  wallet: [walletReducer, INIT_STATES.wallet],
  global: [globalReducer, INIT_STATES.global]
})
