import React, { ReactNode } from "react"
import { useIntl } from "react-intl"
import { useTheme, styled } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import MuiBox from "@mui/material/Box"
import Icon from "@stakefish/ui/components/Icon"
import Button from "@stakefish/ui/components/Button"
import Typography from "@stakefish/ui/components/Typography"

/**
 * Types
 */
export interface ConnectLayoutProps {
  prev?: boolean
  onPrevClick?: React.MouseEventHandler<HTMLButtonElement>
  children: ReactNode
}

/**
 * Styles
 */
const PrevButtonBox = styled(MuiBox)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: theme.spacing(-2),
  transform: "translateY(-50%)",

  [theme.breakpoints.down("sm")]: {
    left: "auto"
  }
}))

const LayoutHeader = styled(MuiBox)(({ theme }) => ({
  position: "relative"
}))

/**
 * Components
 */
export const ConnectingLayout = ({ prev, onPrevClick, children }: ConnectLayoutProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const intl = useIntl()

  const [connectWallet] = [intl.formatMessage({ id: "CONNECT_WALLET" })]

  return (
    <MuiBox>
      <LayoutHeader mb={4} mt={isMobile ? 2 : 0}>
        <MuiBox textAlign="center">
          <Typography variant="title3">{connectWallet}</Typography>
        </MuiBox>
        {prev && (
          <PrevButtonBox>
            <Button
              variant="outlined"
              size="sm"
              startIcon={<Icon iconKey="chevronRight" rotate={180} size="xs1" />}
              onClick={onPrevClick}
            />
          </PrevButtonBox>
        )}
      </LayoutHeader>
      <MuiBox mb={2}>{children}</MuiBox>
    </MuiBox>
  )
}
