import { ReactNode } from "react"
import { styled } from "@mui/material/styles"
import MuiBox from "@mui/material/Box"
import Icon from "@stakefish/ui/components/Icon"
import Typography from "@stakefish/ui/components/Typography"
import LoadingIcon from "@stakefish/ui/components/LoadingIcon"
import { WalletStates } from "../../../types/wallet"

/**
 * Types
 */
export interface StateIndicatorProps {
  type: "connecting" | "negative"
  title: ReactNode
  subtitle?: WalletStates["errorOnWalletConnection"]
  error?: boolean
}

/**
 * Styles
 */
const StatusIndicatorBox = styled(MuiBox)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(3)
}))

/**
 * Components
 */
export const StateIndicator = ({ type, title, subtitle, error = false }: StateIndicatorProps) => (
  <StatusIndicatorBox>
    <MuiBox display="flex" alignItems="center">
      {type === "negative" && <Icon iconKey="fail" size="xl1" color="negative" />}
      {type === "connecting" && <LoadingIcon size="lg" />}
    </MuiBox>
    <MuiBox>
      <MuiBox>
        <Typography variant="descriptionBold">{title}</Typography>
      </MuiBox>
      {subtitle && (
        <MuiBox mt={-1}>
          <Typography
            variant="descriptionBold"
            color={error ? "negative" : "primary"}
            sx={{ "& a": { color: "inherit" } }}
          >
            {subtitle}
          </Typography>
        </MuiBox>
      )}
    </MuiBox>
  </StatusIndicatorBox>
)
