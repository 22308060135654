import React from "react"
import MuiBox from "@mui/material/Box"
import { styled } from "@mui/material/styles"

const StyledDivider = styled(MuiBox)(({ theme }) => ({
  whiteSpace: "nowrap",
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: 30,

  ":after": {
    content:
      "'..........................................................................................................'",
    letterSpacing: 4,
    fontSize: 30,
    color: theme.palette.gray[300],
    lineHeight: 1,
    position: "absolute",
    left: 0,
    top: -10
  }
}))

const Divider = ({ ...props }) => {
  return <StyledDivider my={{ xs: 4, md: 6 }} {...props} />
}

export default Divider
