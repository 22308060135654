import React from "react"
import { useIntl } from "react-intl"
import { useTheme, styled } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import MuiBox from "@mui/material/Box"
import { borderRadius } from "@stakefish/ui/theme/borders"
import Typography from "@stakefish/ui/components/Typography"
import { getTruncatedAddress } from "../../../utils/addressTool"
import { WalletStates } from "../../../types/wallet"

/**
 * Types
 */
export interface TransactionsProps {
  walletStates: WalletStates
}

/**
 * Constants
 */
const ADDRESS_LENGTH_MOBILE = 8
const ADDRESS_LENGTH_DESKTOP = 14

/**
 * Styles
 */
const TransactionBox = styled(MuiBox)(({ theme }) => ({
  background: theme.palette.gray[100],
  margin: theme.spacing(2.5, -2, -2),
  padding: theme.spacing(2),

  "& > .MuiTypography-calloutBold": {
    marginBottom: theme.spacing(1.5)
  },
  [theme.breakpoints.down("sm")]: {
    margin: 0,
    marginTop: theme.spacing(3),
    paddingBottom: 0
  },
  [theme.breakpoints.down(500)]: {
    display: "none"
  }
}))

const TransactionListWrapper = styled(MuiBox)(({ theme }) => ({
  display: "flex",
  overflow: "auto",
  maxHeight: 242,

  "&.list-empty": {
    alignItems: "center",
    justifyContent: "center",
    height: 140
  },
  "& > p": {
    marginTop: theme.spacing(-4)
  },

  [theme.breakpoints.down("sm")]: {
    height: 150
  }
}))

const TransactionListUl = styled("ul")({
  width: "100%",
  margin: 0,
  padding: 0
})

const TransactionListItem = styled("li")(({ theme }) => ({
  background: theme.palette.gray.white,
  border: `3px solid ${theme.palette.gray.white}`,
  borderRadius: borderRadius.sm,
  transition: theme.transitions.create("all"),

  "&:nth-child(even)": {
    background: theme.palette.gray[100],
    border: `3px solid ${theme.palette.gray[100]}`
  },
  "&:focus, &:hover": {
    border: `3px solid ${theme.palette.gray[300]}`
  },
  "& a": {
    display: "block",
    padding: theme.spacing(1.5, 2),
    textDecoration: "none",

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 1.5)
    }
  },
  "& > .MuiTypography-supportingText": {
    marginBlockStart: theme.spacing(-1)
  }
}))

/**
 * Components
 */
export const TransactionList: React.FC<TransactionsProps> = ({ walletStates }) => {
  const { address, history, network } = walletStates

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const intl = useIntl()

  const [transactions, emptyListMessage, depositTransaction, withdrawTransaction, otherTransaction] = [
    intl.formatMessage({ id: "TRANSACTION_LIST_TITLE" }),
    intl.formatMessage({ id: "TRANSACTION_LIST_EMPTY" }),
    intl.formatMessage({ id: "DEPOSIT_TRANSACTION" }),
    intl.formatMessage({ id: "WITHDRAW_TRANSACTION" }),
    intl.formatMessage({ id: "OTHER_TRANSACTION" })
  ]

  const trimEsnAddressAfterNChars = isMobile ? ADDRESS_LENGTH_MOBILE : ADDRESS_LENGTH_DESKTOP

  if (history.length === 0) {
    return (
      <TransactionBox>
        <Typography variant="calloutBold">{transactions}</Typography>

        <TransactionListWrapper className="list-empty">
          <Typography variant="caption">{emptyListMessage}</Typography>
        </TransactionListWrapper>
      </TransactionBox>
    )
  }

  const getTransactionType = (from: string, to: string = "") => {
    if (from === address) {
      return depositTransaction
    } else if (to === address) {
      return withdrawTransaction
    }

    return otherTransaction
  }

  const items = history.map(({ hash, from, to }, i) => {
    const url =
      network?.chainId === 1
        ? `https://etherscan.io/tx/${hash}`
        : `https://${network?.name ?? "goerli"}.etherscan.io/tx/${hash}`

    return (
      <TransactionListItem key={i}>
        <a target="_blank" rel="noreferrer" href={url}>
          <Typography variant="calloutBold" display="block">
            {getTruncatedAddress(hash, trimEsnAddressAfterNChars)}
          </Typography>
          <Typography variant="caption" color="secondary">
            {getTransactionType(from, to)}
          </Typography>
        </a>
      </TransactionListItem>
    )
  })

  return (
    <TransactionBox>
      <Typography variant="calloutBold">{transactions}</Typography>

      <TransactionListWrapper>
        <TransactionListUl>{items}</TransactionListUl>
      </TransactionListWrapper>
    </TransactionBox>
  )
}
