import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: white;
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  .MuiModal-root {
    position: fixed;
  }

  @media all and (max-width: 599.95px) {
    .MuiButton-root {
      .MuiButton-startIcon, .MuiButton-endIcon {
        margin-top: 0;
        margin-bottom: 0
      }
    }
  }
`
