import React from "react"

export const MetaMaskLogo = () => {
  return (
    <svg viewBox="0 0 48 48" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1517 31.6328L38.826 39.4965L34.2603 38.2479L41.1517 31.6328Z"
        fill="#E4761B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2603 38.2479L38.6639 32.2142L41.1517 31.6328L34.2603 38.2479Z"
        fill="#E4761B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.7296 25.7038L39.9314 24.1787L41.1515 31.6325L37.7296 25.7038ZM37.7295 25.7031L41.1514 31.6319L38.6636 32.2133L37.7295 25.7031Z"
        fill="#F6851B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.7416 21.7566L34.9272 21.2229L40.9418 20.0505L40.7416 21.7566ZM34.9275 21.2235L41.2566 18.6309L40.942 20.0511L34.9275 21.2235Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.742 21.7561L40.9422 20.05L41.7905 20.7553L40.742 21.7561ZM42.01 19.2314L40.9424 20.0511L41.257 18.6309L42.01 19.2314ZM40.7421 21.7578L39.9319 24.1789L34.9277 21.224L40.7421 21.7578Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.3511 36.3315L30.9333 36.8367L34.2599 38.2474L29.3511 36.3315Z"
        fill="#E2761B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9321 24.179L33.3647 22.0153L34.928 21.2241L39.9321 24.179ZM39.9324 24.1785L40.7426 21.7574L41.4384 22.2912L39.9324 24.1785Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4912 13.7988L34.928 21.2241L33.3647 22.0152L36.4912 13.7988Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2563 18.6314L34.9272 21.2241L36.4905 13.7988L41.2563 18.6314Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.491 13.7987L41.2568 18.6313L43.0965 13.0361L36.491 13.7987Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9321 24.1789L37.7303 25.7039L33.3647 22.0151L39.9321 24.1789Z"
        fill="#F6851B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.491 13.7979L43.0965 13.0353L42.8105 7.68799L36.491 13.7979Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.8101 7.68799L29.6086 17.2865L29.437 10.7763L42.8101 7.68799Z"
        fill="#E2761B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6194 9.99561L29.4371 10.7772L29.6087 17.2874L19.6194 9.99561Z"
        fill="#F6851B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3642 22.0152L29.6086 17.2875L36.4906 13.7988L33.3642 22.0152Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3644 22.0151L37.73 25.7039L31.6011 26.3807L33.3644 22.0151Z"
        fill="#E4761B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6008 26.3794L29.6086 17.2861L33.3642 22.0139L31.6008 26.3794Z"
        fill="#E4761B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4906 13.7979L29.6086 17.2865L42.8101 7.68799L36.4906 13.7979Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7053 38.0949L22.8222 41.3452L18.5806 37.4849L19.7053 38.0949Z"
        fill="#C0AD9E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2603 38.2475L35.7377 32.6332L38.6639 32.2139L34.2603 38.2475Z"
        fill="#CD6116"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.06592 22.4531L12.4609 16.7817L7.76174 21.7764L7.06592 22.4531Z"
        fill="#E2761B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6087 17.2874L24.3567 17.1158L19.6194 9.99561L29.6087 17.2874ZM38.6643 32.2143L35.7381 32.6337L37.7302 25.7041L38.6643 32.2143Z"
        fill="#F6851B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.7299 25.7041L35.7377 32.6337L35.5757 29.2499L37.7299 25.7041Z"
        fill="#E4751F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6011 26.3809L37.73 25.7041L35.5758 29.2499L31.6011 26.3809Z"
        fill="#CD6116"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3569 17.1152L29.6089 17.2868L31.6011 26.3801L24.3569 17.1152Z"
        fill="#F6851B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3569 17.1157L19.6196 9.99548L10.1831 4L24.3569 17.1157ZM7.29489 43.9004L19.6099 40.0877L4.83569 34.5116L7.29489 43.9004Z"
        fill="#E4761B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.89624 24.7986L13.5477 21.1479L17.4462 22.0535L8.89624 24.7986Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4463 22.053L13.5479 21.1475L15.6353 12.5308L17.4463 22.053Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76196 21.777L13.5478 21.1479L8.89625 24.7986L7.76196 21.777Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5758 29.2499L33.1738 28.0394L31.6011 26.3809L35.5758 29.2499Z"
        fill="#CD6116"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76192 21.777L7.35205 19.6133L13.5477 21.1479L7.76192 21.777Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.678 30.7646L33.1736 28.0386L35.5756 29.2491L32.678 30.7646Z"
        fill="#233447"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7377 32.6343L32.678 30.766L35.5756 29.2505L35.7377 32.6343Z"
        fill="#F6851B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6349 12.5308L24.3565 17.1155L17.446 22.053L15.6349 12.5308ZM15.6355 12.5311L13.5481 21.1478L6.84726 17.7927L15.6355 12.5311ZM13.5478 21.1485L7.3521 19.6139L6.84692 17.7933L13.5478 21.1485Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4356 26.5226L17.8848 26.3224L17.4463 22.0522L27.4356 26.5226ZM17.4464 22.0527L24.3569 17.1152L27.4357 26.5231L17.4464 22.0527Z"
        fill="#E4761B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.601 26.3806L27.4356 26.5236L24.3569 17.1157L31.601 26.3806ZM8.89624 24.798L17.4462 22.0528L17.8847 26.3231L8.89624 24.798Z"
        fill="#F6851B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.1738 28.0394L32.6782 30.7655L31.6011 26.3809L33.1738 28.0394Z"
        fill="#CD6116"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6353 12.5309L24.3569 17.1157L10.1831 4L15.6353 12.5309Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83569 34.5118L19.6099 40.0878L16.7885 34.0161L4.83569 34.5118Z"
        fill="#E4761B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.61 40.0877L16.7886 34.0159L22.6601 33.73L19.61 40.0877Z"
        fill="#CD6116"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83577 34.5112L17.8848 26.3234L8.89631 24.7983L4.83577 34.5112ZM4.83569 34.5103L16.7885 34.0146L17.8847 26.3225L4.83569 34.5103ZM31.6007 26.3797L27.4353 26.5227L28.6077 29.4394L32.3823 29.2202L31.6007 26.3797ZM37.425 36.6083L35.7378 32.6335L32.6781 30.7653L37.425 36.6083Z"
        fill="#F6851B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8226 41.3451L19.6104 40.0869L30.7816 41.9647L22.8226 41.3451Z"
        fill="#C0AD9E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.89601 24.7984L7.06592 22.4536L7.76173 21.7769L8.89601 24.7984Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0493 40.573L30.7816 41.9647L19.6104 40.0869L32.0493 40.573Z"
        fill="#D7C1B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6498 37.1614L19.6104 40.0877L22.6605 33.73L32.6498 37.1614Z"
        fill="#E4761B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6104 40.0869L32.6498 37.1606L32.0493 40.573L19.6104 40.0869Z"
        fill="#D7C1B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76191 21.7771L6.49418 20.4903L7.35204 19.6134L7.76191 21.7771ZM6.8469 17.7923L6.41797 10.4624L15.6352 12.5308L6.8469 17.7923Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3003 28.4107L27.4354 26.5234L27.1304 31.1368L25.3003 28.4107Z"
        fill="#CD6116"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4356 26.5234L25.3005 28.4107L22.1931 30.0216L27.4356 26.5234Z"
        fill="#CD6116"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4248 36.6081L36.8434 36.1792L32.678 30.7651L37.4248 36.6081Z"
        fill="#F6851B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1931 30.0221L17.8848 26.3237L27.4356 26.5239L22.1931 30.0221Z"
        fill="#CD6116"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1306 31.1368L27.4356 26.5234L28.608 29.4402L27.1306 31.1368Z"
        fill="#E4751F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.07471 18.5174L6.84677 17.793L7.35196 19.6135L6.07471 18.5174Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1306 31.1372L22.1931 30.022L25.3005 28.4111L27.1306 31.1372Z"
        fill="#233447"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41797 10.4625L15.6352 12.5309L10.183 4L6.41797 10.4625Z"
        fill="#763D16"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7808 41.9648L31.3051 43.3945L22.8218 41.3452L30.7808 41.9648Z"
        fill="#C0AD9E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6602 33.7298L22.1931 30.022L27.1306 31.1372L22.6602 33.7298Z"
        fill="#F6851B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8848 26.3237L22.1931 30.0221L22.6602 33.7299L17.8848 26.3237Z"
        fill="#E4751F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8847 26.3237L22.6601 33.7299L16.7886 34.0159L17.8847 26.3237ZM28.6081 29.4404L32.3827 29.2211L36.8436 36.1793L28.6081 29.4404Z"
        fill="#F6851B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.608 29.4409L33.2977 36.5802L27.1306 31.1376L28.608 29.4409Z"
        fill="#E4751F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1306 31.1372L33.2977 36.5798L32.6495 37.1613L27.1306 31.1372Z"
        fill="#F6851B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8433 36.1794L33.2975 36.5797L28.6079 29.4404L36.8433 36.1794ZM32.6492 37.1612L22.6599 33.7298L27.1303 31.1371L32.6492 37.1612Z"
        fill="#F6851B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.3055 43.3948L30.7812 41.965L32.049 40.5734L31.3055 43.3948ZM37.7778 39.5342L36.2527 42.5939L31.3057 43.3946L37.7778 39.5342Z"
        fill="#C0AD9E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.3055 43.3946L32.9831 40.2014L37.7776 39.5342L31.3055 43.3946ZM32.0489 40.5737L32.983 40.202L31.3054 43.3951L32.0489 40.5737Z"
        fill="#C0AD9E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8436 36.1797L38.0161 36.8946L34.4512 37.3235L36.8436 36.1797Z"
        fill="#161616"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.0411 37.8848L38.3781 37.3605L37.7776 39.5338L34.0411 37.8848ZM34.451 37.3235L33.2976 36.58L36.8434 36.1797L34.451 37.3235Z"
        fill="#161616"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0158 36.8939L38.378 37.361L34.041 37.8852L38.0158 36.8939ZM32.6498 37.161L33.298 36.5796L34.4513 37.3231L32.6498 37.161ZM32.9834 40.201L32.0493 40.5727L32.6498 37.1603L32.9834 40.201ZM37.7777 39.5338L32.9832 40.201L34.0412 37.8848L37.7777 39.5338Z"
        fill="#161616"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6494 37.16L34.0411 37.8844L32.983 40.2006L32.6494 37.16ZM34.0411 37.8849L34.4509 37.3225L38.0158 36.8936L34.0411 37.8849Z"
        fill="#161616"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4509 37.3227L34.0411 37.8851L32.6494 37.1606L34.4509 37.3227Z"
        fill="#161616"
      />
    </svg>
  )
}
