import { API_URL } from "../consts/api"
import { MINIMUM_STAKING_VALUE } from "../consts/staking"
import { DepositPlan, StakingStates } from "../types/staking"
import { ethToGwei } from "../utils/ethToGwei"
import { axios } from "../utils/http"

export interface RequestDepositPlanResponse {
  plan: DepositPlan[]
}

export const requestDepositPlan = async (
  depositAmount: StakingStates["stakeAmount"] = MINIMUM_STAKING_VALUE,
  promisedExitDate: StakingStates["exitDate"],
  takeLimit: number = 3
) => {
  const url = `${API_URL}/v1/sov/request-deposit-plan`

  const body = {
    depositAmount: `${ethToGwei(depositAmount)}`,
    promisedExitDate,
    takeLimit
  }
  return await axios.post<RequestDepositPlanResponse>(url, body).then(({ data }) => data.plan)
}
