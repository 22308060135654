import React, { useMemo } from "react"
import { useIntl } from "react-intl"

import { styled } from "@mui/material/styles"
import MuiStep from "@mui/material/Step"
import MuiBox from "@mui/material/Box"
import SfStepper from "@stakefish/ui/components/Stepper"
import StepLabel from "@stakefish/ui/components/StepLabel"
import StepLabelText from "@stakefish/ui/components/StepLabelText"
import { StakingSteps, BroadcastState, StakingStates, StakingState, StakingStep } from "../types/staking"

export interface StepperProps {
  steps: StakingSteps
  [StakingState.CURRENT_STEP]: StakingStates["currentStep"]
  [StakingState.STAKE_AMOUNT]: StakingStates["stakeAmount"]
  [StakingState.TX_BROADCAST_STATE]: StakingStates["txBroadcastState"]
}

const EthAmountLabel = ({ amount }: { amount: number }) => (
  <MuiBox display="flex" alignItems="center" mt={1}>
    <StepLabelText variant="note" typographyProps={{ color: "primary" }}>
      {amount} ETH
    </StepLabelText>
  </MuiBox>
)

const Wrapper = styled(MuiBox)(({ theme }) => ({
  "& .MuiStepper-root": {
    padding: 0
  },

  [theme.breakpoints.down("md")]: {
    width: "calc(100% + 40px)",
    overflow: "auto",
    paddingBottom: theme.spacing(2),
    margin: "0 -20px",
    whiteSpace: "nowrap"
  }
}))

const Stepper: React.FC<StepperProps> = ({ steps, currentStep, stakeAmount, txBroadcastState }) => {
  const intl = useIntl()

  return (
    <Wrapper>
      <SfStepper activeStep={currentStep - 1}>
        {steps.map((label, index) => {
          const isLastStepInDone = useMemo(() => currentStep === steps.length && index === steps.length, [currentStep])
          const isErrorStep = useMemo(
            () => isLastStepInDone && txBroadcastState === BroadcastState.Negative,
            [isLastStepInDone, txBroadcastState]
          )

          return (
            <MuiStep key={index}>
              <StepLabel error={isErrorStep} loading={currentStep === StakingStep.Broadcasting}>
                <StepLabelText variant="subtitle">
                  {intl.formatMessage({ id: "STEP" }, { step: index + 1 })}
                </StepLabelText>
                <StepLabelText>{label}</StepLabelText>
                {/*
                 * stake amount at first step
                 */}
                {currentStep > 1 && index === 0 && stakeAmount && <EthAmountLabel amount={stakeAmount} />}
                {/*
                 * stake result at last step
                 */}
                {isLastStepInDone && (
                  <StepLabelText
                    variant="note"
                    typographyProps={{
                      color: txBroadcastState === BroadcastState.Negative ? "negative" : "positive"
                    }}
                  >
                    {txBroadcastState === BroadcastState.Negative ? "Error" : "Success"}
                  </StepLabelText>
                )}
              </StepLabel>
            </MuiStep>
          )
        })}
      </SfStepper>
    </Wrapper>
  )
}

export default Stepper
