import axios from "axios"
import { API_URL } from "../consts/api"

export interface SignedAgreementPayload {
  address: string
  agreement: string
  signature: string
}

export interface SignedAgreementResponse {
  signedAt: Date
}

export const storeSignedAgreement = async ({ address, agreement, signature }: SignedAgreementPayload) => {
  const url = `${API_URL}/v1/agreement/sign`
  const body = {
    agreement,
    address,
    signature
  }

  try {
    const { signedAt } = await axios.post<SignedAgreementResponse>(url, body).then(({ data }) => data)

    return signedAt
  } catch (e) {
    throw new Error("storeAgreementsError")
  }
}
