import { SnackbarProps } from "@stakefish/ui/components/Snackbar"

export type SnackbarType =
  | "insufficientFund"
  | "walletUnconnected"
  | "storeAgreementsError"
  | "generalError"
  | "ethError"
  | "emailError"

export interface SnackPackProps extends Partial<Omit<SnackbarProps, "open">> {
  type: SnackbarType
  disableNextButton?: boolean
}

export const snackPackProps: Record<SnackbarType, SnackPackProps> = {
  walletUnconnected: {
    type: "walletUnconnected",
    alertProps: {
      color: "info"
    },
    autoHideDuration: 5000
  },
  insufficientFund: {
    type: "insufficientFund",
    alertProps: {
      color: "error"
    },
    disableNextButton: true,
    autoHideDuration: 5000
  },
  storeAgreementsError: {
    type: "storeAgreementsError",
    alertProps: {
      color: "error"
    },
    disableNextButton: true,
    autoHideDuration: 5000
  },
  ethError: {
    type: "ethError",
    alertProps: {
      color: "error"
    },
    autoHideDuration: 5000
  },
  emailError: {
    type: "emailError",
    alertProps: {
      color: "error"
    },
    autoHideDuration: 5000
  },
  generalError: {
    type: "generalError",
    alertProps: {
      color: "error"
    },
    autoHideDuration: 5000
  }
}
