import { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { WalletError } from "../types/web3"
import { LINK_CONTACT } from "./stakefish"

/**
 *
 * Eth errors
 *
 */
const EthErrorCodes = [
  "-32700",
  "-32600",
  "-32601",
  "-32602",
  "-32603",
  "-32000",
  "-32001",
  "-32002",
  "-32003",
  "-32004",
  "-32005",
  "4001",
  "4100",
  "4200",
  "4900",
  "4901"
] as const

type EthErrorCode = typeof EthErrorCodes[number]

export const EthErrorMessages: Record<EthErrorCode, ReactNode> = {
  "-32700": <FormattedMessage id="ERR_ETH_PARSE" />,
  "-32600": <FormattedMessage id="ERR_ETH_INVALID_REQUEST" />,
  "-32601": <FormattedMessage id="ERR_ETH_METHOD_NOT_FOUND" />,
  "-32602": <FormattedMessage id="ERR_ETH_INVALID_PARAMS" />,
  "-32603": <FormattedMessage id="ERR_ETH_INTERNAL" />,
  "-32000": <FormattedMessage id="ERR_ETH_INVALID_INPUT" />,
  "-32001": <FormattedMessage id="ERR_ETH_RESOURCE_NOT_FOUND" />,
  "-32002": <FormattedMessage id="ERR_ETH_RESOURCE_UNAVAILABLE" />,
  "-32003": <FormattedMessage id="ERR_ETH_TRANSACTION_REJECTED" />,
  "-32004": <FormattedMessage id="ERR_ETH_METHOD_NOT_SUPPORTED" />,
  "-32005": <FormattedMessage id="ERR_ETH_LIMIT_EXCEEDED" />,
  4001: <FormattedMessage id="ERR_ETH_USER_REJECTED_REQUEST" />,
  4100: <FormattedMessage id="ERR_ETH_UNAUTHORIZED" />,
  4200: <FormattedMessage id="ERR_ETH_UNSUPPORTED_METHOD" />,
  4900: <FormattedMessage id="ERR_ETH_DISCONNECTED" />,
  4901: <FormattedMessage id="ERR_ETH_CHAIND_IS_CONNECTED" />
}

/**
 *
 * Custom errors
 *
 */
const CustomErrorKeys = [
  "insufficientFunds",
  "metaMaskUninstalled",
  "metaMaskProviderFailed",
  "broadcastGeneralFailure",
  "mainnetRequirement",
  "timeoutSignMessage",
  "storeAgreementsError",
  "walletService"
] as const

type CustomErrorKey = typeof CustomErrorKeys[number]

export const CustomErrorMessages: Record<CustomErrorKey, ReactNode> = {
  insufficientFunds: <FormattedMessage id="ERR_INSUFFICIENT_FUNDS" />,
  metaMaskUninstalled: (
    <FormattedMessage
      id="ERR_METAMASK_UNINSTALLED"
      values={{
        url: (...chunks: string[]) => (
          <a href="https://metamask.io/download.html" target="_blank" rel="noopener noreferrer">
            {chunks}
          </a>
        )
      }}
    />
  ),
  metaMaskProviderFailed: (
    <FormattedMessage
      id="ERR_METAMASK_PROVIDER_FAILURE"
      values={{
        url: (...chunks: string[]) => (
          <a href={LINK_CONTACT} target="_blank" rel="noopener noreferrer">
            {chunks}
          </a>
        )
      }}
    />
  ),
  broadcastGeneralFailure: (
    <FormattedMessage
      id="ERR_BROADCAST_GENERAL_FAILURE"
      values={{
        url: (...chunks: string[]) => (
          <a href={LINK_CONTACT} target="_blank" rel="noopener noreferrer">
            {chunks}
          </a>
        )
      }}
    />
  ),
  storeAgreementsError: (
    <FormattedMessage
      id="ERR_STORE_AGREEMENTS"
      values={{
        url: (...chunks: string[]) => (
          <a href={LINK_CONTACT} target="_blank" rel="noopener noreferrer">
            {chunks}
          </a>
        )
      }}
    />
  ),
  mainnetRequirement: <FormattedMessage id="ERR_MAINNET_REQUIREMENT" />,
  timeoutSignMessage: <FormattedMessage id="ERR_TIMEOUT_SING_MESSAGE" />,
  walletService: (
    <FormattedMessage
      id="ERR_WALLET_SERVICE"
      values={{
        url: (...chunks: string[]) => (
          <a href={LINK_CONTACT} target="_blank" rel="noopener noreferrer">
            {chunks}
          </a>
        )
      }}
    />
  )
}

/**
 *
 * Combined errors
 *
 */
const ErrorKeys = [...EthErrorCodes, ...CustomErrorKeys] as const

type ErrorKey = typeof ErrorKeys[number]

export const ErrorMessages: Record<ErrorKey, ReactNode> = {
  ...EthErrorMessages,
  ...CustomErrorMessages
}

export const isErrorKey = (value: string): value is ErrorKey => ErrorKeys.includes(String(value) as ErrorKey)

const isWalletError = (err: any): err is WalletError => err instanceof Object && "code" in err

export const getErrorMessage = (err?: ErrorKey | string | Error | WalletError) => {
  if (!err) return <FormattedMessage id="ERR_GENERAL" />

  const errorMsg = (() => {
    if (isWalletError(err)) return String(err.code)
    if (err instanceof Error) return err.message
    return err
  })()

  if (isErrorKey(errorMsg)) {
    return ErrorMessages[errorMsg]
  }

  return <FormattedMessage id="ERR_GENERAL" />
}
