import { useCallback, useContext, useRef, useEffect, useState, useMemo } from "react"
import { AppContext, DefaultContext } from "../context"
import { GlobalState } from "../types/global"
import { snackPackProps } from "../types/snackbar"
import { StakingState, StakingStep, BroadcastState } from "../types/staking"
import { EN_AGREEMENTS } from "../consts/staking"
import { getErrorMessage } from "../consts/errors"
import { requestDepositPlan } from "../data/requestDepositPlan"
import { storeSignedAgreement } from "../data/storeSignedAgreement"
import { useSnackPack } from "./useSnackPack"
import { useWalletService } from "./useWalletService"
import { StepDirection, SetStepPayloads, SetStep } from "./useStep"
import { useEmailAccount } from "./useEmailAccount"
import { useMatomo } from "./useMatomo"

export interface UseStepWizardProps {
  prevStep: StakingStep
  setStep: SetStep
}

export const useStepWizard = (props: UseStepWizardProps) => {
  const { state, dispatch } = useContext(AppContext as DefaultContext)
  const { prevStep, setStep } = props
  const isFirstLoad = useRef(true)
  const [broadcastedTx, setBroadcastedTx] = useState<any>(null)
  const [Wallet, getWalletService] = useWalletService()
  const { openSnackPack, hideSnackPack } = useSnackPack()
  const { scheduleReminder, cancelReminder } = useEmailAccount()

  const stakingStateRef = useRef(state.staking)
  stakingStateRef.current = state.staking
  const walletStateRef = useRef(state.wallet)
  walletStateRef.current = state.wallet

  // Matomo
  const { trackEvent } = useMatomo()

  const getAndSetWalletService = useCallback(() => {
    const WalletService = Wallet ?? getWalletService()
    if (!WalletService) throw new Error("walletService")

    return WalletService
  }, [])

  const dispatchBroadcastError = useCallback((error: any) => {
    dispatch({ type: "SET_MODAL_OPEN", payload: { field: GlobalState.MODAL_OPEN, value: false } })

    if (stakingStateRef.current.currentStep === StakingStep.Sign) {
      throw error
    }

    if (stakingStateRef.current.currentStep === StakingStep.Broadcasting) {
      dispatch({
        type: "SET_TX_BROADCAST_RESULT",
        payload: {
          field: StakingState.TX_BROADCAST_RESULT,
          value: {
            errorMsg: getErrorMessage(error)
          }
        }
      })
      dispatch({
        type: "SET_TX_BROADCAST_STATE",
        payload: {
          field: StakingState.TX_BROADCAST_STATE,
          value: BroadcastState.Negative
        }
      })

      setStep(stakingStateRef.current.currentStep, "next", { targetNextStep: StakingStep.Done })
    }
  }, [])

  const beforeChangeActions = useMemo(
    () => ({
      [StakingStep.StakeAmount]: async () => {
        /**
         * [UI state] Loading state on
         */
        dispatch({
          type: "SET_LOADING_COMPONENT",
          payload: {
            field: GlobalState.LOADING_COMPONENT,
            value: {
              ...state.global.loadingComponent,
              mainSection: true
            }
          }
        })

        /**
         * Main actions
         */
        const { stakeAmount, exitDate } = stakingStateRef.current
        const contracts = await requestDepositPlan(stakeAmount, exitDate)

        dispatch({
          type: "SET_CONTRACTS",
          payload: { field: StakingState.CONTRACTS, value: contracts }
        })

        /**
         * [UI state] Loading state off
         */
        dispatch({
          type: "SET_LOADING_COMPONENT",
          payload: {
            field: GlobalState.LOADING_COMPONENT,
            value: {
              ...state.global.loadingComponent,
              mainSection: false
            }
          }
        })

        scheduleReminder()
      },
      [StakingStep.Review]: async () => {
        /***********************************/
        /* 1. Open modal */
        /***********************************/
        dispatch({ type: "SET_MODAL_ID", payload: { field: GlobalState.MODAL_ID, value: "walletInFocusView" } })
        dispatch({ type: "SET_MODAL_OPEN", payload: { field: GlobalState.MODAL_OPEN, value: true } })

        /***********************************/
        /* 2. Reset values                 */
        /***********************************/
        dispatch({
          type: "SET_GAS_COST",
          payload: {
            field: StakingState.GAS_COST,
            value: undefined
          }
        })
        dispatch({
          type: "SET_AGREEMENTS_SIGNATURE",
          payload: { field: StakingState.AGREEMENTS_SIGNATURE, value: undefined }
        })

        /***********************************/
        /* 3-1. Get and set values         */
        /***********************************/
        try {
          const WalletService = getAndSetWalletService()

          const address = walletStateRef.current.address as string
          const gasCost = await WalletService.estimateGasCost(
            stakingStateRef.current.contracts,
            stakingStateRef.current.stakeAmount
          )
          dispatch({
            type: "SET_GAS_COST",
            payload: {
              field: StakingState.GAS_COST,
              value: gasCost
            }
          })

          const agreementText = EN_AGREEMENTS.join(" ")
          const signedPayload = await WalletService.signAgreement(address, agreementText)
          await storeSignedAgreement(signedPayload)
          dispatch({
            type: "SET_AGREEMENTS_SIGNATURE",
            payload: { field: StakingState.AGREEMENTS_SIGNATURE, value: signedPayload.signature }
          })

          scheduleReminder()
        } catch (e) {
          /***********************************/
          /* 3-2. Close modal on error state */
          /***********************************/
          dispatch({ type: "SET_MODAL_OPEN", payload: { field: GlobalState.MODAL_OPEN, value: false } })
          throw e as Error
        }

        /***********************************/
        /* 4. Close modal                  */
        /***********************************/
        dispatch({ type: "SET_MODAL_OPEN", payload: { field: GlobalState.MODAL_OPEN, value: false } })
      },
      [StakingStep.Sign]: async () => {
        dispatch({ type: "SET_MODAL_ID", payload: { field: GlobalState.MODAL_ID, value: "walletInFocusView" } })
        dispatch({ type: "SET_MODAL_OPEN", payload: { field: GlobalState.MODAL_OPEN, value: true } })

        try {
          const WalletService = getAndSetWalletService()

          const tx = await WalletService.broadcastAndGetTx(
            stakingStateRef.current.contracts,
            stakingStateRef.current.stakeAmount
          )
          setBroadcastedTx(tx)
        } catch (e) {
          dispatchBroadcastError(e)
        }

        dispatch({ type: "SET_MODAL_OPEN", payload: { field: GlobalState.MODAL_OPEN, value: false } })
        scheduleReminder()
      },
      [StakingStep.Broadcasting]: () => null,
      [StakingStep.Done]: () => null
    }),
    [Wallet]
  )

  const afterChangeActions = useMemo(
    () => ({
      [StakingStep.StakeAmount]: () => null,
      [StakingStep.Review]: () => null,
      [StakingStep.Sign]: () => null,
      [StakingStep.Broadcasting]: async () => {
        // Matomo
        trackEvent({ category: "transaction", action: "broadcastStarted" })

        try {
          const WalletService = getAndSetWalletService()

          const receipt = await WalletService.getBroadcastReceipt(broadcastedTx)
          if (!receipt) {
            dispatchBroadcastError("broadcastGeneralFailure")
            return
          }

          dispatch({
            type: "SET_GAS_COST",
            payload: {
              field: StakingState.GAS_COST,
              value: receipt.gasCost
            }
          })
          dispatch({
            type: "SET_TX_BROADCAST_RESULT",
            payload: {
              field: StakingState.TX_BROADCAST_RESULT,
              value: {
                eth1Id: receipt.txHash,
                eth1TxUrl: `https://etherscan.io/tx/${receipt.txHash}`
              }
            }
          })

          dispatch({
            type: "SET_TX_BROADCAST_STATE",
            payload: {
              field: StakingState.TX_BROADCAST_STATE,
              value: BroadcastState.Positive
            }
          })
          setStep(StakingStep.Broadcasting, "next")

          // Matomo
          trackEvent({ category: "transaction", action: "broadcastEnded" })
          trackEvent({ category: "transaction", action: "broadcastSuccess" })
        } catch (e) {
          dispatchBroadcastError(e)

          // Matomo
          trackEvent({ category: "transaction", action: "broadcastEnded" })
          trackEvent({ category: "transaction", action: "broadcastFailure" })
        }
      },
      [StakingStep.Done]: async () => {
        try {
          await cancelReminder()
        } catch (e) {
          // do nothing
        }
      }
    }),
    [broadcastedTx]
  )

  const onNavigateStepClick = useCallback(
    async (direction: StepDirection, payloads?: SetStepPayloads) => {
      if (direction === "prev") {
        /**
         *
         * Previous step actions
         *
         */
        const currentStep = stakingStateRef.current.currentStep

        // Cancels email reminder if user returns to the first step
        if (currentStep === StakingStep.Review) {
          cancelReminder()
        }

        setStep(currentStep, direction, payloads)
      } else {
        /**
         *
         * Next step action
         *
         */
        try {
          await beforeChangeActions[stakingStateRef.current.currentStep]()
          setStep(stakingStateRef.current.currentStep, direction, payloads)
        } catch (e: any) {
          openSnackPack({
            ...snackPackProps.generalError,
            children: getErrorMessage(e)
          })
        }
      }

      hideSnackPack()
      window.scroll(0, 0)
    },
    [stakingStateRef]
  )

  const majorButtonCallbacks = useMemo(
    () => ({
      walletUnconnected: () => {
        dispatch({ type: "SET_MODAL_ID", payload: { field: GlobalState.MODAL_ID, value: "walletWidget" } })
        dispatch({ type: "SET_MODAL_OPEN", payload: { field: GlobalState.MODAL_OPEN, value: true } })

        // Matomo
        trackEvent({ category: "wallet", action: "clickConnect" })
      },
      firstStep: async () => {
        await onNavigateStepClick("next")

        // Matomo
        trackEvent({ category: "stepStakeAmount", action: "clickNextStep" })
      },
      reviewStep: async () => {
        await onNavigateStepClick("next")

        // Matomo
        trackEvent({ category: "stepReview", action: "clickReviewAndSign" })
      },
      signStep: async () => {
        await onNavigateStepClick("next")

        // Matomo
        trackEvent({ category: "stepSign", action: "clickSignAndBroadcast" })
      },
      txPendingStep: () => null,
      txPositiveStep: () => null,
      txNegativeStep: async () => {
        // set BroadcastState back to pending before step change
        dispatch({
          type: "SET_TX_BROADCAST_STATE",
          payload: {
            field: StakingState.TX_BROADCAST_STATE,
            value: BroadcastState.Pending
          }
        })
        await onNavigateStepClick("prev", { targetPrevStep: StakingStep.Sign })
      }
    }),
    [onNavigateStepClick]
  )

  const subButtonCallbacks = useMemo(
    () => ({
      walletUnconnected: () => null,
      firstStep: async () => {
        await onNavigateStepClick("prev")

        // Matomo
        trackEvent({ category: "stepStakeAmount", action: "clickPreviousStep" })
      },
      reviewStep: async () => {
        await onNavigateStepClick("prev")

        // Matomo
        trackEvent({ category: "stepReview", action: "clickPreviousStep" })
      },
      signStep: async () => {
        await onNavigateStepClick("prev")

        // Matomo
        trackEvent({ category: "stepSign", action: "clickPreviousStep" })
      },
      txPendingStep: () => null,
      txPositiveStep: () => null,
      txNegativeStep: () => null
    }),
    [onNavigateStepClick]
  )

  /**
   *
   * [On step change]
   * Handle actions after change.
   *
   */
  useEffect(() => {
    if (!isFirstLoad.current) {
      // prev step actions
      if (prevStep > state.staking.currentStep) return

      // next step actions
      const handleAfterChange = async () => {
        await afterChangeActions[state.staking.currentStep]()
      }
      handleAfterChange()
    }

    isFirstLoad.current = false
  }, [state.staking.currentStep])

  return {
    majorButtonCallbacks,
    subButtonCallbacks
  }
}
