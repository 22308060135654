import { useState, useContext, useCallback } from "react"
import { AppContext, DefaultContext } from "../context"
import { StakingStep, StakingState } from "../types/staking"
import { usePrevious } from "./usePrevious"

export type StepDirection = "prev" | "next"

export interface SetStepPayloads {
  /**
   * Target step when direction is 'prev'.
   */
  targetPrevStep?: StakingStep
  /**
   * Target step when direction is 'next'.
   */
  targetNextStep?: StakingStep
}

export type SetStep = (
  /**
   * Current `StakingStep`.
   */
  currentStep: StakingStep,
  /**
   * Direction can be 'prev' or 'next', as type `StepDirection`.
   */
  direction: StepDirection,
  /**
   * Additional payloads for customizing stepping actions.
   */
  payloads?: SetStepPayloads
) => void

export const useStep = () => {
  const { state, dispatch } = useContext(AppContext as DefaultContext)

  const [activeStep, setActiveStep] = useState(state.staking.currentStep)
  const prevStep = usePrevious(activeStep) as StakingStep

  const setStep = useCallback<SetStep>((currentStep, direction, payloads) => {
    const defaultTargetStep = (() => {
      if (direction === "next") return currentStep + 1 > StakingStep.Done ? StakingStep.Done : currentStep + 1

      return currentStep - 1 < StakingStep.StakeAmount ? StakingStep.StakeAmount : currentStep - 1
    })()

    const targetStep: StakingStep = (() => {
      if (direction === "prev" && payloads?.targetPrevStep && currentStep > payloads.targetPrevStep) {
        return payloads.targetPrevStep
      }
      if (direction === "next" && payloads?.targetNextStep && currentStep < payloads.targetNextStep) {
        return payloads.targetNextStep
      }

      return defaultTargetStep
    })()

    setActiveStep(targetStep)
    dispatch({
      type: "SET_CURRENT_STEP",
      payload: {
        field: StakingState.CURRENT_STEP,
        value: targetStep
      }
    })
  }, [])

  return { prevStep, activeStep, setStep }
}
