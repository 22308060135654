import React, { memo } from "react"
import { useIntl } from "react-intl"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import MuiBox from "@mui/material/Box"
import GridItem from "@stakefish/ui/components/GridItem"
import Typography from "@stakefish/ui/components/Typography"
import GridContainer from "@stakefish/ui/components/GridContainer"
import Card, { CardColorVariants, CardSizeVariants } from "@stakefish/ui/components/Card"
import SwipeableMobileStepper from "@stakefish/ui/components/SwipeableMobileStepper"
import BlockLayout from "../components/BlockLayout"
import { AGREEMENTS } from "../consts/staking"

const Bullet = styled(MuiBox)(({ theme }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: "100%",
  backgroundColor: theme.palette.text.primary
}))

const StyledCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    overflow: "hidden",
    padding: theme.spacing(2, 3),

    "& .MuiBox-root:last-child": {
      marginBottom: 0
    }
  }
}))

const renderers = {
  highlight: (...chunks: string[]) => (
    <Typography variant="caption" component="span">
      {chunks}
    </Typography>
  )
}

const Review = () => {
  const intl = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const [reviewAgreements, validatingServiceFee, percentOfRewards] = [
    intl.formatMessage({ id: "REVIEW_AGREEMENTS" }),
    intl.formatMessage({ id: "STAKEFISH_VALIDATING_SERVICE_FEE" }),
    intl.formatMessage({ id: "PERCENT_OF_REWARDS" }, { highlight: renderers.highlight })
  ]

  return (
    <MuiBox>
      <StyledCard
        color={isMobile ? CardColorVariants.Muted : CardColorVariants.Initial}
        size={isMobile ? CardSizeVariants.Small : CardSizeVariants.Initial}
      >
        <BlockLayout title={reviewAgreements}>
          {isMobile ? (
            <SwipeableMobileStepper
              swipeableViewsProps={{
                overflowXHidden: false,
                sx: { "& .react-swipeable-view-slide": { overflow: "hidden !important" }, marginLeft: -1 }
              }}
            >
              {AGREEMENTS.map((item, i) => (
                <Card key={item.id} sx={{ height: "100%" }} size={CardSizeVariants.Small}>
                  <Typography variant={isMobile ? "caption" : "callout"}>{item.text}</Typography>
                </Card>
              ))}
            </SwipeableMobileStepper>
          ) : (
            <Card color={CardColorVariants.Muted} size={isMobile ? CardSizeVariants.Small : CardSizeVariants.Medium}>
              {AGREEMENTS.map((item, index) => (
                <MuiBox mb={index !== AGREEMENTS.length - 1 ? 1.5 : 1} key={item.id}>
                  <GridContainer wrap="nowrap" spacing={{ xs: 2 }} direction={{ xs: "row" }} pr={2}>
                    <GridItem xs="auto">
                      <Bullet mt={1.3} />
                    </GridItem>
                    <GridItem>
                      <Typography variant="caption">{item.text}</Typography>
                    </GridItem>
                  </GridContainer>
                </MuiBox>
              ))}
            </Card>
          )}
        </BlockLayout>
      </StyledCard>
      <StyledCard
        color={isMobile ? CardColorVariants.Muted : CardColorVariants.Initial}
        size={isMobile ? CardSizeVariants.Small : CardSizeVariants.Initial}
      >
        <Typography variant="caption" color="secondary" component="h3">
          {validatingServiceFee}
        </Typography>
        <BlockLayout description={<Typography variant="title2">{percentOfRewards}</Typography>} />
      </StyledCard>
    </MuiBox>
  )
}

export default memo(Review)
