import { SocialIconVariant } from "@stakefish/ui/components/SocialIcon"

export const SOCIAL_LINKS = [
  {
    icon: SocialIconVariant.Telegram,
    url: "https://t.me/stakefish",
    name: "stake.fish"
  },
  {
    icon: SocialIconVariant.Twitter,
    url: "https://twitter.com/stakefish",
    name: "stakefish"
  },
  {
    icon: SocialIconVariant.YouTube,
    url: "https://www.youtube.com/c/stakefish/",
    name: "stake.fish"
  },
  {
    icon: SocialIconVariant.Instagram,
    url: "http://instagram.com/stakedotfish",
    name: "stakedotfish"
  },
  {
    icon: SocialIconVariant.LinkedIn,
    url: "https://www.linkedin.com/company/stakefish",
    name: "stakefish"
  },
  {
    icon: SocialIconVariant.Medium,
    url: "https://medium.com/stakefish",
    name: "stakefish"
  },
  {
    icon: SocialIconVariant.Reddit,
    url: "https://www.reddit.com/r/stakefish",
    name: "stakefish"
  }
]

export const FEEDBACK_EMAIL = "support@stake.fish"

export const LINK_CONTACT = "https://stake.fish/en/contact/"

export const LINK_PRIVACY_POLICY = "https://stake.fish/en/privacy-policy/"

export const LINK_TERMS_OF_SERVICE = "https://stake.fish/en/ethereum/terms-of-service/"

export const LINK_DASHBOARD = "https://stake.fish/ethereum-shared/dashboard/?mtm_campaign=eth-staking-2"

export const META_DATA = {
  name: "stakefish",
  title: "Stake Ethereum with just 0.1 ETH",
  description:
    "Stake Ethereum with a reliable validator, with as little as 0.1 ETH. Your friendly neighborhood validator, stakefish.",
  keywords:
    "staking ethereum, ethereum staking pool, how to stake ethereum, eth staking, eth2.0, eth2 staking, eth2 staking rewards, eth2 launch"
}
