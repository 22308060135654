import { StakingStates } from "./staking"
import { SupportedWallet } from "./wallet"

export enum StorageKey {
  WALLET_CONNECT = "walletconnect",
  WALLET_CONNECTED = "walletConnected",
  STAKE_AMOUNT = "stakeAmount",
  EXIT_DATE = "exitDate",
  AGREEMENTS_SIGNATURE = "agreementsSignature",
  LAST_EDIT_TIMESTAMP = "lastEditTimeStamp",
  USER_EMAIL = "userEmail"
}

export interface StorageProps {
  [StorageKey.WALLET_CONNECT]?: {}
  [StorageKey.WALLET_CONNECTED]?: SupportedWallet["name"]
  [StorageKey.STAKE_AMOUNT]?: StakingStates["stakeAmount"]
  [StorageKey.EXIT_DATE]?: StakingStates["exitDate"]
  [StorageKey.AGREEMENTS_SIGNATURE]?: StakingStates["agreementsSignature"]
  [StorageKey.USER_EMAIL]?: StakingStates["userEmail"]
  [StorageKey.LAST_EDIT_TIMESTAMP]?: Date
}
