import React from "react"
import { FormattedMessage } from "react-intl"
import { WalletStates, SupportedWallet } from "../../types/wallet"
import Button from "@stakefish/ui/components/Button"
import { ConnectingLayout, DisabledWalletButton, StateIndicator } from "./private"

/**
 * Types
 */
export interface ConnectErrorViewProps {
  connectingWallet: SupportedWallet | null
  prev: boolean
  onPrevClick: React.MouseEventHandler<HTMLButtonElement>
  errorMsg?: WalletStates["errorOnWalletConnection"]
}

/**
 * Components
 */
export const ConnectErrorView = ({
  connectingWallet,
  onPrevClick,
  errorMsg,
  ...layoutProps
}: ConnectErrorViewProps) => {
  return (
    <ConnectingLayout {...layoutProps}>
      {connectingWallet && <DisabledWalletButton wallet={connectingWallet} />}
      <StateIndicator
        type="negative"
        title={<FormattedMessage id="ERROR" />}
        subtitle={errorMsg ?? <FormattedMessage id="ERR_ETH_GENERAL" />}
        error
      />
      <Button size="xs" onClick={onPrevClick}>
        {<FormattedMessage id="TRY_AGAIN" />}
      </Button>
    </ConnectingLayout>
  )
}
