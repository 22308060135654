import { ReactNode } from "react"
import { ethers, providers } from "ethers"
import { SupportedWalletShape } from "@stakefish/ui/components/WalletButton"
import { ActionShape } from "./context"

export type WalletType = "MetaMask" | "WalletConnect"

export type WalletActionKey =
  | "SET_CONNECTED_WALLET"
  | "SET_WEB3_PROVIDER"
  | "SET_BALANCE"
  | "SET_ADDRESS"
  | "SET_NETWORK"
  | "SET_HISTORY"
  | "SET_WALLET_CONNECTED_INFO"
  | "SET_ERROR_ON_WALLET_CONNECTION"

export type WalletActionsUnion =
  | SetConnectedWallet
  | SetWeb3Provider
  | SetBalance
  | SetAddress
  | SetNetwork
  | SetHistory
  | SetErrorOnWalletConnection
  | SetWalletConnectedInfo

export type TransactionReceipt = providers.TransactionReceipt

export type TransactionResponse = providers.TransactionResponse

export type Web3Provider = WalletStates["web3Provider"] | null

export type ProviderErrorMsg = string | null

export interface SupportedWallet extends SupportedWalletShape {
  /**
   * Supported devices by the connector.
   */
  devices: "all" | "desktop" | "mobile"
  name: WalletType
}

export interface MetaMaskWallet extends SupportedWallet {
  connector: () => Promise<[Web3Provider, ProviderErrorMsg]>
}

export interface WalletConnectWallet extends SupportedWallet {
  connector: () => any
}

export type SupportedWallets = Record<WalletType, SupportedWallet>

export const SupportedNetworks = ["homestead"] as const
export type SupportedNetwork = typeof SupportedNetworks[number]

export enum WalletWidgetState {
  DISCONNECTED = "Disconnected",
  CONNECTING = "Connecting",
  CONNECT_ERROR = "ConnectError",
  CONNECTED = "Connected",
  WALLET_CHANGING = "WalletChanging"
}

export enum WalletState {
  CONNECTED_WALLET = "connectedWallet",
  BALANCE = "balance",
  ADDRESS = "address",
  NETWORK = "network",
  HISTORY = "history",
  WEB3_PROVIDER = "web3Provider",
  ERROR_ON_WALLET_CONNECTION = "errorOnWalletConnection"
}

export interface WalletStates {
  /**
   * Wallet that user's connected to.
   * If `null`, user's not connected to any wallet.
   */
  [WalletState.CONNECTED_WALLET]: SupportedWallet | null
  /**
   * Web3 provider used for interacting with the wallet
   */
  [WalletState.WEB3_PROVIDER]: ethers.providers.Web3Provider | null
  /**
   * Balance value obtained from connected wallet.
   */
  [WalletState.BALANCE]?: number // TODO: The balance should be a BigNumber
  /**
   * Address value obtained from connected wallet.
   */
  [WalletState.ADDRESS]?: string
  /**
   * Network value obtained from connected wallet.
   */
  [WalletState.NETWORK]?: ethers.providers.Network
  /**
   * The list of transactions from connected wallet.
   */
  [WalletState.HISTORY]: TransactionResponse[]
  /**
   * Error message during wallet connection process.
   */
  [WalletState.ERROR_ON_WALLET_CONNECTION]?: string | ReactNode
}

interface WalletActionShape extends ActionShape<WalletActionKey, WalletState> {}
interface SetConnectedWallet extends WalletActionShape {
  type: "SET_CONNECTED_WALLET"
  payload: {
    field: WalletState.CONNECTED_WALLET
    value: WalletStates[WalletState.CONNECTED_WALLET]
  }
}

interface SetWeb3Provider extends WalletActionShape {
  type: "SET_WEB3_PROVIDER"
  payload: {
    field: WalletState.WEB3_PROVIDER
    value: WalletStates[WalletState.WEB3_PROVIDER]
  }
}

interface SetBalance extends WalletActionShape {
  type: "SET_BALANCE"
  payload: {
    field: WalletState.BALANCE
    value: WalletStates[WalletState.BALANCE]
  }
}

interface SetAddress extends WalletActionShape {
  type: "SET_ADDRESS"
  payload: {
    field: WalletState.ADDRESS
    value: WalletStates[WalletState.ADDRESS]
  }
}

interface SetNetwork extends WalletActionShape {
  type: "SET_NETWORK"
  payload: {
    field: WalletState.NETWORK
    value: WalletStates[WalletState.NETWORK]
  }
}

interface SetHistory extends WalletActionShape {
  type: "SET_HISTORY"
  payload: {
    field: WalletState.HISTORY
    value: WalletStates[WalletState.HISTORY]
  }
}

interface SetErrorOnWalletConnection extends WalletActionShape {
  type: "SET_ERROR_ON_WALLET_CONNECTION"
  payload: {
    field: WalletState.ERROR_ON_WALLET_CONNECTION
    value: WalletStates[WalletState.ERROR_ON_WALLET_CONNECTION]
  }
}

interface SetWalletConnectedInfo {
  type: "SET_WALLET_CONNECTED_INFO"
  payload: {
    field: any
    value: any
  }
  // TODO: refactor this interface for valid type checking.
  payloads: {
    [WalletState.CONNECTED_WALLET]: WalletStates[WalletState.CONNECTED_WALLET]
    [WalletState.WEB3_PROVIDER]: WalletStates[WalletState.WEB3_PROVIDER]
    [WalletState.BALANCE]: WalletStates[WalletState.BALANCE]
    [WalletState.ADDRESS]: WalletStates[WalletState.ADDRESS]
    [WalletState.NETWORK]: WalletStates[WalletState.NETWORK]
    [WalletState.HISTORY]: WalletStates[WalletState.HISTORY]
  }
}
