import { ethers } from "ethers"
import { MINIMUM_STAKING_VALUE } from "../consts/staking"
import {
  STAKEFISH_SERVICES_CONTRACT_FACTORY_ADDRESS,
  STAKEFISH_SERVICES_CONTRACT_FACTORY_ABI,
  USER_WALLET_ACTION_TIMEOUT
} from "../consts/wallet"
import { DepositPlan, StakingStates } from "../types/staking"
import { DefiniteWeb3Provider } from "../types/web3"
import { retrieveSaltAddressesFromContract } from "../utils/web3"
import { SignedAgreementPayload } from "../data/storeSignedAgreement"

const walletFactory = (web3Provider: DefiniteWeb3Provider) => ({
  retrieveAddress: async () => {
    try {
      const signer = await web3Provider.getSigner()
      const address = await signer.getAddress()

      return address
    } catch (e) {
      throw e as Error
    }
  },
  retrieveWallet: async (address: string) => {
    try {
      const network = await web3Provider.getNetwork()
      if (network.chainId !== 1) {
        throw new Error("mainnetRequirement")
      }
      const balance = await web3Provider.getBalance(address)

      // TODO uncomment if we need to bring it back - remember about the API keys! [mateusz]
      // const etherscan = new ethers.providers.EtherscanProvider(network)
      // const history = await etherscan.getHistory(address)

      return {
        balance: Number(ethers.utils.formatEther(balance)),
        address,
        network,
        history: []
      }
    } catch (e) {
      throw e as Error
    }
  },
  changeChainId: async (chainId: number) => {
    await web3Provider.send("wallet_switchEthereumChain", [
      {
        chainId: `0x${chainId}`
      }
    ])
  },
  signAgreement: async (address: string, message: string): Promise<SignedAgreementPayload> => {
    return await new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error("timeoutSignMessage"))
      }, USER_WALLET_ACTION_TIMEOUT)

      const agreement = "0x" + Buffer.from(message, "utf8").toString("hex")
      web3Provider
        .send("personal_sign", [agreement, address])
        .then((signature: string) => {
          clearTimeout(timeout)
          resolve({
            address,
            agreement,
            signature
          })
        })
        .catch((e: string) => {
          clearTimeout(timeout)
          reject(e)
        })
    })
  },
  estimateGasCost: async (
    contracts: DepositPlan[],
    depositSize: StakingStates["stakeAmount"] = MINIMUM_STAKING_VALUE
  ) => {
    try {
      const saltAddresses = retrieveSaltAddressesFromContract(contracts)

      const factory = new ethers.Contract(
        STAKEFISH_SERVICES_CONTRACT_FACTORY_ADDRESS,
        STAKEFISH_SERVICES_CONTRACT_FACTORY_ABI,
        web3Provider
      )
      const gasUsed = await factory.estimateGas.fundMultipleContracts(saltAddresses, false, {
        value: ethers.utils.parseEther(depositSize.toString())
      })
      const gasPrice = await web3Provider.getGasPrice()

      return Number(ethers.utils.formatEther(gasUsed.mul(gasPrice)))
    } catch {
      // TODO: enhance error handling
    }

    return undefined
  },
  broadcastAndGetTx: async (
    contracts: DepositPlan[],
    depositSize: StakingStates["stakeAmount"] = MINIMUM_STAKING_VALUE
  ) => {
    try {
      const saltAddresses = retrieveSaltAddressesFromContract(contracts)

      const factory = new ethers.Contract(
        STAKEFISH_SERVICES_CONTRACT_FACTORY_ADDRESS,
        STAKEFISH_SERVICES_CONTRACT_FACTORY_ABI,
        web3Provider
      )
      const signer = web3Provider.getSigner()
      const tx = await factory
        .connect(signer)
        .fundMultipleContracts(saltAddresses, false, { value: ethers.utils.parseEther(depositSize.toString()) })

      return tx
    } catch (e) {
      throw e as Error
    }
  },
  getBroadcastReceipt: async (tx: any): Promise<{ txHash: string; gasCost: number } | undefined> => {
    try {
      const receipt = await tx.wait()
      const txHash: string = tx.hash
      const gasCost = receipt.gasUsed.mul(tx.gasPrice)

      return {
        txHash,
        gasCost: Number(ethers.utils.formatEther(gasCost))
      }
    } catch (e) {
      throw e as Error
    }
  }
})

export default walletFactory
