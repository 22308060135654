import React from "react"
import { useIntl } from "react-intl"

import { styled, useTheme } from "@mui/material/styles"
import MuiBox from "@mui/material/Box"
import useMediaQuery from "@mui/material/useMediaQuery"
import Typography from "@stakefish/ui/components/Typography"
import SfLinearProgress from "@stakefish/ui/components/LinearProgress"
import { StakingSteps, StakingStates, StakingState, StakingStep } from "../types/staking"

export interface ProgressProps {
  steps: StakingSteps
  [StakingState.CURRENT_STEP]: StakingStates["currentStep"]
  [StakingState.STAKE_AMOUNT]: StakingStates["stakeAmount"]
  [StakingState.TX_BROADCAST_STATE]: StakingStates["txBroadcastState"]
}

const Wrapper = styled(MuiBox)(({ theme }) => ({}))

const Stepper: React.FC<ProgressProps> = ({ steps, currentStep }) => {
  const intl = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Wrapper>
      <MuiBox display="flex" justifyContent="space-between" alignItems="center" mb={{ xs: 0.85, sm: 1 }}>
        <Typography variant={isMobile ? "title5" : "title2"} sx={{ fontWeight: "bold" }}>
          {intl.formatMessage({ id: "ETHEREUM_STAKING_SHORT" })}
        </Typography>
        <Typography variant="title5" color="secondary">
          {currentStep === StakingStep.Done ? steps.length : currentStep}/{steps.length}
        </Typography>
      </MuiBox>

      <MuiBox>
        <SfLinearProgress value={currentStep === StakingStep.Done ? 100 : (100 / steps.length) * currentStep} />
      </MuiBox>
    </Wrapper>
  )
}

export default Stepper
