import React from "react"
import { useIntl } from "react-intl"
import { SupportedWallet } from "../../types/wallet"
import { ConnectingLayout, DisabledWalletButton, StateIndicator } from "./private"

/**
 * Types
 */
export interface ConnectingViewProps {
  connectingWallet: SupportedWallet | null
}

/**
 * Components
 */
export const ConnectingView = ({ connectingWallet, ...layoutProps }: ConnectingViewProps) => {
  const intl = useIntl()

  return (
    <ConnectingLayout {...layoutProps}>
      {connectingWallet && <DisabledWalletButton wallet={connectingWallet} />}
      <StateIndicator type="connecting" title={intl.formatMessage({ id: "CONNECTING" })} />
    </ConnectingLayout>
  )
}
