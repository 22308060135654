import { useState, useContext, useEffect, useCallback, useMemo } from "react"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Icon from "@stakefish/ui/components/Icon"
import Snackbar from "@stakefish/ui/components/Snackbar"
import { AppContext, DefaultContext } from "../context"
import { GlobalStates } from "../types/global"
import { usePrevious } from "../hooks/usePrevious"
import { useMatomo } from "../hooks/useMatomo"

interface DefiniteSnackPack extends Exclude<GlobalStates["snackbarPack"], undefined> {
  key: number
}

const SnackbarPack: React.FC = () => {
  const { state } = useContext(AppContext as DefaultContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [snackPack, setSnackPack] = useState<readonly DefiniteSnackPack[]>([])
  const prevSnackPack = usePrevious(state.global.snackbarPack)
  const [open, setOpen] = useState(false)
  const [messageInfo, setMessageInfo] = useState<GlobalStates["snackbarPack"] | undefined>(undefined)
  const { trackEvent } = useMatomo()

  /***********/
  /* Helpers */
  /***********/
  const handleClose = useCallback(
    (event, reason) => {
      if (reason === "clickaway") return

      if (messageInfo?.onClose) messageInfo?.onClose(event, reason)
      setOpen(false)
    },
    [messageInfo]
  )
  const handleExited = useCallback(() => {
    setMessageInfo(undefined)
  }, [setMessageInfo])

  /***********/
  /* Updates */
  /***********/
  /**
   *
   * Update local snackPack array according to global change.
   *
   */
  useEffect(() => {
    if (prevSnackPack?.type !== state.global.snackbarPack?.type) {
      setSnackPack((prev) => [
        ...prev,
        { ...state.global.snackbarPack, key: new Date().getTime() } as DefiniteSnackPack
      ])
    }
  }, [state.global.snackbarPack])

  /**
   *
   * Close Snackbar if global bar pack is from valid to undefined.
   *
   */
  useEffect(() => {
    if (!state.global.snackbarPack) {
      setSnackPack([])
      setOpen(false)
    }
  }, [state.global.snackbarPack])

  /**
   *
   * Update Snackbar content according to local state changes.
   *
   */
  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] })
      setSnackPack((prev) => prev.slice(1))
      setOpen(true)

      // Matomo
      trackEvent({
        category: "snackbar",
        action: "shown",
        name: `${state.staking.currentStep}-${snackPack[0].type}`
      })
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false)

      // Matomo
      trackEvent({
        category: "snackbar",
        action: "hidden",
        name: `${state.staking.currentStep}`
      })
    }
  }, [snackPack, messageInfo, open])

  /***********/
  /* Main */
  /***********/
  const { key, alertProps, ...props } = useMemo(() => {
    if (messageInfo) return messageInfo

    return {
      key: undefined,
      alertProps: undefined
    }
  }, [messageInfo])

  return (
    <Snackbar
      key={messageInfo ? key : undefined}
      alertProps={{
        icon: <Icon iconKey="infoCircle" size={!isMobile ? "sm" : "xs1"} />,
        sx: { alignItems: "center" },
        ...alertProps
      }}
      {...props}
      open={open}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      data-test-id="snackbar"
    >
      {messageInfo?.children}
    </Snackbar>
  )
}

export default SnackbarPack
