import React from "react"
import { styled } from "@mui/material/styles"
import MuiBox from "@mui/material/Box"

const LogoRoot = styled(MuiBox)(({ theme }) => ({
  width: theme.spacing(9),
  minWidth: theme.spacing(9),
  overflow: "hidden",
  transition: "width 0.4s ease",
  display: "block",

  "& svg": {
    width: theme.spacing(25),

    "& .fish_circle": {
      transition: "all 0.2s ease",
      transformOrigin: "34px center"
    },

    "& .fish_tail": {
      transition: "all 0.2s ease",
      transformOrigin: "20px center"
    }
  },

  [theme.breakpoints.down("sm")]: {
    width: theme.spacing(5),
    minWidth: theme.spacing(5),

    "& svg": {
      width: theme.spacing(14),
      height: "auto"
    }
  }
}))

const Logo = () => {
  return (
    <LogoRoot>
      <svg viewBox="0 0 224 49" style={{ display: "block" }}>
        <path
          className="fish_circle"
          d="M55.3,0c-7.3,0-13.9,3.2-18.4,8.4c5,0.9,9.7,3.1,13.5,6.4c2.7,2.2,5.1,4.8,7.1,7.6l1.2,1.7
          c0.2,0.3,0.2,0.6-0.1,0.8l-1.2,1.7c-2,2.9-4.4,5.4-7.1,7.6c-3.9,3.3-8.6,5.5-13.5,6.4c4.5,5.1,11.1,8.4,18.5,8.4
      c13.6,0,24.6-11,24.6-24.6S68.8,0,55.3,0z"
          fill="#cf0"
        />
        <path
          className="fish_head"
          d="M33.5,36c0.1,0,0.3,0,0.4,0c9.3-0.6,15.8-7.4,18.7-10.9c0.2-0.3,0.2-0.6,0-0.8c-2.9-3.6-9.4-10.3-18.7-11
          c-0.1,0-0.3,0-0.4,0c-1.8,3.4-2.8,7.3-2.8,11.4C30.7,28.7,31.7,32.5,33.5,36z M43.4,22c0.1,0,0.2,0,0.3,0c1.4,0.1,2.5,1.3,2.4,2.7
          c-0.1,1.4-1.3,2.5-2.7,2.4C42,27,40.9,25.9,41,24.4C41.1,23.1,42.1,22.1,43.4,22z"
        />
        <path
          className="fish_text"
          d="M187.7,10.7c-0.4,0.4-0.7,1-0.7,1.6c0,0.6,0.2,1.2,0.7,1.6c0.5,0.4,1.1,0.6,1.8,0.6c0.6,0,1.3-0.2,1.7-0.6
          c0.5-0.4,0.7-1,0.7-1.6c0-0.6-0.2-1.2-0.7-1.6C190.2,9.9,188.8,9.9,187.7,10.7z"
        />
        <path
          className="fish_text"
          d="M189.7,17.2h-8.6v-0.5c-0.1-0.7,0.2-1.4,0.6-2.1c0.5-0.5,1.2-0.8,1.9-0.8l0.7-0.1c0.4,0,0.8-0.2,1.1-0.5
          c0.3-0.3,0.4-0.7,0.4-1.2c0-0.7-0.3-1.6-1.8-1.6c-0.2,0-0.3,0-0.5,0.1l-0.7,0.1c-1.6,0.1-3.2,0.8-4.3,2c-1.1,1.3-1.6,2.9-1.5,4.5
          h-1.3c-1.6,0-1.9,0.9-1.9,1.6s0.3,1.6,1.9,1.6h1.3v10.1c-0.1,0.6,0.2,1.1,0.6,1.5c0.4,0.3,1,0.5,1.5,0.5c0.5,0,1.1-0.2,1.5-0.5
          s0.6-0.9,0.6-1.5V20.4h6.3v10.1c-0.1,0.6,0.2,1.1,0.6,1.5c0.4,0.4,1,0.5,1.5,0.5s1.1-0.2,1.5-0.5s0.6-1,0.6-1.5V18.7
          C191.6,18,191.2,17.2,189.7,17.2z"
        />
        <path
          className="fish_text"
          d="M204.9,24.2c-1.1-0.4-2.1-0.8-3.2-1c-0.8-0.2-1.7-0.4-2.5-0.8c-0.4-0.2-0.6-0.6-0.6-1c0-0.4,0.2-0.8,0.6-1.1
          c0.5-0.3,1.1-0.4,1.6-0.4c0.5,0,1,0.1,1.5,0.2c0.5,0.2,1,0.4,1.5,0.7c0.3,0.2,0.5,0.3,0.8,0.4c0.2,0.1,0.5,0.2,0.7,0.2
          c0.4,0,0.7-0.2,1-0.5c0.3-0.3,0.4-0.7,0.4-1.2c0-0.7-0.4-1.4-1.1-1.8c-0.7-0.4-1.4-0.7-2.2-1c-0.8-0.2-1.6-0.3-2.5-0.3
          c-1.1,0-2.2,0.2-3.2,0.6c-0.9,0.4-1.7,1-2.3,1.7c-0.5,0.7-0.8,1.6-0.8,2.5c-0.1,1.1,0.4,2.2,1.2,2.9c0.8,0.7,2.1,1.2,4,1.6
          c0.7,0.2,1.5,0.4,2.2,0.6c0.4,0.1,0.7,0.3,1,0.5c0.2,0.2,0.3,0.4,0.2,0.7c0,0.4-0.2,0.8-0.6,1c-0.6,0.3-1.2,0.4-1.9,0.4
          c-0.7,0-1.3-0.1-2-0.2c-0.6-0.2-1.2-0.4-1.7-0.7c-0.5-0.3-1-0.5-1.5-0.5c-0.4,0-0.7,0.2-1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1
          c0,0.7,0.4,1.4,1.1,1.8c1.4,0.9,3.2,1.3,5.4,1.3c1.6,0.1,3.3-0.4,4.6-1.3c1.2-0.8,1.8-2.1,1.8-3.5c0.1-0.8-0.2-1.5-0.5-2.2
          C206.2,25,205.6,24.5,204.9,24.2z"
        />
        <path
          className="fish_text"
          d="M218.5,16.8c-1,0-2,0.2-3,0.6c-0.7,0.3-1.3,0.8-1.7,1.4v-6.4c0-0.5-0.2-1.1-0.6-1.4c-0.9-0.7-2.1-0.7-3,0.1
          c-0.4,0.4-0.6,0.9-0.6,1.4v18.1c-0.1,0.5,0.2,1.1,0.6,1.5c0.4,0.4,1,0.6,1.5,0.5c0.6,0.1,1.1-0.2,1.5-0.5c0.4-0.4,0.6-1,0.6-1.5
          v-6.8c-0.1-1,0.3-1.9,1-2.6c0.6-0.6,1.5-1,2.5-1c0.7-0.1,1.4,0.2,1.9,0.7c0.5,0.6,0.7,1.4,0.6,2.2v7.4c-0.1,0.5,0.2,1.1,0.6,1.5
          c0.4,0.4,1,0.6,1.5,0.5c0.6,0.1,1.1-0.2,1.5-0.5c0.4-0.4,0.6-1,0.6-1.5v-7.4C223.9,18.9,222.1,16.8,218.5,16.8z"
        />
        <path
          className="fish_text"
          d="M97.1,24.2c-1.1-0.4-2.1-0.8-3.2-1c-0.8-0.2-1.7-0.4-2.5-0.8c-0.4-0.2-0.6-0.6-0.6-1c0-0.4,0.2-0.8,0.6-1.1
          c0.5-0.3,1.1-0.4,1.6-0.4c0.5,0,1,0.1,1.5,0.2c0.5,0.2,1,0.4,1.5,0.7c0.3,0.2,0.5,0.3,0.8,0.4c0.2,0.1,0.5,0.2,0.7,0.2
          c0.4,0,0.7-0.2,1-0.5c0.3-0.3,0.4-0.7,0.4-1.2c0-0.7-0.4-1.4-1.1-1.8c-0.7-0.4-1.4-0.7-2.2-1c-0.8-0.2-1.6-0.3-2.5-0.3
          c-1.1,0-2.2,0.2-3.2,0.6c-0.9,0.4-1.7,1-2.2,1.7c-0.5,0.7-0.8,1.6-0.8,2.5c-0.1,1.1,0.4,2.2,1.2,2.9c0.8,0.7,2.1,1.2,4,1.6
          c0.7,0.2,1.5,0.4,2.2,0.6c0.4,0.1,0.7,0.3,1,0.5c0.2,0.2,0.3,0.4,0.2,0.7c0,0.4-0.2,0.8-0.6,1c-0.6,0.3-1.2,0.4-1.9,0.4
          s-1.3-0.1-2-0.2c-0.6-0.2-1.2-0.4-1.7-0.7c-0.5-0.3-1-0.5-1.5-0.5c-0.4,0-0.7,0.2-1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1
          c0,0.7,0.4,1.5,1.1,1.8c1.4,0.9,3.2,1.3,5.4,1.3c1.6,0.1,3.3-0.4,4.7-1.3c1.2-0.8,1.8-2.1,1.8-3.5c0.1-0.8-0.2-1.5-0.5-2.2
          C98.4,25,97.8,24.5,97.1,24.2z"
        />
        <path
          className="fish_text"
          d="M110.1,29.3l-0.8-0.1c-1.3-0.1-1.8-0.8-1.8-2.2v-6.7h2.4c0.5,0.1,1-0.1,1.4-0.4c0.7-0.6,0.7-1.7,0.1-2.4
          c0,0,0,0-0.1-0.1c-0.4-0.3-0.9-0.4-1.4-0.4h-2.4v-2.6c0-0.5-0.2-1.1-0.6-1.4c-0.4-0.4-1-0.5-1.5-0.5c-0.6-0.1-1.1,0.2-1.5,0.5
          c-0.4,0.4-0.6,0.9-0.6,1.4v2.6H102c-0.5-0.1-1,0.1-1.4,0.4c-0.7,0.6-0.7,1.7-0.1,2.4c0,0,0,0,0.1,0.1c0.4,0.3,0.9,0.4,1.4,0.4h1.3
          v6.4c0,3.5,1.8,5.4,5.3,5.6l0.8,0.1h0.3c0.5,0,1.1-0.1,1.5-0.4c0.4-0.3,0.7-0.7,0.7-1.3C111.8,30.2,111.6,29.4,110.1,29.3z"
        />
        <path
          className="fish_text"
          d="M120.4,16.8c-1,0-2,0.1-3,0.3c-0.9,0.2-1.7,0.5-2.6,1c-0.4,0.2-0.7,0.5-1,0.8c-0.2,0.3-0.3,0.6-0.3,1
          c0,0.4,0.1,0.8,0.4,1.2c0.3,0.3,0.6,0.5,1.1,0.5c0.4-0.1,0.8-0.2,1.2-0.4c0.8-0.3,1.5-0.6,2.1-0.7c0.6-0.2,1.2-0.3,1.7-0.3
          c0.7-0.1,1.4,0.2,1.9,0.6c0.4,0.6,0.6,1.3,0.6,2v0.5h-0.6c-1.7-0.1-3.5,0.1-5.2,0.4c-1.1,0.2-2.1,0.7-2.8,1.4
          c-0.6,0.7-1,1.7-0.9,2.7c0,0.9,0.3,1.7,0.7,2.5c0.5,0.7,1.2,1.3,2,1.7c0.8,0.4,1.8,0.6,2.8,0.6c1,0,1.8-0.2,2.6-0.7
          c0.5-0.3,1-0.7,1.3-1.3c0,0.5,0.2,1,0.6,1.3c0.4,0.4,0.9,0.5,1.4,0.5c0.5,0.1,1.1-0.2,1.5-0.5c0.4-0.4,0.6-0.9,0.5-1.4v-7.4
          c0.1-2.1-0.5-3.7-1.5-4.8C124.1,17.3,122.5,16.8,120.4,16.8z M122.8,26c0.1,0.9-0.3,1.8-0.9,2.5c-0.6,0.6-1.4,1-2.3,1
          c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.4c0-0.4,0.1-0.8,0.4-1.1c0.4-0.3,1-0.5,1.5-0.6c1.1-0.2,2.1-0.2,3.1-0.2h0.4
          L122.8,26L122.8,26z"
        />
        <path
          className="fish_text"
          d="M143.7,29L143.7,29l-5.1-4.5l4.6-4.3c0.4-0.4,0.6-0.9,0.6-1.4s-0.2-1-0.6-1.4c-0.4-0.4-0.8-0.6-1.4-0.6
          c-0.5,0-1,0.2-1.4,0.6l-6.1,5.9V12.4c0.1-0.6-0.2-1.1-0.6-1.5c-0.4-0.3-1-0.5-1.5-0.5c-0.5,0-1.1,0.2-1.5,0.5
          c-0.4,0.4-0.6,0.9-0.6,1.5v18.1c-0.1,0.6,0.2,1.1,0.6,1.5c0.4,0.3,1,0.5,1.5,0.5c0.5,0,1.1-0.2,1.5-0.5c0.4-0.4,0.6-0.9,0.6-1.5
          v-4.8l6.8,6.1c0.4,0.4,0.8,0.6,1.4,0.6s1-0.2,1.3-0.6C144.5,31,144.5,29.8,143.7,29C143.7,29.1,143.7,29.1,143.7,29z"
        />
        <path
          className="fish_text"
          d="M159.9,24.1c0.1-1.3-0.3-2.7-0.8-3.9c-0.5-1.1-1.4-2-2.4-2.6c-1.1-0.6-2.3-1-3.6-0.9c-1.4,0-2.7,0.3-3.9,1
          c-1.1,0.7-2.1,1.6-2.6,2.8c-0.6,1.3-1,2.7-1,4.1c0,2.4,0.7,4.4,2.2,5.8c1.4,1.4,3.4,2.1,5.8,2.1c0.8,0,1.7-0.1,2.5-0.4
          c0.8-0.2,1.6-0.5,2.4-1c0.7-0.3,1.2-1,1.2-1.7c0-0.4-0.1-0.8-0.4-1.2c-0.2-0.3-0.6-0.5-1-0.5c-0.5,0.1-1,0.2-1.4,0.5
          c-0.5,0.3-1.1,0.5-1.6,0.6c-0.5,0.2-1.1,0.3-1.6,0.2c-1,0.1-2-0.3-2.7-1c-0.7-0.7-1.1-1.7-1.1-2.7h8.7
          C159.1,25.6,159.9,25.3,159.9,24.1z M149.8,23.2c0.1-0.9,0.4-1.7,1-2.4c0.6-0.6,1.5-1,2.3-0.9c0.8-0.1,1.6,0.3,2.2,0.9
      c0.5,0.7,0.8,1.6,0.8,2.5L149.8,23.2L149.8,23.2z"
        />
        <path
          className="fish_text"
          d="M167.7,22c-0.1,0-0.2,0-0.3,0c-1.3,0.1-2.3,1.1-2.4,2.4c-0.1,1.4,1,2.6,2.4,2.7c1.4,0.1,2.6-1,2.7-2.4
      C170.2,23.3,169.1,22.1,167.7,22z"
        />
        <path
          className="fish_tail"
          d="M11.7,24.9c-0.2-0.2-0.2-0.5,0-0.7c5.8-7.2,13.7-11.5,21.8-11c0.9-1.7,2-3.3,3.3-4.8c-0.8-0.2-1.7-0.3-2.6-0.3
          c-7.6-0.3-15,2.3-20.7,7.2c-1.7,1.4-3.3,3-4.8,4.8H8.7c-0.1,0.1-0.2,0.1-0.3-0.1c-1.6-2.9-2.9-5.9-3.8-9.1v-0.1
          c-0.3-0.7-0.8-1.2-1.5-1.5C2.6,9.1,1.9,9.1,1.4,9.4C0.4,10-0.2,11.1,0,12.3v0.1c1.2,4.2,2.9,8.1,5.2,11.8c0.1,0.2,0.1,0.4,0,0.6
          C3,28.5,1.2,32.5,0,36.6v0.1c0,1.1,0.6,2,1.6,2.4c1.1,0.5,2.4,0.1,3-1l0.1-0.1v-0.1C5.5,35,6.9,31.8,8.5,29l0,0
          c0.1-0.1,0.2-0.1,0.3,0c1.4,1.7,3,3.3,4.8,4.8c5.3,4.6,12,7.2,19,7.3c0.6,0,1.2,0,1.7-0.1c0.9-0.1,1.7-0.2,2.6-0.3
          c-1.3-1.4-2.4-3.1-3.3-4.8C25.4,36.3,17.5,32.1,11.7,24.9z"
        />
      </svg>
    </LogoRoot>
  )
}

export default Logo
