import React, { ReactNode } from "react"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import MuiBox from "@mui/material/Box"
import Icon from "@stakefish/ui/components/Icon"
import Button from "@stakefish/ui/components/Button"
import Tooltip, { TooltipProps, TooltipSizeVariants } from "@stakefish/ui/components/Tooltip"
import Typography, { TypographyProps } from "@stakefish/ui/components/Typography"

interface BlockLayoutProps {
  title?: string | ReactNode | ReactNode[]
  titleSize?: "sm" | "md"
  tooltip?: TooltipProps["title"]
  color?: TypographyProps["color"]
  description?: string | ReactNode | ReactNode[]
}

const BlockLayout: React.FC<BlockLayoutProps> = ({
  title,
  titleSize = "md",
  description,
  tooltip,
  color = "primary",
  children
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <MuiBox mb={{ xs: 2, sm: 2.5, md: 4 }}>
      <MuiBox mb={{ xs: title ?? description ? 1 : 0, md: title ?? description ? 2.4 : 0 }}>
        {title && (
          <MuiBox
            display="flex"
            alignItems="center"
            gap={0.5}
            mb={titleSize === "md" ? 0.5 : 1}
            style={{ maxWidth: theme.spacing(87) }}
          >
            <MuiBox>
              <Typography
                color={color}
                variant={isMobile ? "title2" : titleSize === "md" ? "title2" : "title3"}
                sx={{ fontWeight: isMobile ? 600 : titleSize === "md" ? 700 : 500 }}
              >
                {title}
              </Typography>
            </MuiBox>

            {tooltip && (
              <MuiBox mt={isMobile ? -1.2 : 0} mb={isMobile ? -1 : 0}>
                <Tooltip
                  size={isMobile ? TooltipSizeVariants.Small : TooltipSizeVariants.Large}
                  title={
                    <Typography my={isMobile ? 1 : 0} variant="caption" color="inversePrimary" component="div">
                      {tooltip}
                    </Typography>
                  }
                >
                  <Button variant="text" color="secondary" startIcon={<Icon iconKey="infoCircle" size="xs1" />} />
                </Tooltip>
              </MuiBox>
            )}
          </MuiBox>
        )}
        {description && (
          <MuiBox mb={3}>
            <Typography variant="caption" color="secondary" component="div">
              {description}
            </Typography>
          </MuiBox>
        )}
      </MuiBox>
      {children && <MuiBox>{children}</MuiBox>}
    </MuiBox>
  )
}

export default BlockLayout
