import { ReactNode } from "react"
import { ActionShape } from "./context"

export interface DepositPlan {
  totalDeposit: string
  address: string
  salt: string
}

export enum StakingStep {
  StakeAmount = 1,
  Review = 2,
  Sign = 3,
  Broadcasting = 4,
  Done = 5
}

export enum ExitDate {
  Fastest = 0,
  MiddlePoint = 6,
  Slowest = 12
}

export enum BroadcastState {
  Positive = "positive",
  Negative = "negative",
  Pending = "pending"
}

export type AmountInputErrorState = "overWalletBalance" | "belowMinStakeValue" | "overflowStepDigit"

export type StakingSteps = [ReactNode, ReactNode, ReactNode, ReactNode]

export type StepPaths = Record<StakingStep, string>

export type StakingActionKey =
  | "SET_CONTRACTS"
  | "SET_CURRENT_STEP"
  | "SET_STAKE_AMOUNT"
  | "SET_EXIT_DATE"
  | "SET_AGREEMENTS_SIGNATURE"
  | "SET_GAS_COST"
  | "SET_VALIDATORS_AMOUNT"
  | "SET_USER_EMAIL"
  | "SET_TX_BROADCAST_STATE"
  | "SET_TX_BROADCAST_RESULT"

export enum StakingState {
  CONTRACTS = "contracts",
  CURRENT_STEP = "currentStep",
  CURRENT_STEP_ERROR = "currentStepError",
  STAKE_AMOUNT = "stakeAmount",
  EXIT_DATE = "exitDate",
  AGREEMENTS_SIGNATURE = "agreementsSignature",
  GAS_COST = "gasCost",
  VALIDATORS_AMOUNT = "validatorsAmount",
  USER_EMAIL = "userEmail",
  TX_BROADCAST_STATE = "txBroadcastState",
  TX_BROADCAST_RESULT = "txBroadcastResult"
}
export interface StakingStates {
  /**
   * The list of smart contracts.
   */
  [StakingState.CONTRACTS]: DepositPlan[]
  /**
   * Step that user's currently interacting with. Default value should be `StakingStep.StakeAmount`.
   */
  [StakingState.CURRENT_STEP]: StakingStep
  /**
   * Input amount of ETH from user.
   */
  [StakingState.STAKE_AMOUNT]: number
  /**
   * Exit date selection from user. Default value should be `DEFAULT_EXIT_DATE` (fastest option).
   */
  [StakingState.EXIT_DATE]: ExitDate
  /**
   * Signed agreements hex value
   */
  [StakingState.AGREEMENTS_SIGNATURE]?: string
  /**
   * Gas cost value of user's choice obtained from connected wallet.
   */
  [StakingState.GAS_COST]?: number
  /**
   * Estimated amount of validators at `StakingStep.Sign` step.
   */
  [StakingState.VALIDATORS_AMOUNT]?: number
  /**
   * This value could be obtained from two sources:
   * - 1. Wallet or our backend API. If user's connected with wallet and the email info is available.
   * - 2. The email input field at `StakingStep.Sign` step.
   */
  [StakingState.USER_EMAIL]?: string
  /**
   * State of the broadcast result. Default state should be `BroadcastState.Pending`.
   * If the broadcast result is available and obtained, this state should be updated accordingly,
   * to either `BroadcastState.Positive` or `BroadcastState.Negative`.
   */
  [StakingState.TX_BROADCAST_STATE]: BroadcastState
  /**
   * Payloads at `StakingStep.Broadcasting`, the final step.
   */
  [StakingState.TX_BROADCAST_RESULT]?: {
    /**
     * ETH1 TX id.
     */
    eth1Id?: string
    /**
     * External URL, usually Etherscan one, to the TX details.
     */
    eth1TxUrl?: string
    /**
     * Confirmed amount of validators received through this transaction.
     */
    validatorsAmount?: number
    /**
     * The detail error message if broadcast result state is `BroadcastState.Negative`.
     */
    errorMsg?: ReactNode
  }
}

export type StakingActionsUnion =
  | SetContracts
  | SetCurrentStep
  | SetStakeAmount
  | SetExitDate
  | SetAgreementsSignature
  | SetGasCost
  | SetValidatorsAmount
  | SetUserEmail
  | SetBroadcastState
  | SetBroadcastResult

interface StakingActionShape extends ActionShape<StakingActionKey, StakingState> {}

interface SetContracts extends StakingActionShape {
  type: "SET_CONTRACTS"
  payload: {
    field: StakingState.CONTRACTS
    value: StakingStates[StakingState.CONTRACTS]
  }
}

interface SetCurrentStep extends StakingActionShape {
  type: "SET_CURRENT_STEP"
  payload: {
    field: StakingState.CURRENT_STEP
    value: StakingStates[StakingState.CURRENT_STEP]
  }
}

interface SetStakeAmount extends StakingActionShape {
  type: "SET_STAKE_AMOUNT"
  payload: {
    field: StakingState.STAKE_AMOUNT
    value: StakingStates[StakingState.STAKE_AMOUNT]
  }
}

interface SetExitDate extends StakingActionShape {
  type: "SET_EXIT_DATE"
  payload: {
    field: StakingState.EXIT_DATE
    value: StakingStates[StakingState.EXIT_DATE]
  }
}

interface SetAgreementsSignature extends StakingActionShape {
  type: "SET_AGREEMENTS_SIGNATURE"
  payload: {
    field: StakingState.AGREEMENTS_SIGNATURE
    value: StakingStates[StakingState.AGREEMENTS_SIGNATURE]
  }
}
interface SetGasCost extends StakingActionShape {
  type: "SET_GAS_COST"
  payload: {
    field: StakingState.GAS_COST
    value: StakingStates[StakingState.GAS_COST]
  }
}

interface SetValidatorsAmount extends StakingActionShape {
  type: "SET_VALIDATORS_AMOUNT"
  payload: {
    field: StakingState.VALIDATORS_AMOUNT
    value: StakingStates[StakingState.VALIDATORS_AMOUNT]
  }
}

interface SetUserEmail extends StakingActionShape {
  type: "SET_USER_EMAIL"
  payload: {
    field: StakingState.USER_EMAIL
    value: StakingStates[StakingState.USER_EMAIL]
  }
}

interface SetBroadcastState extends StakingActionShape {
  type: "SET_TX_BROADCAST_STATE"
  payload: {
    field: StakingState.TX_BROADCAST_STATE
    value: StakingStates[StakingState.TX_BROADCAST_STATE]
  }
}

interface SetBroadcastResult extends StakingActionShape {
  type: "SET_TX_BROADCAST_RESULT"
  payload: {
    field: StakingState.TX_BROADCAST_RESULT
    value: StakingStates[StakingState.TX_BROADCAST_RESULT]
  }
}
