export default {
  ADDRESS_COPIED: "Address copied!",
  AN_ERROR_OCCURED: "An error occurred",
  AS_SOON_AS_WITHDRAWALS_ARE_ENABLED: "As soon as withdrawals are enabled",
  BACK_TO_SIGN_AND_BROADCAST: "Back to sign and broadcast",
  BEFORE_STAKING_PLEASE_CONNECT_YOUR_WALLET: "Please connect your wallet to proceed.",
  BEFORE_YOU_CONTINUE_SOME_THINGS_TO_REMEMBER: " ",
  CHANGE: "Change",
  CONNECTED_WITH: "Connected with {name}",
  CONNECTING: "Connecting...",
  CONNECTED: "Connected",
  CONNECT_TO_A_WALLET: "Connect Wallet",
  CONNECT_WALLET: "Connect Wallet",
  COPYRIGHT_STAKE_FISH: "Copyright © 2020–2021 stake.fish",
  COPY_ADDRESS: "Copy address",
  DEPOSIT_AMOUNT: "Staking amount",
  DEPOSIT_IS_STILL_BROADCASTING: "Deposit is still broadcasting",
  DEPOSIT_TRANSACTION_HASNT_BEEN_BROADCASTED: "Staking transaction hasn’t been broadcasted",
  DEPOSIT_TRANSACTION_HAS_BEEN_BROADCASTED: "Staking transaction has been broadcasted",
  DO_NOT_LOSE_ACCESS_TO_YOUR_WALLET: " ",
  DO_NOT_USE_WALLET_ADDRESS: " ",
  ENTER_YOUR_EMAIL_ADDRESS_OPTIONAL: "Enter your email address (optional)",
  ERROR: "Error",
  SUCCESS: "Success",
  ESTIMATED_AMOUNT_OF_VALIDATORS: "Estimated amount of validators",
  ETH1_DEPOSIT_TRANSACTION_ID: "Staking transaction ID",
  ETHEREUM_STAKING: "Ethereum Staking",
  ETHEREUM_STAKING_SHORT: "Ethereum Staking",
  EXIT_DATE: "Select Exit Period",
  GAS_COST: "Gas cost",
  GO_BACK_TO_THE_PREVIOUS_STEP_AND_TRY_AGAIN: "Error: {error} {break}{break} Go back to the previous step and try again later. You can also contact <url>technical support</url>.",
  IF_THIS_TAKES_AN_EXCESSIVE_AMOUNT_OF_TIME: "This process could take up to 15 minutes. If this takes longer than 15 minutes, please contact <url>technical support</url>.",
  IF_YOU_HAVE_ANY_QUESTIONS_CONTACT_US: "If you have any questions, please <url>contact us</url>.",
  IF_YOU_HAVE_ANY_QUESTIONS_PLEASE_CONTACT_US: "If you have any questions, please contact us.",
  INSUFFICIENT_FUNDS_IN_CONNECTED_WALLET: "Insufficient funds in connected wallet.",
  I_HAVE_READ_AND_ACCEPTED_TOS: "I have read and accepted the <url1>Privacy Policy</url1> and <url2>Terms of Service</url2>.",
  I_UNDERSTAND_THE_SLASHING_RISK_ASSOCIATED_WITH_STAKING: "I understand the slashing risk associated with staking and validating in a Proof of Stake network like Ethereum. I understand that in the rare event that validators are slashed, stakefish is not liable for any slashing penalties of my staking deposit.",
  I_WONT_BE_ABLE_TO_WITHDRAW: "This means a few features, such as the ability to withdraw staked ETH, will have to wait a little longer after the Ethereum merge is complete. I understand that this could take one year or longer, and that it may never happen at all. I understand that there are no refunds once my staking deposit has been completed.",
  MINIMUM_STAKING_VALUE_IS: "Minimum staking value is 0.1.",
  NEXT_STEP: "Next step",
  ONE_YEAR_AFTER_WITHDRAWALS_ARE_ENABLED: "1 year after withdrawals are enabled",
  PERCENT_OF_REWARDS: "10% <highlight>of staking rewards</highlight>",
  PLEASE_REVIEW_AND_SIGN_USING_CONNECTED_WALLET: "Please review and sign using connected wallet.",
  PREV_STEP: "Previous step",
  PRIVACY_POLICY: "Privacy Policy",
  REVIEW_AGREEMENTS: "Review Agreements",
  SIGN_AND_BROADCAST: "Sign and Broadcast",
  SIX_MONTHS_AFTER_WITHDRAWALS_ARE_ENABLED: "6 months after withdrawals are enabled",
  STACKING_SUMMARY: "Staking Summary",
  STAKEFISH_DOES_NOT_HAVE_CUSTODY_OF_MY_DEPOSIT: "I understand that stakefish does not have custody of my staking deposit. My withdrawal private keys, which I have stored securely and which only I know about, control my funds. Protecting my withdrawal private keys is solely my responsibility, and stakefish has no way of retrieving my staking deposit.",
  STAKEFISH_VALIDATING_SERVICE_FEE: "stakefish Validator Fee",
  STAKE_AMOUNT: "Stake Amount",
  STAKE_AMOUNT_MUST_BE_AT_LEAST: "Stake amount must be at least 0.1 ETH.",
  STAKE_AMOUNT_OF_ETH: "Select Staking Amount",
  STEP: "Step {step}",
  SUBSCRIBE: "Subscribe",
  UNSUBSCRIBE: "Unsubscribe",
  TERMS_OF_SERVICE: "Terms of Service",
  THE_EXIT_DATE_IS_WHEN_AN_EXIT_TRANSACTION_WILL_BE_SENT: "The exit period is when a transaction will be sent to exit the validator. After a waiting period, you will be able to withdraw the staked Ethereum and rewards.",
  TRANSACTION_BROADCASTED: "Confirmation",
  TRANSACTION_SUMMARY: "Transaction summary",
  TRY_AGAIN: "Try again",
  TX_BROADCASTING: "Transaction broadcasting...",
  USE_WALLET_TO_SIGN_FOR_CONFIRMATION: "Sign for confirmation",
  VALUE_STEPPER_IS: "Value stepper is 0.1.",
  VIEW_ON_ETHERSCAN: "View on Etherscan",
  VIEW_ON_EXPLORER: "View on explorer",
  VIEW_YOUR_VALIDATORS_ON_THE_DASHBOARD: "View your validators on your dashboard",
  DASHBOARD: "Dashboard",
  WE_ARE_WAITING_FOR_YOUR_TRANSACTION: "We are waiting for your transaction to be mined. This process might take few minutes or hours to be completed. You may check the progress in <url>your dashboard</url>.",
  WHILE_NOT_REQUIRED_IF_YOU_SHARE_EMAIL_ADDRESS: "If you share your email address with us we will contact you ONLY with updates regarding the status of your staked Ethereum and validators. We will not send any marketing or promotional emails and will not share your address with third parties. If you want to opt-out from emails just visit your dashboard.",
  WITHDRAWAL_WILL_NOT_BE_ENABLED: " ",
  YOUR_EMAIL: "Your email",
  REQUIRE_VALID_EMAIL: "Please enter valid email address.",
  TRANSACTION_LIST_TITLE: "Transactions",
  TRANSACTION_LIST_EMPTY: "Your transaction will appear here",
  WALLET_BALANCE_LABEL: "Balance",
  WALLET_BALANCE_VALUE: "{amount} ETH",
  DEPOSIT_TRANSACTION: "Deposit transaction",
  WITHDRAW_TRANSACTION: "Withdraw transaction",
  OTHER_TRANSACTION: "Other transaction",
  ERR_GENERAL: "Something went wrong", // TODO: replace this error message
  ERR_WALLET_SERVICE: "Sorry, we failed to connect wallet provider. Please try the sign button again, or contact <url>technical support</url>.",
  ERR_ETH_GENERAL: "Something went wrong", // TODO: replace this error message
  ERR_STORE_AGREEMENTS: "Sorry, we failed to process your singed agreements. Please try again later, or contact <url>technical support</url>.",
  ERR_MAINNET_REQUIREMENT: "Please connect to the Ethereum Mainnet network.",
  ERR_METAMASK_UNINSTALLED: "MetaMask is not installed. Please visit <url>the installation guide</url> for more information.",
  ERR_METAMASK_PROVIDER_FAILURE: "MetaMask initialization has failed. Please try connecting again, or contact <url>technical support</url>.",
  ERR_INSUFFICIENT_FUNDS: "Insufficient funds in connected wallet.",
  ERR_TIMEOUT_SING_MESSAGE: "Timed out on signing message. Please try signing again within displayed time frame.",
  ERR_BROADCAST_GENERAL_FAILURE: "Something's wrong during broadcast procedure. Please try the broadcast action again, or contact <url>technical support</url>.",
  ERR_EMAIL_SUBSCRIBE: "Something went wrong", // TODO: replace this error message
  ERR_EMAIL_UNSUBSCRIBE: "Something went wrong", // TODO: replace this error message
  ERR_ETH_PARSE: "Invalid JSON was received by the server. An error occurred on the server while parsing the JSON text.",
  ERR_ETH_INVALID_REQUEST: "The JSON sent is not a valid Request object.",
  ERR_ETH_METHOD_NOT_FOUND: "The method does not exist / is not available.",
  ERR_ETH_INVALID_PARAMS: "Invalid method parameter(s).",
  ERR_ETH_INTERNAL: "Internal JSON-RPC error.",
  ERR_ETH_INVALID_INPUT: "Invalid input.",
  ERR_ETH_RESOURCE_NOT_FOUND: "Resource not found.",
  ERR_ETH_RESOURCE_UNAVAILABLE: "Resource unavailable.",
  ERR_ETH_TRANSACTION_REJECTED: "Transaction rejected.",
  ERR_ETH_METHOD_NOT_SUPPORTED: "Method not supported.",
  ERR_ETH_LIMIT_EXCEEDED: "Request limit exceeded.",
  ERR_ETH_USER_REJECTED_REQUEST: "User rejected the request.",
  ERR_ETH_UNAUTHORIZED: "The requested account and/or method has not been authorized by the user.",
  ERR_ETH_UNSUPPORTED_METHOD: "The requested method is not supported by this Ethereum provider.",
  ERR_ETH_DISCONNECTED: "The provider is disconnected from all chains.",
  ERR_ETH_CHAIND_IS_CONNECTED: "The provider is disconnected from the specified chain.",
  FEEDBACK_TITLE: "Anything that can be improved?",
  FEEDBACK_TEXT: "We want to make this the best staking experience possible. Was there anything which seemed difficult, confusing, or could be improved? Please let us know!",
  FEEDBACK_BUTTON: "Leave feedback"
}
