import { useCallback, useEffect, useState } from "react"
import { ethers } from "ethers"
import { isNil } from "lodash"

import { SUPPORTED_WALLETS } from "../../consts/wallet"
import initializeWallet from "../../services/wallet"
import { SupportedWallet } from "../../types/wallet"
import { StorageKey } from "../../types/localStorage"
import { useStoredData } from "../useStoredData"
import { HandleAccountsChanged, FetchWallet } from "./types"

/**
 * Types
 */
interface Props {
  fetchWallet: FetchWallet
  connectWallet: (wallet: SupportedWallet, callback: () => Promise<void>) => Promise<void>
  handleChainChanged: () => void
  handleAccountsChanged: HandleAccountsChanged
  handleDisconnect: () => void
}

/**
 * Hooks
 */
export const useWalletConnect = ({
  connectWallet,
  fetchWallet,
  handleChainChanged,
  handleAccountsChanged,
  handleDisconnect
}: Props) => {
  /**
   * States
   */
  const { storageWalletConnected, resetLocalStorage } = useStoredData()
  const [connected, setConnected] = useState(false)

  const { WalletConnect } = SUPPORTED_WALLETS

  const hasConnectedWallet = storageWalletConnected !== undefined
  const isWalletConnectPreferred = storageWalletConnected === WalletConnect.name
  // QUICK FIX: WalletConnect gets disconnected since localstorage is rewritten into the state.
  // We need up-to-date version of data from Localstorage here.
  const hasWalletConnectObject = !isNil(window.localStorage.getItem(StorageKey.WALLET_CONNECT))

  const web3Modal = WalletConnect.connector()

  /**
   * Callbacks
   */
  const connectWalletCallback = async () => {
    await fetchWallet(fetchWalletCallback)
  }

  const fetchWalletCallback = async () => {
    try {
      const rawProvider = await web3Modal.connectTo("walletconnect")
      // DO NOT SYNC the rawProvider with connectedProvider - it will make API fail!
      rawProvider.pollingInterval = 23000

      initListeners(rawProvider)

      const connectedProvider = new ethers.providers.Web3Provider(rawProvider, "any")
      // DO NOT SYNC the connectedProvider with rawProvider - it will make API fail!
      connectedProvider.pollingInterval = 5000

      setConnected(true)

      const Wallet = initializeWallet(connectedProvider)

      const address = await Wallet.retrieveAddress()
      const wallet = await Wallet.retrieveWallet(address)

      return {
        connectedWallet: WalletConnect,
        web3Provider: connectedProvider,
        ...wallet
      }
    } catch (e) {
      await disconnect()
      resetLocalStorage(true)
      throw e
    }
  }

  /**
   * Helpers
   */
  const initListeners = useCallback((rawProvider: ethers.providers.JsonRpcProvider) => {
    if (!rawProvider.on) {
      return
    }

    rawProvider.on("accountsChanged", () => handleAccountsChanged)

    rawProvider.on("chainChanged", handleChainChanged)

    rawProvider.on("network", (_newNetwork, oldNetwork) => {
      if (!oldNetwork) return
      window.location.reload()
    })

    rawProvider.on("disconnect", () => {
      disconnect()
    })
  }, [])

  const disconnect = useCallback(async () => {
    web3Modal.clearCachedProvider()
    setConnected(false)

    handleDisconnect()
  }, [web3Modal, connected])

  /**
   * Lifecycle
   */

  /**
   * Disconnects WalletConnect if localstorage tampered
   */
  useEffect(() => {
    if (hasConnectedWallet && isWalletConnectPreferred && !hasWalletConnectObject) {
      disconnect()
    }
  }, [hasConnectedWallet, isWalletConnectPreferred, hasWalletConnectObject])

  /**
   * Connects WalletConnect only if it is a prefered wallet
   */
  useEffect(() => {
    if (!connected && isWalletConnectPreferred && hasWalletConnectObject) {
      connectWallet(WalletConnect, connectWalletCallback)
    }
  }, [connected, isWalletConnectPreferred, hasWalletConnectObject])

  /**
   * Return
   */
  return {
    connectWalletCallback,
    fetchWalletCallback
  }
}
