import React, { useEffect, useContext, useMemo, useRef } from "react"
import Skeleton from "react-loading-skeleton"
import { useBeforeunload } from "react-beforeunload"
import StepWizard from "./StepWizard"
import { useStoredData } from "../hooks/useStoredData"
import { INIT_STATES } from "../context/reducer"
import { AppContext, DefaultContext } from "../context"
import { StakingStep, BroadcastState } from "../types/staking"
import {
  RouteWrapperProps,
  handleOnLoadChangeStep,
  handleBrowserChangeStep,
  handleWalletChangeStep,
  WizardProps
} from "../utils/routerWizard"

/**
 * Components
 */
const Router: React.FC<RouteWrapperProps> = ({ stepWizardProps, localStorage, ...props }) => {
  // if (!STEP_PATHS.includes(props?.currentPath ?? "")) return <Redirect to={`../${STEP_PATHS[0]}`} noThrow />

  const isFirstLoad = useRef(true)
  const { state, dispatch } = useContext(AppContext as DefaultContext)
  const { setStep } = stepWizardProps
  const { setStorageStakeAmount } = useStoredData()

  const currentStepRef = useRef(state.staking.currentStep)
  currentStepRef.current = state.staking.currentStep
  const walletBalanceRef = useRef(state.wallet.balance)
  walletBalanceRef.current = state.wallet.balance

  const boundedHandleOnLoadChangeStep = useMemo(() => handleOnLoadChangeStep(dispatch), [dispatch])
  const boundedHandleBrowserChangeStep = useMemo(() => handleBrowserChangeStep(), [dispatch])
  const boundedHandleWalletChangeStep = useMemo(() => handleWalletChangeStep(dispatch), [dispatch])

  /**
   *
   * [On the first load]
   * Get validated target step with `currentPath`
   * to set current step to the target step,
   * and update store state if needed.
   *
   */
  useEffect(() => {
    boundedHandleOnLoadChangeStep({ setStep, currentPath: props?.currentPath, localStorage })
    isFirstLoad.current = false
  }, [])

  /**
   *
   * [On browser navigation]
   * Detect back event (←) triggered by browser,
   * and change step if needed.
   *
   * Note that on the first load the procedure should be ignore,
   * because step change should be handled by `handleOnLoadChangeStep`.
   *
   */
  useEffect(() => {
    if (isFirstLoad.current) return

    boundedHandleBrowserChangeStep({
      setStep,
      currentPath: props?.currentPath,
      localStorage,
      currentStep: currentStepRef.current
    })
  }, [props?.currentPath])

  /**
   *
   * [On wallet balance change]
   * Check if wallet has adequate balance at current step with stored `stakeAmount`.
   * If not, reset step and state.
   *
   */
  useEffect(() => {
    if (props.loading) return

    boundedHandleWalletChangeStep({
      walletBalance: walletBalanceRef.current,
      currentStep: currentStepRef.current,
      localStorage,
      setStorageStakeAmount,
      setStep
    })
  }, [state.wallet])

  return props.loading ? (
    <>
      <Skeleton height={400} />
      <Skeleton height={64} />
      <Skeleton height={48} />
    </>
  ) : (
    <StepWizard {...stepWizardProps} />
  )
}

/**
 * Main
 */
const Wizard: React.FC<WizardProps> = (props) => {
  const { state } = useContext(AppContext as DefaultContext)

  const {
    storageWalletConnected,
    storageStakeAmount,
    setStorageStakeAmount,
    storageExitDate,
    setStorageExitDate,
    storageAgreementsSignature,
    setStorageAgreementsSignature,
    setStorageTimeStamp
  } = useStoredData()

  /******************************************************/
  /* Updates                                            */
  /******************************************************/
  /**
   *
   * [On success broadcast result]
   * Reset local storage.
   *
   */
  useEffect(() => {
    if (state.staking.txBroadcastState === BroadcastState.Positive) {
      setStorageStakeAmount(INIT_STATES.staking.stakeAmount)
      setStorageExitDate(INIT_STATES.staking.exitDate)
      setStorageAgreementsSignature(undefined)
    }
  }, [state.staking.currentStep, state.staking.txBroadcastState])

  /**
   *
   * [On browser unload event]
   * Prompt users warning to leave site at necessary steps.
   *
   */
  useBeforeunload(() => {
    setStorageTimeStamp(new Date())

    if (
      (state.staking.currentStep === StakingStep.Review && state.global.modalOpen) ||
      (state.staking.currentStep === StakingStep.Sign && state.global.modalOpen) ||
      state.staking.currentStep === StakingStep.Broadcasting
    ) {
      // Most browsers display native confirmation message,
      // instead of this customized string value.
      return "Are you sure you want to leave? Information you’ve entered may not be saved."
    }
  })

  /******************************************************/
  /* Main                                               */
  /******************************************************/
  return (
    <Router
      localStorage={{
        walletConnected: storageWalletConnected,
        exitDate: storageExitDate,
        stakeAmount: storageStakeAmount,
        agreementsSignature: storageAgreementsSignature
      }}
      {...props}
    />
  )
}

export default Wizard
