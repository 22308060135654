import React, { ReactNode, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import MuiBox from "@mui/material/Box"
import Button, { ButtonProps } from "@stakefish/ui/components/Button"
import { MINIMUM_STAKING_VALUE } from "../consts/staking"
import { WalletState, WalletStates } from "../types/wallet"
import { StakingStep, StakingState, StakingStates, BroadcastState } from "../types/staking"

/**
 * Types
 */
type StepState =
  | "walletUnconnected"
  | "firstStep"
  | "reviewStep"
  | "signStep"
  | "txPendingStep"
  | "txPositiveStep"
  | "txNegativeStep"

type StepStateMap<T> = Record<StepState, T>

type GetStepState = (data: ActionButtonProps["data"]) => StepState
interface ActionButtonProps {
  data: {
    [WalletState.BALANCE]: WalletStates["balance"]
    [WalletState.CONNECTED_WALLET]: WalletStates["connectedWallet"]
    [StakingState.CURRENT_STEP]: StakingStates["currentStep"]
    [StakingState.TX_BROADCAST_STATE]: StakingStates["txBroadcastState"]
    disableNextButton?: boolean
    dashboardUrl?: string
  }
  /**
   * Callbacks in each step for major (next-step or call-to-actions) button.
   */
  majorButtonCallbacks: StepStateMap<() => void>
  /**
   * Callbacks in each step for minor (prev-step) button.
   */
  subButtonCallbacks: StepStateMap<() => void>
}
interface MajorButtonProps extends Omit<ActionButtonProps, "subButtonCallbacks"> {
  stepState: StepState
  fullWidth?: boolean
}

/**
 * Constants
 */
const majorButtonTextMap: StepStateMap<ReactNode> = {
  walletUnconnected: <FormattedMessage id="CONNECT_WALLET" />,
  firstStep: <FormattedMessage id="NEXT_STEP" />,
  reviewStep: <FormattedMessage id="USE_WALLET_TO_SIGN_FOR_CONFIRMATION" />,
  signStep: <FormattedMessage id="SIGN_AND_BROADCAST" />,
  txPendingStep: <FormattedMessage id="TX_BROADCASTING" />,
  txPositiveStep: <FormattedMessage id="VIEW_YOUR_VALIDATORS_ON_THE_DASHBOARD" />,
  txNegativeStep: <FormattedMessage id="BACK_TO_SIGN_AND_BROADCAST" />
}

/**
 * Helpers
 */
const getStepState: GetStepState = ({ connectedWallet, currentStep, txBroadcastState, dashboardUrl }) => {
  if (currentStep === StakingStep.StakeAmount && !connectedWallet) return "walletUnconnected"
  if (currentStep === StakingStep.StakeAmount) return "firstStep"
  if (currentStep === StakingStep.Review) return "reviewStep"
  if (currentStep === StakingStep.Sign) return "signStep"
  if (currentStep === StakingStep.Broadcasting && txBroadcastState === BroadcastState.Pending) return "txPendingStep"
  if (currentStep === StakingStep.Done && txBroadcastState === BroadcastState.Positive && dashboardUrl) {
    return "txPositiveStep"
  }
  if (currentStep === StakingStep.Done && txBroadcastState === BroadcastState.Negative) {
    return "txNegativeStep"
  }

  return "firstStep"
}

/**
 * Styles
 */
const ButtonGroup = styled(MuiBox)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  }
}))

/**
 * Components
 */
const MajorButton: React.FC<MajorButtonProps> = ({ data, majorButtonCallbacks, stepState, fullWidth }) => {
  if (stepState === "txPendingStep") return null

  const { balance = -1, disableNextButton, dashboardUrl } = data

  const hasLessThanMinimumBalance = balance <= MINIMUM_STAKING_VALUE
  const isFinalStep = stepState === "txPositiveStep"
  const shallDisable = isFinalStep ? false : hasLessThanMinimumBalance

  const commonProps: ButtonProps = {
    size: "md",
    disabled: disableNextButton ?? shallDisable,
    color: disableNextButton ? "disabled" : "primary",
    onClick: majorButtonCallbacks[stepState]
  }

  const majorButtonPropsMap: StepStateMap<ButtonProps> = {
    walletUnconnected: { ...commonProps, disabled: false, color: "primary" },
    firstStep: commonProps,
    reviewStep: commonProps,
    signStep: commonProps,
    txPendingStep: { ...commonProps, color: "disabled", disabled: true },
    txPositiveStep: {
      ...commonProps,
      variant: "outlined",
      href: dashboardUrl
    },
    txNegativeStep: { ...commonProps, variant: "outlined" }
  }

  return (
    <Button {...majorButtonPropsMap[stepState]} size="sm" fullWidth={fullWidth} data-test-id="majorCtaButton">
      {majorButtonTextMap[stepState]}
    </Button>
  )
}

/**
 * Main
 */
const ActionButtons: React.FC<ActionButtonProps> = ({ data, subButtonCallbacks, majorButtonCallbacks }) => {
  const intl = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const stepState = useMemo(() => getStepState(data), [data])
  const isPrevNeeded = useMemo(() => ["reviewStep", "signStep"].includes(stepState), [stepState])

  return (
    <MuiBox marginY={{ xs: 2, sm: 3, md: 7 }}>
      <ButtonGroup justifyContent={isPrevNeeded ? "space-between" : "flex-end"} gap={{ xs: 1, md: 0 }}>
        {isPrevNeeded && (
          <Button variant="outlined" size="sm" onClick={subButtonCallbacks[stepState]} fullWidth={isMobile}>
            {intl.formatMessage({ id: "PREV_STEP" })}
          </Button>
        )}

        <MajorButton
          data={data}
          majorButtonCallbacks={majorButtonCallbacks}
          stepState={stepState}
          fullWidth={isMobile}
        />
      </ButtonGroup>
    </MuiBox>
  )
}

export default ActionButtons
