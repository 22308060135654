import { useContext, useRef, useMemo } from "react"
import { isNil } from "lodash"
import { ethers } from "ethers"
import { AppContext, DefaultContext } from "../context"
import initializeWallet from "../services/wallet"

export const useWalletService = () => {
  const { provider } = useContext(AppContext as DefaultContext)
  const providerRef = useRef(provider)
  providerRef.current = provider

  const getWalletService = () => {
    if (isNil(providerRef.current)) {
      return null
    }

    return initializeWallet(providerRef.current as ethers.providers.Web3Provider)
  }

  const walletService = useMemo(getWalletService, [providerRef.current])

  return [walletService, getWalletService] as const
}
