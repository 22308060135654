import React from "react"
import { styled, useTheme } from "@mui/material/styles"
import MuiBox from "@mui/material/Box"
import useMediaQuery from "@mui/material/useMediaQuery"

import GridItem from "@stakefish/ui/components/GridItem"
import GridContainer from "@stakefish/ui/components/GridContainer"
import Modal, { ModalProps } from "@stakefish/ui/components/Modal"

import { SEO } from "../core/MetaTags"
import { ModalType, ModalsList } from "../types/modal"
import Footer from "./Footer"
import SnackbarPack from "./SnackbarPack"
import Header, { HeaderProps } from "./Header"
import Stepper, { StepperProps } from "./Stepper"
import Progress from "./Progress"

export interface LayoutProps {
  loading?: boolean
  stepperProps: StepperProps
  modal: {
    open: ModalProps["open"]
    activeId: ModalType
    list: ModalsList
  }
  headerProps: HeaderProps
}

const GridContainerBox = styled(GridContainer)({
  marginTop: 0
})

const MainBox = styled(MuiBox)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh"
})

// TODO: remove thsi styles after ui kit release with Container updates
const ContainerBox = styled(MuiBox)(({ theme }) => ({
  maxWidth: 1220,
  margin: "0 auto",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  boxSizing: "content-box",

  [theme.breakpoints.down("sm")]: {
    paddingLeft: "20px",
    paddingRight: "20px"
  }
}))

const Layout: React.FC<LayoutProps> = ({ loading, stepperProps, modal, headerProps, children }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <>
      <SEO />
      <MainBox>
        <MuiBox flexGrow={1}>
          <Header {...headerProps} loading={loading} />
          <main>
            <MuiBox>
              <ContainerBox>
                <GridContainerBox columnSpacing={{ xs: 0.65, sm: 3 }} rowSpacing={{ xs: 0.65, sm: 3, md: 4.5 }}>
                  <GridItem xs={12} md={4} style={{ width: "100%" }}>
                    {isMobile ? <Progress {...stepperProps} /> : <Stepper {...stepperProps} />}
                  </GridItem>
                  <GridItem xs={12} md={7} pt={2} style={{ width: "100%" }}>
                    <MuiBox pt={{ xs: 2.3, md: 1.5 }}>{children}</MuiBox>
                  </GridItem>
                </GridContainerBox>
              </ContainerBox>
            </MuiBox>
          </main>
        </MuiBox>
        <Footer />

        <SnackbarPack />
        <Modal size="sm" {...modal.list[modal.activeId]} open={modal.open} />
      </MainBox>
    </>
  )
}

export default Layout
