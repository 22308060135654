import { providers } from "ethers"
import Web3Modal from "web3modal"
import WalletConnectProvider from "@walletconnect/web3-provider"
import { SupportedWallets, SupportedNetwork, MetaMaskWallet, WalletConnectWallet } from "../types/wallet"
import { MetaMaskLogo } from "../assets/MetaMaskLogo"
import { WalletConnectLogo } from "../assets/WalletConnectLogo"

declare global {
  interface Window {
    ethereum: any
  }
}

/**
 * Consts
 */
export const ETH1_CHAIN_ID = 1
export const ETH1_RPC_URL = "https://geth.mainnet.ethereum.fish"
export const WALLETCONNECT_BRIDGE = "https://walletconnect.production.stakefish.link/"

export const USER_WALLET_ACTION_TIMEOUT = 60_000

export { abi as STAKEFISH_SERVICES_CONTRACT_FACTORY_ABI } from "../data/StakefishServicesContractFactory.json"

// WARNING! THE REAL FACTORY ADDRESS
export const STAKEFISH_SERVICES_CONTRACT_FACTORY_ADDRESS = "0x96935981Cc7BD24d75407C865d2Aa3fAbaf6D57D"

export const SUPPORTED_WALLETS: SupportedWallets = {
  MetaMask: {
    name: "MetaMask",
    logo: <MetaMaskLogo />,
    connector: async () => {
      if (!window.ethereum) return [null, "metaMaskUninstalled"]

      const provider = new providers.Web3Provider(window.ethereum, "any")
      return [provider, !provider ? "metaMaskProviderFailed" : null]
    },
    devices: "desktop"
  } as MetaMaskWallet,
  WalletConnect: {
    name: "WalletConnect",
    logo: <WalletConnectLogo />,
    connector: () => {
      const connector = new Web3Modal({
        cacheProvider: true,
        providerOptions: {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              rpc: { [ETH1_CHAIN_ID]: ETH1_RPC_URL },
              bridge: WALLETCONNECT_BRIDGE,
              chainId: ETH1_CHAIN_ID
            }
          }
        }
      })

      return connector
    },
    devices: "all"
  } as WalletConnectWallet
}

export const SUPPORTED_NETWORK_MAP: Record<SupportedNetwork, string> = {
  homestead: "Ethereum"
}
