import { useEffect, useContext } from "react"
import { AppContext, DefaultContext } from "../../context"
import { INIT_STATES } from "../../context/reducer"
import { StorageKey, StorageProps } from "../../types/localStorage"
import { StakingStep } from "../../types/staking"
import { isOverLocalStorageTimeLimit } from "../../utils/routerWizard"
import { useLocalStorage } from "./useLocalStorage"

export const useStoredData = () => {
  const { state } = useContext(AppContext as DefaultContext)

  /**
   * IMPORTANT! DO NOT TAMPER THIS VALUE!
   *
   * walletconnect - it comes from an exteral SDK. It should never be modified through this hook!
   */
  const [storageWalletConnect, , removeStorageWalletConnect] = useLocalStorage<StorageProps["walletconnect"]>(
    StorageKey.WALLET_CONNECT,
    undefined
  )

  /**
   * walletConnected
   */
  // prettier-ignore
  const [storageWalletConnected, setStorageWalletConnected, removeStorageWalletConnected] = useLocalStorage<StorageProps["walletConnected"]>(StorageKey.WALLET_CONNECTED, undefined)
  useEffect(() => {
    setStorageWalletConnected(state.wallet.connectedWallet?.name)
  }, [state.wallet.connectedWallet])

  /**
   * stakeAmount
   */
  // prettier-ignore
  const [storageStakeAmount, setStorageStakeAmount, removeStorageStakeAmount] = useLocalStorage<StorageProps["stakeAmount"]>(StorageKey.STAKE_AMOUNT, undefined)
  useEffect(() => {
    if (state.staking.currentStep > StakingStep.StakeAmount) setStorageStakeAmount(state.staking.stakeAmount)
  }, [state.staking.currentStep, state.staking.stakeAmount])

  /**
   * stakeAmount
   */
  const [storageExitDate, setStorageExitDate, removeStorageExitDate] = useLocalStorage<StorageProps["exitDate"]>(
    StorageKey.EXIT_DATE,
    INIT_STATES.staking.exitDate
  )
  useEffect(() => {
    if (state.staking.currentStep > StakingStep.StakeAmount) setStorageExitDate(state.staking.exitDate)
  }, [state.staking.currentStep])

  /**
   * agreementSigned
   */
  // prettier-ignore
  const [storageAgreementsSignature, setStorageAgreementsSignature, removeStorageAgreementsSignature] = useLocalStorage<StorageProps["agreementsSignature"]>(
    StorageKey.AGREEMENTS_SIGNATURE,
    undefined
  )
  useEffect(() => {
    setStorageAgreementsSignature(state.staking.agreementsSignature)
  }, [state.staking.currentStep, state.staking.agreementsSignature])

  /**
   * user email address
   */
  // prettier-ignore
  const [storageEmail, setStorageEmail, removeStorageEmail] = useLocalStorage<StorageProps["userEmail"]>(StorageKey.USER_EMAIL, undefined)

  /**
   * timeStamp
   */
  // prettier-ignore
  const [storageTimeStamp, setStorageTimeStamp, removeStorageTimeStamp] = useLocalStorage<StorageProps["lastEditTimeStamp"]>(StorageKey.LAST_EDIT_TIMESTAMP, undefined)
  useEffect(() => {
    if (isOverLocalStorageTimeLimit(new Date(), storageTimeStamp)) {
      setStorageStakeAmount(INIT_STATES.staking.stakeAmount)
      setStorageExitDate(INIT_STATES.staking.exitDate)
      setStorageAgreementsSignature(undefined)
    }
  }, [])

  const resetLocalStorage = (resetWallet: boolean = false) => {
    if (resetWallet) {
      removeStorageWalletConnected()
      removeStorageWalletConnect()
    }

    removeStorageStakeAmount()
    removeStorageExitDate()
    removeStorageAgreementsSignature()
    removeStorageTimeStamp()
    removeStorageEmail()
  }

  return {
    storageWalletConnect,
    storageWalletConnected,
    setStorageWalletConnected,
    storageStakeAmount,
    setStorageStakeAmount,
    storageExitDate,
    setStorageExitDate,
    storageAgreementsSignature,
    setStorageAgreementsSignature,
    storageEmail,
    setStorageEmail,
    removeStorageEmail,
    storageTimeStamp,
    setStorageTimeStamp,
    resetLocalStorage
  }
}
