import axios from "axios"
import { API_URL } from "../consts/api"

export interface AccountRequest {
  email: string
  address: string
  language?: string
}

export interface AccountDefaultResponse {
  status: "OK"
}

export interface AccountStatusResponse {
  email: string
  language: string
  confirmed: boolean
}

export const signUp = async (account: AccountRequest) => {
  const url = `${API_URL}/v1/user/sign-up`

  return await axios.post<AccountStatusResponse>(url, account).then(({ data }) => data)
}

export const checkStatus = async (account: AccountRequest) => {
  const url = `${API_URL}/v1/user/check-status`

  return await axios.post<AccountStatusResponse>(url, account).then(({ data }) => data)
}

export const unsubscribe = async (account: AccountRequest) => {
  const url = `${API_URL}/v1/user/unsubscribe`

  return await axios.post<AccountDefaultResponse>(url, account)
}

export const scheduleReminder = async (account: AccountRequest) => {
  const url = `${API_URL}/v1/user/schedule-reminder`

  return await axios.post<AccountDefaultResponse>(url, account)
}

export const cancelReminder = async (account: AccountRequest) => {
  const url = `${API_URL}/v1/user/cancel-reminder`

  return await axios.post<AccountDefaultResponse>(url, account)
}
