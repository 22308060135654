import React from "react"

export const WalletConnectLogo = () => {
  return (
    <svg viewBox="0 0 48 48" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0936 14.7361C30.2822 7.08798 17.6173 7.08798 9.80583 14.7361L8.77396 15.7463C8.38338 16.1287 8.38338 16.7487 8.77396 17.1311L11.9899 20.2798C12.1852 20.4711 12.5018 20.4711 12.6971 20.2798L14.0826 18.9234C19.5321 13.5879 28.3674 13.5879 33.8169 18.9234L35.1106 20.19C35.3059 20.3812 35.6225 20.3812 35.8178 20.19L39.0337 17.0413C39.4243 16.6589 39.4243 16.0389 39.0337 15.6565L38.0936 14.7361ZM47.6071 24.0499L44.7449 21.2475C44.3543 20.8651 43.721 20.8651 43.3305 21.2475L34.1706 30.2157C34.073 30.3113 33.9147 30.3113 33.817 30.2157L24.6571 21.2473C24.2665 20.8649 23.6332 20.8649 23.2427 21.2473L14.0831 30.2157C13.9855 30.3113 13.8272 30.3113 13.7295 30.2157L4.56954 21.2473C4.17897 20.8649 3.54573 20.8649 3.15515 21.2473L0.29293 24.0497C-0.0976432 24.4321 -0.0976432 25.0521 0.29293 25.4345L13.1992 38.0709C13.5898 38.4533 14.2231 38.4533 14.6136 38.0709L23.7733 29.1026C23.8709 29.007 24.0292 29.007 24.1269 29.1026L33.2867 38.0709C33.6773 38.4533 34.3106 38.4533 34.7011 38.0709L47.6071 25.4347C47.9976 25.0523 47.9976 24.4323 47.6071 24.0499Z"
        fill="#3B99FC"
      />
    </svg>
  )
}
