import { isNil } from "lodash"

import { StepWizardProps } from "../containers/StepWizard"
import { MINIMUM_STAKING_VALUE, PATH_STEP_MAP, STEP_PATH_MAP } from "../consts/staking"
import { Dispatch } from "../types/context"
import { StorageProps } from "../types/localStorage"
import { StakingState, StakingStep, StakingStates } from "../types/staking"
import { WalletStates } from "../types/wallet"
import { SetStep } from "../hooks/useStep"
import { INIT_STATES } from "../context/reducer"

/**
 * Types
 */
type LocalStorageStep = StakingStep.Review | StakingStep.Sign | undefined

interface GetOnLoadStepProps {
  currentPath: RouteWrapperProps["currentPath"]
  localStorage: StorageProps
}

export type GetOnLoadStep = (props: GetOnLoadStepProps) => LocalStorageStep

export interface HandleOnLoadStepChangeProps extends GetOnLoadStepProps {
  setStep: SetStep
}
export interface HandleBrowserChangeProps extends HandleOnLoadStepChangeProps {
  currentStep: StakingStates["currentStep"]
}

export interface RouteWrapperProps extends WizardProps {
  currentPath?: string
  localStorage: StorageProps
}

export interface WizardProps {
  stepWizardProps: StepWizardProps
  loading?: boolean
}

export interface HandleWalletChangeStepProps {
  walletBalance: WalletStates["balance"]
  currentStep: StakingStates["currentStep"]
  localStorage: StorageProps
  setStorageStakeAmount: (value: number | ((val: number | undefined) => number | undefined) | undefined) => void
  setStep: SetStep
}

/**
 * Helpers
 */
export const getOnLoadStep: GetOnLoadStep = ({ currentPath, localStorage }) => {
  const walletConnected = localStorage.walletConnected
  const stakeAmount = localStorage.stakeAmount && localStorage.stakeAmount >= MINIMUM_STAKING_VALUE
  const agreementSigned = localStorage.agreementsSignature

  if (currentPath === STEP_PATH_MAP[StakingStep.Review] && walletConnected && stakeAmount) {
    return StakingStep.Review
  }
  if (currentPath === STEP_PATH_MAP[StakingStep.Sign] && walletConnected && stakeAmount && agreementSigned) {
    return StakingStep.Sign
  }

  return undefined
}

export const isOverLocalStorageTimeLimit = (
  newDate: Exclude<StorageProps["lastEditTimeStamp"], undefined>,
  lastDate: StorageProps["lastEditTimeStamp"]
) => {
  if (!lastDate) return false

  const daysDiff = (newDate.getTime() - new Date(lastDate).getTime()) / (1000 * 3600 * 24)
  return daysDiff > 3 // TODO: confirm max days
}

export const handleOnLoadChangeStep = (dispatch: Dispatch) => {
  return ({ setStep, ...props }: HandleOnLoadStepChangeProps) => {
    const localStorageStep = getOnLoadStep(props)

    if (localStorageStep) {
      dispatch({
        type: "SET_STAKE_AMOUNT",
        payload: {
          field: StakingState.STAKE_AMOUNT,
          value: props.localStorage.stakeAmount ?? INIT_STATES.staking.stakeAmount
        }
      })
      dispatch({
        type: "SET_EXIT_DATE",
        payload: {
          field: StakingState.EXIT_DATE,
          value: !isNil(props.localStorage.exitDate) ? props.localStorage.exitDate : INIT_STATES.staking.exitDate
        }
      })
    }

    if (localStorageStep === StakingStep.Review) {
      setStep(StakingStep.StakeAmount, "next", {
        targetNextStep: StakingStep.Review
      })

      return
    }
    if (localStorageStep === StakingStep.Sign) {
      setStep(StakingStep.Review, "next", {
        targetNextStep: StakingStep.Sign
      })
      dispatch({
        type: "SET_AGREEMENTS_SIGNATURE",
        payload: {
          field: StakingState.AGREEMENTS_SIGNATURE,
          value: localStorage.agreementsSignature
        }
      })
    }
  }
}

export const handleBrowserChangeStep = () => {
  return ({ setStep, currentStep, currentPath }: HandleBrowserChangeProps) => {
    if (!currentPath || !PATH_STEP_MAP[currentPath]) {
      return
    }

    const targetStep = PATH_STEP_MAP[currentPath]
    if (targetStep < currentStep) {
      setStep(currentStep, targetStep > currentStep ? "next" : "prev")
    }
  }
}

export const handleWalletChangeStep = (dispatch: Dispatch) => {
  return (props: HandleWalletChangeStepProps) => {
    const { walletBalance = -1, currentStep, localStorage, setStorageStakeAmount, setStep } = props
    const walletHasEnoughBalance =
      walletBalance > 0 && localStorage?.stakeAmount && walletBalance > localStorage.stakeAmount

    const isFinalStep = StakingStep.Done === currentStep

    /**
     * User finished staking or wallet has enough balance: do nothing
     *
     * IMPORTANT: When user stakes all ETH they have,
     * we should keep them on the last step (DONE).
     */
    if (isFinalStep || (!isNil(walletBalance) && walletHasEnoughBalance)) {
      return
    }

    /**
     *
     * Wallet has inadequate balance: reset state and step
     *
     */
    setStorageStakeAmount(INIT_STATES.staking.stakeAmount)

    dispatch({
      type: "SET_STAKE_AMOUNT",
      payload: {
        field: StakingState.STAKE_AMOUNT,
        value: INIT_STATES.staking.stakeAmount
      }
    })

    if (currentStep > StakingStep.StakeAmount) {
      setStep(currentStep, "prev", {
        targetPrevStep: StakingStep.StakeAmount
      })
    }
  }
}
