import React, { ReactNode, memo } from "react"
import { useIntl } from "react-intl"

import AmountForm from "../components/AmountForm"
import BlockLayout from "../components/BlockLayout"
import { WalletStates, WalletState } from "../types/wallet"
import { StakingStates, StakingState } from "../types/staking"

export interface StakeAmountProps {
  data: {
    [WalletState.BALANCE]: WalletStates["balance"]
    [StakingState.STAKE_AMOUNT]: StakingStates["stakeAmount"]
    [StakingState.EXIT_DATE]: StakingStates["exitDate"]
  }
  onAmountChange: (amount: StakingStates["stakeAmount"], errorMsg: ReactNode | null) => void
  onExitDateChange: (date: StakingStates["exitDate"]) => void
}

/**
 * Main
 */
const StakeAmount: React.FC<StakeAmountProps> = (props: StakeAmountProps) => {
  const intl = useIntl()

  const {
    data: { stakeAmount, balance },
    onAmountChange
  } = props

  const [stakeAmountOfEth] = [intl.formatMessage({ id: "STAKE_AMOUNT_OF_ETH" })]

  return (
    <div>
      <BlockLayout title={stakeAmountOfEth}>
        <AmountForm initialValue={stakeAmount} walletBalance={balance} onAmountChange={onAmountChange} />
      </BlockLayout>
    </div>
  )
}

export default memo(StakeAmount)
