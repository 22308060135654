import React, { createContext, memo, useMemo, useReducer, ReactNode, useState } from "react"
import { ethers } from "ethers"

import { AppStates, AppActionsUnion, AppReducer } from "../types/context"
import { appCombinedReducer } from "./reducer"

interface Context {
  state: AppStates
  dispatch: React.Dispatch<AppActionsUnion>
  provider: ethers.providers.JsonRpcProvider | null
  setProvider: React.Dispatch<React.SetStateAction<ethers.providers.JsonRpcProvider | null>>
}

export interface DefaultContext extends React.Context<Context> {}

export const AppContext = createContext<Context | null>(null)

const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer<AppReducer>(...appCombinedReducer)
  const [provider, setProvider] = useState<ethers.providers.JsonRpcProvider | null>(null)

  const memoizedValue = useMemo(
    () => ({
      state,
      dispatch,
      provider,
      setProvider
    }),
    [state, provider]
  )

  /**
   * Provider
   */
  return <AppContext.Provider value={memoizedValue}>{children}</AppContext.Provider>
}

export default memo(AppContextProvider)
