import { useCallback, useContext } from "react"
import { AppContext, DefaultContext } from "../context"
import { GlobalState, GlobalStates } from "../types/global"
import { SnackbarType } from "../types/snackbar"

export const useSnackPack = () => {
  const { state, dispatch } = useContext(AppContext as DefaultContext)

  const openSnackPack = useCallback(
    (value: GlobalStates[GlobalState.SNACKBAR_PACK]) => {
      dispatch({
        type: "SET_SNACKBAR_PACK",
        payload: {
          field: GlobalState.SNACKBAR_PACK,
          value
        }
      })
    },
    [dispatch]
  )

  const hideSnackPack = useCallback(() => {
    dispatch({
      type: "SET_SNACKBAR_PACK",
      payload: {
        field: GlobalState.SNACKBAR_PACK,
        value: undefined
      }
    })
  }, [])

  const isOpenSnackPack = useCallback(
    (type: SnackbarType) => {
      return state.global.snackbarPack?.type === type
    },
    [state.global.snackbarPack?.type]
  )

  return {
    isOpenSnackPack,
    openSnackPack,
    hideSnackPack
  }
}
