import React from "react"
import { WalletStates } from "../../types/wallet"
import { ConnectingLayout } from "./private"
import { WalletButtonClickHandler } from "./types"

/**
 * Types
 */
export interface DisconnectedViewProps {
  walletStates: WalletStates
  walletList: React.ReactNode
  walletButtonClickHandler: WalletButtonClickHandler
}

/**
 * Components
 */
export const DisconnectedView = ({ walletList, ...layoutProps }: DisconnectedViewProps) => {
  return <ConnectingLayout {...layoutProps}>{walletList}</ConnectingLayout>
}
