import { memo } from "react"
import { useIntl } from "react-intl"
import MuiBox from "@mui/material/Box"
import Button from "@stakefish/ui/components/Button"
import { LINK_PRIVACY_POLICY, LINK_TERMS_OF_SERVICE } from "../consts/stakefish"

const AgreementsLinks = () => {
  const intl = useIntl()
  const [privacyPolicy, termsOfService] = [
    intl.formatMessage({ id: "PRIVACY_POLICY" }),
    intl.formatMessage({ id: "TERMS_OF_SERVICE" })
  ]

  return (
    <MuiBox display="flex" gap={{ xs: 1, sm: 3 }} mt={1} flexWrap="wrap">
      <Button
        variant="underline"
        href={LINK_PRIVACY_POLICY}
        sx={{ whiteSpace: "nowrap" }}
        size="xs"
        linkProps={{
          target: "_blank",
          rel: "noopener noreferrer"
        }}
      >
        {privacyPolicy}
      </Button>
      <Button
        variant="underline"
        href={LINK_TERMS_OF_SERVICE}
        sx={{ whiteSpace: "nowrap" }}
        size="xs"
        linkProps={{
          target: "_blank",
          rel: "noopener noreferrer"
        }}
      >
        {termsOfService}
      </Button>
    </MuiBox>
  )
}

export default memo(AgreementsLinks)
