import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { AppContext, DefaultContext } from "../context"
import {
  AccountRequest,
  AccountStatusResponse,
  signUp,
  checkStatus,
  unsubscribe,
  scheduleReminder,
  cancelReminder
} from "../data/Account"
import { useStoredData } from "./useStoredData"

type AccountStatus = "confirmed" | "unconfirmed" | "no_account"

export const useEmailAccount = () => {
  const {
    state: {
      wallet: { address }
    }
  } = useContext(AppContext as DefaultContext)
  const { storageEmail, setStorageEmail, removeStorageEmail } = useStoredData()
  const [account, setAccount] = useState<AccountStatusResponse>()
  const [reminder, setReminder] = useState(false)

  const status: AccountStatus = useMemo(() => {
    if (account === undefined) {
      return "no_account"
    }

    if (account.confirmed) {
      return "confirmed"
    }

    return "unconfirmed"
  }, [account])

  const createAccountRequest = (email: string): AccountRequest => {
    return {
      email,
      address: address as string,
      // TODO hook language [mateusz]
      language: "en"
    }
  }

  const verifyAccount = async (email: string) => {
    try {
      const account = await checkStatus({ address: address as string, email })
      setAccount(account)

      return account
    } catch (err) {
      console.error(err)
    }

    return undefined
  }

  const createAccount = useCallback(
    async (email: string) => {
      const account = await verifyAccount(email)
      if (account !== undefined) {
        setAccount(account)
        setStorageEmail(email)
        return
      }
      const newAccount = await signUp(createAccountRequest(email))
      setAccount(newAccount)
      setStorageEmail(email)
    },
    [address]
  )

  const removeAccount = useCallback(
    async (email: string) => {
      await unsubscribe(createAccountRequest(email))
      removeStorageEmail()
      setAccount(undefined)
    },
    [address]
  )

  const turnReminderOn = useCallback(async () => {
    if (![storageEmail, address].includes(undefined) && !reminder) {
      await scheduleReminder(createAccountRequest(storageEmail as string))
      setReminder(true)
    }
  }, [storageEmail, address, account, reminder])

  const turnReminderOff = useCallback(async () => {
    if (![storageEmail, address].includes(undefined)) {
      await cancelReminder(createAccountRequest(storageEmail as string))
      setReminder(false)
    }
  }, [storageEmail, address, account, reminder])

  useEffect(() => {
    if (![storageEmail, address].includes(undefined)) {
      verifyAccount(storageEmail as string)
    }
  }, [storageEmail, address])

  return {
    status,
    account,
    createAccount,
    removeAccount,
    scheduleReminder: turnReminderOn,
    cancelReminder: turnReminderOff
  }
}
