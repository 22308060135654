import React from "react"
import MuiBox from "@mui/material/Box"
import { styled } from "@mui/material/styles"
import { useIntl } from "react-intl"
import Typography from "@stakefish/ui/components/Typography"
import SocialIcon from "@stakefish/ui/components/SocialIcon"
import {
  SOCIAL_LINKS,
  LINK_PRIVACY_POLICY,
  LINK_TERMS_OF_SERVICE,
  LINK_CONTACT,
  LINK_DASHBOARD
} from "../consts/stakefish"

const SocialIconsBox = styled("ul")(({ theme }) => ({
  listStyle: "none",
  margin: theme.spacing(0, 0, 2.5),
  padding: 0,
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: theme.spacing(1.5),
  direction: "ltr",

  "& li": {
    textAlign: "center",
    margin: theme.spacing(0.5, 0)
  },
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1)
  }
}))

const LegalBox = styled(MuiBox)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  "& a, & *": {
    color: theme.palette.gray[500]
  },
  "& a": {
    background: "none",
    fontWeight: "inherit"
  },
  "& span": {
    padding: theme.spacing(0, 1)
  }
}))

const Copyright = styled(MuiBox)(({ theme }) => ({
  color: theme.palette.gray[500]
}))

const FooterBox = styled("footer")(({ theme }) => ({
  margin: "0 auto",
  textAlign: "center",
  padding: theme.spacing(11.3, 3),
  letterSpacing: "normal",

  [theme.breakpoints.down("sm")]: {
    margin: "0 auto",
    padding: theme.spacing(7, 3),

    ".MuiTypography-root": {
      fontSize: "12px !important",
      lineHeight: "normal !important"
    }
  }
}))

const Footer = () => {
  const intl = useIntl()

  const renderers = {
    url: (...chunks: string[]) => (
      <a href={LINK_CONTACT} target="_blank" rel="noopener noreferrer">
        {chunks}
      </a>
    )
  }

  const [copyright, privacyPolicy, termsOfService, ifYouHaveAnyQuestions, dashboard] = [
    intl.formatMessage({ id: "COPYRIGHT_STAKE_FISH" }),
    intl.formatMessage({ id: "PRIVACY_POLICY" }),
    intl.formatMessage({ id: "TERMS_OF_SERVICE" }),
    intl.formatMessage({ id: "IF_YOU_HAVE_ANY_QUESTIONS_CONTACT_US" }, { url: renderers.url }),
    intl.formatMessage({ id: "DASHBOARD" })
  ]

  return (
    <FooterBox>
      <SocialIconsBox>
        {SOCIAL_LINKS.map((item, index) => (
          <li key={index > 2 ? index + 2 : index}>
            <SocialIcon variant={item.icon} url={item.url} />
          </li>
        ))}
      </SocialIconsBox>
      <MuiBox mt={1} mb={2}>
        <Typography variant="callout">{ifYouHaveAnyQuestions}</Typography>
      </MuiBox>
      <LegalBox>
        <Typography variant="caption" color="secondary" component="div">
          <a href={LINK_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
            {privacyPolicy}
          </a>
          <span>&middot;</span>
          <a href={LINK_TERMS_OF_SERVICE} target="_blank" rel="noopener noreferrer">
            {termsOfService}
          </a>
          <span>&middot;</span>
          <a href={LINK_DASHBOARD} target="_blank" rel="noopener noreferrer">
            {dashboard}
          </a>
        </Typography>
      </LegalBox>
      <Copyright>
        <Typography variant="caption" color="secondary">
          {copyright}
        </Typography>
      </Copyright>
    </FooterBox>
  )
}

export default Footer
