import React from "react"
import { WalletStates } from "../../types/wallet"
import { ConnectingLayout } from "./private"
import { WalletButtonClickHandler } from "./types"

/**
 * Types
 */
export interface WalletChangingViewProps {
  walletStates: WalletStates
  walletList: React.ReactNode
  walletButtonClickHandler: WalletButtonClickHandler
  prev: boolean
  onPrevClick: React.MouseEventHandler<HTMLButtonElement>
}

/**
 * Components
 */
export const WalletChangingView: React.FC<WalletChangingViewProps> = ({ walletList, ...layoutProps }) => {
  return <ConnectingLayout {...layoutProps}>{walletList}</ConnectingLayout>
}
