import React, { memo } from "react"
import { useIntl } from "react-intl"
import MuiBox from "@mui/material/Box"
import GridItem from "@stakefish/ui/components/GridItem"
import Typography from "@stakefish/ui/components/Typography"
import GridContainer from "@stakefish/ui/components/GridContainer"
import BlockLayout from "../components/BlockLayout"
import SubscribeForm from "../components/SubscribeForm"
import Divider from "../components/Divider"
import AgreementsLinks from "../components/AgreementsLinks"
import { StakingStates, StakingState } from "../types/staking"
import { MINIMUM_STAKING_VALUE } from "../consts/staking"

interface SignProps {
  data: {
    [StakingState.STAKE_AMOUNT]: StakingStates["stakeAmount"]
  }
}

const Sign: React.FC<SignProps> = ({ data }: SignProps) => {
  const intl = useIntl()

  const [stakingSummary, depositAmount, enterYourEmailAddress, whileNotRequiredIfYouShareEmailAddress] = [
    intl.formatMessage({ id: "STACKING_SUMMARY" }),
    intl.formatMessage({ id: "DEPOSIT_AMOUNT" }),
    intl.formatMessage({ id: "ENTER_YOUR_EMAIL_ADDRESS_OPTIONAL" }),
    intl.formatMessage({ id: "WHILE_NOT_REQUIRED_IF_YOU_SHARE_EMAIL_ADDRESS" })
  ]

  return (
    <MuiBox>
      <BlockLayout title={stakingSummary}>
        <MuiBox>
          <GridContainer spacing={{ xs: 1, sm: 2 }} direction={{ xs: "row" }}>
            <GridItem xs={6} lg={3}>
              <Typography variant="caption" color="secondary" component="div">
                {depositAmount}
              </Typography>
            </GridItem>
            <GridItem xs={6} lg={9}>
              <Typography variant="caption" component="div">
                {data?.stakeAmount ?? MINIMUM_STAKING_VALUE} ETH
              </Typography>
            </GridItem>
          </GridContainer>
        </MuiBox>
      </BlockLayout>

      <Divider />

      <BlockLayout title={enterYourEmailAddress} tooltip={whileNotRequiredIfYouShareEmailAddress} titleSize="sm">
        <MuiBox mb={2}>
          <SubscribeForm />
        </MuiBox>
        <MuiBox mb={2.5} display="flex" justifyContent={{ xs: "center", sm: "flex-start" }}>
          <AgreementsLinks />
        </MuiBox>
      </BlockLayout>
    </MuiBox>
  )
}

export default memo(Sign)
