import { WalletError } from "../types/web3"
import { Dispatch } from "../types/context"
import { GlobalState } from "../types/global"
import { StakingState } from "../types/staking"
import { snackPackProps } from "../types/snackbar"
import { WalletState, WalletStates } from "../types/wallet"
import { getErrorMessage } from "../consts/errors"
import { INIT_STATES } from "./reducer"

export const dispatchWalletInfo = (dispatch: Dispatch) => (wallet?: WalletStates) => {
  if (wallet === undefined) {
    dispatch({
      type: "SET_WALLET_CONNECTED_INFO",
      payload: {
        field: null,
        value: null
      },
      payloads: INIT_STATES.wallet
    } as any)
    return
  }

  // TODO: remove the `any` once SetWalletConnectedInfo interface is refactored [mateusz]
  dispatch({
    type: "SET_WALLET_CONNECTED_INFO",
    payload: {
      field: null,
      value: null
    },
    payloads: wallet
  } as any)
}

export const dispatchWalletConnectionError = (dispatch: Dispatch) => (e?: WalletError) => {
  const errorMessage = e ? getErrorMessage(e) : undefined
  dispatch({
    type: "SET_ERROR_ON_WALLET_CONNECTION",
    payload: {
      field: WalletState.ERROR_ON_WALLET_CONNECTION,
      value: errorMessage
    }
  })
  dispatch({
    type: "SET_SNACKBAR_PACK",
    payload: {
      field: GlobalState.SNACKBAR_PACK,
      value: errorMessage
        ? {
            ...snackPackProps.ethError,
            children: errorMessage
          }
        : undefined
    }
  })
}

export const dispatchStakingStatesReset = (dispatch: Dispatch) => () => {
  dispatch({
    type: "SET_STAKE_AMOUNT",
    payload: {
      field: StakingState.STAKE_AMOUNT,
      value: INIT_STATES.staking.stakeAmount
    }
  })
  dispatch({
    type: "SET_EXIT_DATE",
    payload: {
      field: StakingState.EXIT_DATE,
      value: INIT_STATES.staking.exitDate
    }
  })
  dispatch({
    type: "SET_AGREEMENTS_SIGNATURE",
    payload: {
      field: StakingState.AGREEMENTS_SIGNATURE,
      value: INIT_STATES.staking.agreementsSignature
    }
  })
  dispatch({
    type: "SET_GAS_COST",
    payload: {
      field: StakingState.GAS_COST,
      value: INIT_STATES.staking.gasCost
    }
  })
  dispatch({
    type: "SET_VALIDATORS_AMOUNT",
    payload: {
      field: StakingState.VALIDATORS_AMOUNT,
      value: INIT_STATES.staking.validatorsAmount
    }
  })
}
