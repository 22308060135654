import { useIntl } from "react-intl"
import MuiBox from "@mui/material/Box"
import Typography from "@stakefish/ui/components/Typography"
import Button from "@stakefish/ui/components/Button"
import { styled } from "@mui/material/styles"
import Divider from "./Divider"

export interface FormProps {
  mailto: string
}

const Rectangle = styled(MuiBox)(({ theme }) => ({
  position: "absolute",
  inset: "0",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "24px",
  zIndex: "-1",
  transform: "perspective(800px) rotateY(16deg) rotateX(-8deg) translateZ(100px)",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90%"
  }
}))

const Card = styled(MuiBox)(({ theme }) => ({
  position: "relative",
  maxWidth: "580px",
  margin: "0 auto",
  padding: "50px 110px 20px 50px",

  [theme.breakpoints.down("sm")]: {
    padding: "50px 40px 20px 60px"
  }
}))

const FeedbackForm: React.FC<FormProps> = (props) => {
  const intl = useIntl()

  const [title, text, button] = [
    intl.formatMessage({ id: "FEEDBACK_TITLE" }),
    intl.formatMessage({ id: "FEEDBACK_TEXT" }),
    intl.formatMessage({ id: "FEEDBACK_BUTTON" })
  ]

  return (
    <MuiBox my={5} mx={{ xs: 0, sm: 3 }}>
      <Divider />
      <Card>
        <Rectangle />
        <MuiBox position="relative" zIndex="10" sx={{ transform: "translateZ(1000px)" }}>
          <Typography variant="title2" mb={1}>
            {title}
          </Typography>
          <Typography variant="caption" mb={3} component="p">
            {text}
          </Typography>
          <Button variant="contained" color="inverse" size="xs" href={`mailto:${props.mailto}`}>
            {button}
          </Button>
        </MuiBox>
      </Card>
    </MuiBox>
  )
}

export default FeedbackForm
