export const getDecimalLength = (val: number) => {
  if (Math.floor(val.valueOf()) === val.valueOf()) return 0

  const str = val.toString()
  if (str.includes(".") && str.includes("-")) {
    return str.split("-")[1] || 0
  } else if (str.includes(".")) {
    return str.split(".")[1].length || 0
  }

  return str.split("-")[1] || 0
}
