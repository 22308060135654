import { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { LINK_TERMS_OF_SERVICE, LINK_PRIVACY_POLICY } from "./stakefish"
import { ExitDate, StakingStep, StakingSteps, AmountInputErrorState, StepPaths } from "../types/staking"

export const STAKING_STEPS: StakingSteps = [
  <FormattedMessage id="STAKE_AMOUNT" />,
  <FormattedMessage id="REVIEW_AGREEMENTS" />,
  <FormattedMessage id="SIGN_AND_BROADCAST" />,
  <FormattedMessage id="TRANSACTION_BROADCASTED" />
]

export const STEP_PATH_MAP: StepPaths = {
  [StakingStep.StakeAmount]: "stake-amount",
  [StakingStep.Review]: "review-agreements",
  [StakingStep.Sign]: "sign-and-broadcast",
  [StakingStep.Broadcasting]: "sign-and-broadcast",
  [StakingStep.Done]: "broadcast-result"
}

export const PATH_STEP_MAP = {
  [STEP_PATH_MAP[1]]: StakingStep.StakeAmount,
  [STEP_PATH_MAP[2]]: StakingStep.Review,
  [STEP_PATH_MAP[3]]: StakingStep.Sign,
  [STEP_PATH_MAP[4]]: StakingStep.Sign,
  [STEP_PATH_MAP[5]]: StakingStep.Done
}

export const STEP_PATHS = Object.values(STEP_PATH_MAP)

export const MINIMUM_STAKING_VALUE = 0.1

export const DASHBOARD_URL = "https://stake.fish/ethereum-shared/dashboard"

export const DEFAULT_EXIT_DATE = ExitDate.Fastest

export const AGREEMENTS = [
  { id: 0, text: <FormattedMessage id="STAKEFISH_DOES_NOT_HAVE_CUSTODY_OF_MY_DEPOSIT" /> },
  { id: 1, text: <FormattedMessage id="I_WONT_BE_ABLE_TO_WITHDRAW" /> },
  { id: 2, text: <FormattedMessage id="I_UNDERSTAND_THE_SLASHING_RISK_ASSOCIATED_WITH_STAKING" /> },
  {
    id: 3,
    text: (
      <FormattedMessage
        id="I_HAVE_READ_AND_ACCEPTED_TOS"
        values={{
          url1: (...chunks: string[]) => (
            <a href={LINK_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
              {chunks}
            </a>
          ),
          url2: (...chunks: string[]) => (
            <a href={LINK_TERMS_OF_SERVICE} target="_blank" rel="noopener noreferrer">
              {chunks}
            </a>
          )
        }}
      />
    )
  }
]

export const EN_AGREEMENTS = [
  "I understand that stakefish does not have custody of my staking deposit. My withdrawal private keys, which I have stored securely and which only I know about, control my funds. Protecting my withdrawal private keys is solely my responsibility, and stakefish has no way of retrieving my staking deposit.",
  "This means a few features, such as the ability to withdraw staked ETH, will have to wait a little longer after the Ethereum merge is complete. I understand that this could take one year or longer, and that it may never happen at all. I understand that there are no refunds once my staking deposit has been completed.",
  "I understand the slashing risk associated with staking and validating in a Proof of Stake network like Ethereum. I understand that in the rare event that validators are slashed, stakefish is not liable for any slashing penalties of my staking deposit.",
  "I have read and accepted the Privacy Policy and Terms of Service."
]

export const STAKE_INPUT_ERROR_MESSAGES: Record<AmountInputErrorState, ReactNode> = {
  overWalletBalance: <FormattedMessage id="INSUFFICIENT_FUNDS_IN_CONNECTED_WALLET" />,
  belowMinStakeValue: <FormattedMessage id="MINIMUM_STAKING_VALUE_IS" />,
  overflowStepDigit: <FormattedMessage id="VALUE_STEPPER_IS" />
}
