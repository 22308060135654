import { useEffect, useState } from "react"
import { ethers } from "ethers"
import { SUPPORTED_WALLETS } from "../../consts/wallet"
import initializeWallet from "../../services/wallet"
import { SupportedWallet } from "../../types/wallet"
import { useStoredData } from "../useStoredData"
import { HandleAccountsChanged, FetchWallet } from "./types"

/**
 * Types
 */
interface Props {
  fetchWallet: FetchWallet
  connectWallet: (wallet: SupportedWallet, callback: () => Promise<void>) => Promise<void>
  handleChainChanged: () => void
  handleAccountsChanged: HandleAccountsChanged
  handleDisconnect: () => void
}

export const useMetaMask = ({
  fetchWallet,
  handleChainChanged,
  handleAccountsChanged: accountsChangedHandler
}: Props) => {
  /**
   * States
   */
  const { storageWalletConnected } = useStoredData()
  const [connected, setConnected] = useState(false)

  const extensionInstalled = window.ethereum !== undefined

  const { MetaMask } = SUPPORTED_WALLETS
  const inLocalStorage = storageWalletConnected === MetaMask.name

  /**
   * Callbacks
   */
  const connectWalletCallback = async () => {
    const [web3Provider, error] = await MetaMask.connector()
    if (error) throw error

    await (web3Provider as ethers.providers.Web3Provider).send("eth_requestAccounts", [])

    await web3Provider.send("wallet_switchEthereumChain", [
      {
        chainId: "0x1" // Mainnet network
      }
    ])

    await fetchWallet(fetchWalletCallback)
  }

  const fetchWalletCallback = async () => {
    const [web3Provider, error] = await MetaMask.connector()
    if (error) throw error

    const Wallet = initializeWallet(web3Provider)

    const address = await Wallet.retrieveAddress()
    const wallet = await Wallet.retrieveWallet(address)

    setConnected(true)

    return {
      connectedWallet: MetaMask,
      web3Provider,
      ...wallet
    }
  }

  const handleAccountsChanged = async (accounts: string[]) => {
    await accountsChangedHandler(accounts, fetchWalletCallback, MetaMask.name)
  }

  /**
   * Main
   */
  useEffect(() => {
    if (extensionInstalled) {
      window.ethereum.on("chainChanged", handleChainChanged)
      window.ethereum.on("accountsChanged", handleAccountsChanged)
    }

    return () => {
      if (extensionInstalled) {
        window.ethereum.removeListener("accountsChanged", handleChainChanged)
        window.ethereum.removeListener("chainChanged", handleAccountsChanged)
      }
    }
  }, [])

  /**
   * Unfortunately, The MetaMask extenstion doesn't support disconnection.
   * I investigated how 1inch does it, and they simply clear state, and localstorage.
   * MetaMask remains connected but 1inch app doesn't initialize it on start.
   * I reflected their implementation here.
   */
  useEffect(() => {
    if (!connected && inLocalStorage) {
      fetchWallet(fetchWalletCallback)
    }
  }, [connected, inLocalStorage])

  /**
   * Return
   */
  return {
    connectWalletCallback,
    fetchWalletCallback
  }
}
