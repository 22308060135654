import React, { memo, useEffect } from "react"
import { useIntl } from "react-intl"
import { isDesktop, isMobile } from "react-device-detect"
import { styled } from "@mui/material/styles"
import MuiBox from "@mui/material/Box"
import Icon from "@stakefish/ui/components/Icon"
import Typography from "@stakefish/ui/components/Typography"
import WalletButton, { SupportedWalletShape } from "@stakefish/ui/components/WalletButton"

import {
  DisconnectedView,
  ConnectedView,
  ConnectingView,
  ConnectErrorView,
  WalletChangingView,
  ConnectedViewProps,
  DisconnectedViewProps,
  ConnectingViewProps,
  ConnectErrorViewProps,
  WalletChangingViewProps,
  WalletWidgetProps,
  WalletButtonClickHandler
} from "../components/WalletWidgetViews"
import { WalletStates, SupportedWallet, WalletWidgetState } from "../types/wallet"
import { SUPPORTED_WALLETS } from "../consts/wallet"

/**
 * Types
 */
export type StateChangeType = "toConnecting" | "toConnectError" | "toConnected" | "toWalletChanging" | "toDisconnected"

type StateChangeTypeMap = {
  [key in StateChangeType]: {
    from: WalletWidgetState[]
    to: WalletWidgetState
  }
}

interface WalletButtonsProps {
  onWalletButtonClick: WalletButtonClickHandler
  connectedWallet?: WalletStates["connectedWallet"]
}

export interface WalletWidgetWizardProps {
  walletStates: WalletStates
  currentState: WalletWidgetState
  stateChangeType: StateChangeType | null
  connectingWallet: SupportedWallet | null
  onWalletButtonClick: WalletButtonClickHandler
  setState: (currentState: WalletWidgetState, toState: WalletWidgetState) => void
}

/**
 * Constants
 */
const validSupportedWallets = Object.entries(SUPPORTED_WALLETS).filter(([key, wallet]) => {
  switch (wallet.devices) {
    case "desktop":
      return isDesktop
    case "mobile":
      return isMobile
    default:
      return true
  }
})

const stateChangeTypeMaps: StateChangeTypeMap = {
  toDisconnected: {
    from: [WalletWidgetState.CONNECT_ERROR],
    to: WalletWidgetState.DISCONNECTED
  },
  toConnecting: {
    from: [WalletWidgetState.DISCONNECTED, WalletWidgetState.WALLET_CHANGING],
    to: WalletWidgetState.CONNECTING
  },
  toConnectError: {
    from: [WalletWidgetState.CONNECTING],
    to: WalletWidgetState.CONNECT_ERROR
  },
  toConnected: {
    from: [WalletWidgetState.CONNECTING],
    to: WalletWidgetState.CONNECTED
  },
  toWalletChanging: {
    from: [WalletWidgetState.CONNECTED],
    to: WalletWidgetState.WALLET_CHANGING
  }
}

const viewPropsMap = {
  [WalletWidgetState.DISCONNECTED]: (
    props: Pick<DisconnectedViewProps, "walletStates" | "walletButtonClickHandler">
  ) => {
    const { walletStates, walletButtonClickHandler } = props

    return {
      walletStates,
      walletList: (
        <WalletButtons onWalletButtonClick={walletButtonClickHandler} connectedWallet={walletStates.connectedWallet} />
      ),
      walletButtonClickHandler
    }
  },
  [WalletWidgetState.CONNECTING]: (props: Pick<ConnectingViewProps, "connectingWallet">) => {
    const { connectingWallet } = props

    return {
      connectingWallet
    }
  },
  [WalletWidgetState.CONNECT_ERROR]: (
    props: Pick<ConnectErrorViewProps, "connectingWallet" | "onPrevClick" | "errorMsg">
  ) => {
    const { connectingWallet, onPrevClick, errorMsg } = props

    return {
      connectingWallet,
      onPrevClick,
      errorMsg
    }
  },
  [WalletWidgetState.CONNECTED]: (props: ConnectedViewProps) => {
    const { walletStates, onChangeClick } = props

    return {
      walletStates,
      onChangeClick
    }
  },
  [WalletWidgetState.WALLET_CHANGING]: (
    props: Pick<WalletChangingViewProps, "walletStates" | "onPrevClick" | "walletButtonClickHandler">
  ) => {
    const { walletStates, onPrevClick, walletButtonClickHandler } = props

    return {
      walletStates,
      walletList: (
        <WalletButtons onWalletButtonClick={walletButtonClickHandler} connectedWallet={walletStates.connectedWallet} />
      ),
      walletButtonClickHandler,
      prev: true,
      onPrevClick
    }
  }
}
const getViewProps = ({
  currentState,
  connectingWallet,
  walletStates,
  setState,
  handleWalletButtonClick
}: {
  currentState: WalletWidgetWizardProps["currentState"]
  connectingWallet: WalletWidgetWizardProps["connectingWallet"]
  walletStates: WalletWidgetWizardProps["walletStates"]
  setState: WalletWidgetWizardProps["setState"]
  handleWalletButtonClick: WalletWidgetWizardProps["onWalletButtonClick"]
}) => {
  switch (currentState) {
    case WalletWidgetState.DISCONNECTED:
      return viewPropsMap.Disconnected({
        walletStates,
        walletButtonClickHandler: handleWalletButtonClick
      })
    case WalletWidgetState.CONNECTING:
      return viewPropsMap.Connecting({
        connectingWallet
      })
    case WalletWidgetState.CONNECT_ERROR:
      return viewPropsMap.ConnectError({
        connectingWallet,
        onPrevClick: () => setState(WalletWidgetState.CONNECT_ERROR, stateChangeTypeMaps.toDisconnected.to),
        errorMsg: walletStates.errorOnWalletConnection
      })
    case WalletWidgetState.CONNECTED:
      return viewPropsMap.Connected({
        walletStates,
        onChangeClick: () => setState(WalletWidgetState.CONNECT_ERROR, stateChangeTypeMaps.toWalletChanging.to)
      })
    case WalletWidgetState.WALLET_CHANGING:
      return viewPropsMap.WalletChanging({
        walletStates,
        onPrevClick: () => setState(WalletWidgetState.WALLET_CHANGING, stateChangeTypeMaps.toConnected.to),
        walletButtonClickHandler: handleWalletButtonClick
      })
  }
}

/**
 * Styles
 */
const ConnectedNoteRoot = styled(MuiBox)(({ theme }) => ({
  "&": {
    marginBlockStart: theme.spacing(-1)
  },
  "& *": {
    color: theme.palette.positive.main
  }
}))

/**
 * Components
 */
const ConnectedNote = () => {
  const intl = useIntl()
  const [connected] = [intl.formatMessage({ id: "CONNECTED" })]

  return (
    <ConnectedNoteRoot display="flex" alignItems="center" gap={1}>
      <Icon iconKey="checkCircle" size="xs1" />
      <Typography variant="callout">{connected}</Typography>
    </ConnectedNoteRoot>
  )
}

const WalletButtons = ({ onWalletButtonClick, connectedWallet = null }: WalletButtonsProps) => (
  <MuiBox>
    {validSupportedWallets.map(([, wallet], index) =>
      wallet ? (
        <MuiBox key={index} mb={2} data-test-id={`connectButton-${wallet.name}`}>
          <WalletButton
            wallet={wallet}
            disabled={connectedWallet?.name === wallet.name}
            onWalletButtonClick={async (wallet: SupportedWalletShape) =>
              await onWalletButtonClick(wallet as SupportedWallet)
            }
            supportingElement={connectedWallet?.name === wallet.name && <ConnectedNote />}
          />
        </MuiBox>
      ) : null
    )}
  </MuiBox>
)

/**
 * Renderers
 */
const renderers = {
  [WalletWidgetState.DISCONNECTED]: (props: WalletWidgetProps) => (
    <DisconnectedView {...(props as DisconnectedViewProps)} />
  ),
  [WalletWidgetState.CONNECTING]: (props: WalletWidgetProps) => <ConnectingView {...(props as ConnectingViewProps)} />,
  [WalletWidgetState.CONNECT_ERROR]: (props: WalletWidgetProps) => (
    <ConnectErrorView {...(props as ConnectErrorViewProps)} />
  ),
  [WalletWidgetState.CONNECTED]: (props: WalletWidgetProps) => <ConnectedView {...(props as ConnectedViewProps)} />,
  [WalletWidgetState.WALLET_CHANGING]: (props: WalletWidgetProps) => (
    <WalletChangingView {...(props as WalletChangingViewProps)} />
  )
}

/**
 * Main
 */
const WalletWidgetWizard: React.FC<WalletWidgetWizardProps> = ({
  currentState,
  walletStates,
  stateChangeType,
  connectingWallet,
  onWalletButtonClick,
  setState
}) => {
  /**
   * State changing actions triggered by `stateChangeType` change.
   */
  useEffect(() => {
    if (!stateChangeType) return
    if (!stateChangeTypeMaps[stateChangeType].from.includes(currentState)) {
      // console.warn(`state "${currentState}" is not allowed to transition through route "${stateChangeType}".`)
      return
    }

    setState(currentState, stateChangeTypeMaps[stateChangeType].to)
  }, [stateChangeType])

  /**
   * Main renderer
   */
  return (
    <div data-test-id="walletWidget">
      {renderers[currentState](
        getViewProps({
          currentState,
          handleWalletButtonClick: onWalletButtonClick,
          connectingWallet,
          setState,
          walletStates
        })
      )}
    </div>
  )
}

export default memo(WalletWidgetWizard)
