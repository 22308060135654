import { useEffect, useContext, useState, useCallback, useRef } from "react"
import { useIntl } from "react-intl"
import { ethers } from "ethers"
import { SupportedWalletShape } from "@stakefish/ui/components/WalletButton"

import { SUPPORTED_WALLETS } from "../../consts/wallet"
import { STAKE_INPUT_ERROR_MESSAGES, MINIMUM_STAKING_VALUE } from "../../consts/staking"
import { WalletWidgetWizardProps } from "../../containers/WalletWidgetWizard"
import { AppContext, DefaultContext } from "../../context"
import { dispatchWalletConnectionError, dispatchWalletInfo, dispatchStakingStatesReset } from "../../context/actions"
import { WalletError } from "../../types/web3"
import { GlobalState } from "../../types/global"
import { StakingStep } from "../../types/staking"
import { snackPackProps } from "../../types/snackbar"
import { SupportedWallet, WalletWidgetState, WalletType, WalletState } from "../../types/wallet"
import { usePrevious } from "../usePrevious"
import { useSnackPack } from "../useSnackPack"
import { SetStep } from "../useStep"
import { useStoredData } from "../useStoredData"
import { useMatomo } from "../useMatomo"
import { useMetaMask } from "./useMetaMask"
import { useWalletConnect } from "./useWalletConnect"
import { FetchWallet, FetchWalletCallback, HandleAccountsChanged } from "./types"

/**
 * Types
 */
type SetWalletWidgetStateChangeType = (
  value: React.SetStateAction<WalletWidgetWizardProps["stateChangeType"] | null>
) => void

interface UseWalletProps {
  setWalletWidgetState: React.Dispatch<React.SetStateAction<WalletWidgetState>>
  setWalletWidgetStateChangeType: SetWalletWidgetStateChangeType
  setConnectingWallet: React.Dispatch<React.SetStateAction<SupportedWallet | null>>
  setStep: SetStep
}

const { MetaMask, WalletConnect } = SUPPORTED_WALLETS

/**
 * Main
 */
export const useWallet = (props: UseWalletProps) => {
  const { formatMessage } = useIntl()
  const { setConnectingWallet, setWalletWidgetStateChangeType, setWalletWidgetState, setStep } = props

  const { state, dispatch, provider, setProvider } = useContext(AppContext as DefaultContext)
  const [walletChecked, setWalletChecked] = useState(false)
  const { storageWalletConnected, setStorageWalletConnected, resetLocalStorage } = useStoredData()
  const { openSnackPack } = useSnackPack()

  const currentStepRef = useRef(state.staking.currentStep)
  currentStepRef.current = state.staking.currentStep
  const stateWalletRef = useRef(state.wallet)
  stateWalletRef.current = state.wallet

  const { trackEvent } = useMatomo()

  /***********************************/
  /* Helpers                         */
  /***********************************/
  /**
   * Bounded dispatches
   */
  const boundedDispatchWalletInfo = dispatchWalletInfo(dispatch)
  const boundedDispatchWalletConnectionError = dispatchWalletConnectionError(dispatch)
  const boundedDispatchStakingStatesReset = dispatchStakingStatesReset(dispatch)

  /**
   *
   * To connect with wallet provider through `WalletWidgetWizard` element.
   *
   * @param  {SupportedWallet} wallet
   * @param  {()=>Promise<void>} connectWalletCallback From individual wallet hook (ex: `useMetaMask`)
   *
   */
  const connectWallet = useCallback(
    async (wallet: SupportedWalletShape, connectWalletCallback: () => Promise<void>) => {
      setConnectingWallet(wallet as SupportedWallet)
      setWalletWidgetStateChangeType("toConnecting")

      try {
        // Fetching and updating wallet data/states take place inside of the callback.
        await connectWalletCallback()

        setWalletWidgetStateChangeType("toConnected")
        setStorageWalletConnected(wallet.name as WalletType)
        dispatch({ type: "SET_MODAL_OPEN", payload: { field: GlobalState.MODAL_OPEN, value: false } })

        // Matomo
        trackEvent({ category: "wallet", action: "connected", name: `${currentStepRef.current}-${wallet.name}` })
      } catch (e: any) {
        handleError(e)
      }
    },
    []
  )

  /**
   *
   * To fetch and update wallet data and states.
   *
   * @param  {() => Promise<any>} fetchWalletCallback From individual wallet hook (ex: `useMetaMask`)
   *
   */
  const fetchWallet: FetchWallet = useCallback(async (fetchWalletCallback) => {
    try {
      // Fetching wallet data takes place inside of the callback.
      const walletInfo = await fetchWalletCallback()

      setProvider(walletInfo.web3Provider)
      boundedDispatchWalletInfo(walletInfo)
      setWalletWidgetState(WalletWidgetState.CONNECTED)
    } catch (e: any) {
      handleError(e)
    }
  }, [])

  /**
   *
   * Resets the app state in localstorage
   *
   */
  const resetStoredDataAndReload = useCallback((removeWallet: boolean = false) => {
    resetLocalStorage(removeWallet)

    setTimeout(() => {
      window.location.reload()
    }, 1)
  }, [])

  /**
   *
   * Actions to handle global states at each step
   * when resetting the app's current step.
   *
   */
  const handleStepReset = useCallback(async (fetchWalletCallback?: FetchWalletCallback) => {
    const handleFetchWallet = async () => {
      if (!fetchWalletCallback) {
        return
      }

      await fetchWallet(fetchWalletCallback)
    }

    setWalletChecked(false)

    switch (currentStepRef.current) {
      case StakingStep.StakeAmount:
        await handleFetchWallet()
        boundedDispatchStakingStatesReset()

        setWalletChecked(true)
        break
      case StakingStep.Review:
      case StakingStep.Sign:
        await handleFetchWallet()
        boundedDispatchStakingStatesReset()
        setStep(currentStepRef.current, "prev", {
          targetPrevStep: StakingStep.StakeAmount
        })

        setWalletChecked(true)
        break
      case StakingStep.Broadcasting:
      case StakingStep.Done:
        resetStoredDataAndReload()
        break
    }
  }, [])

  /**
   *
   * Error states
   *
   */
  const handleError = useCallback((e: WalletError) => {
    boundedDispatchWalletConnectionError(e)
    setWalletWidgetStateChangeType("toConnectError")
    setStorageWalletConnected(undefined)
  }, [])

  /**
   *
   * On chain changed (per EIP-1193)
   *
   */
  const handleChainChanged = useCallback(() => {
    resetStoredDataAndReload()
  }, [])

  /**
   *
   * On account changed (per EIP-1193)
   *
   */
  const handleAccountsChanged: HandleAccountsChanged = useCallback(
    async (accounts, fetchWalletCallback, walletType) => {
      // Ignore unconnected accounts change event
      if (walletType !== stateWalletRef.current.connectedWallet?.name) return

      // Disconnected
      if (accounts.length === 0) {
        handleDisconnect()

        // Matomo
        trackEvent({
          category: "wallet",
          action: "disconnected",
          name: `${currentStepRef.current}-${stateWalletRef.current.connectedWallet?.name}`
        })
        return
      }

      // Active account changed
      if (stateWalletRef.current.address !== accounts[0]) {
        await handleStepReset(fetchWalletCallback)
      }
    },
    []
  )

  /**
   *
   * On disconnects (per EIP-1193)
   *
   */
  const handleDisconnect = useCallback(() => {
    boundedDispatchWalletInfo()

    resetStoredDataAndReload(true)
  }, [])

  /**
   *
   * On provider change (per EIP-1193)
   *
   */
  const handleProviderChange = useCallback((previousWalletProvider: WalletType | undefined) => {
    const providerChanged =
      previousWalletProvider !== undefined &&
      stateWalletRef.current.connectedWallet?.name !== undefined &&
      previousWalletProvider !== stateWalletRef.current.connectedWallet.name

    if (!providerChanged) {
      return
    }

    handleStepReset()
  }, [])

  /**
   *
   * Connect wallets
   *
   */
  const handleConnectWallet = useCallback(async (wallet: SupportedWallet) => {
    switch (wallet.name) {
      case "MetaMask":
        connectWallet(MetaMask, connectMetaMaskCallback)
        break
      case "WalletConnect":
        connectWallet(WalletConnect, connectWalletConnectCallback)
        break
      default:
      // do nothing
    }
  }, [])

  /**
   * Updates wallet balance every block.
   *
   * IMPORTANT! DO NOT WRAP IN `useCallback`!
   * If wrapped in useCallback it will not be executed until wallet address doesn't change.
   * It needs to be executed on every new block.
   */
  const handleUpdateBalance = async () => {
    if (state.wallet.address === undefined) {
      return
    }
    try {
      // TODO: handle error here
      if (!provider) throw new Error("")
      const balance = await provider.getBalance(state.wallet.address)

      dispatch({
        type: "SET_BALANCE",
        payload: {
          field: WalletState.BALANCE,
          value: Number(ethers.utils.formatEther(balance))
        }
      })
    } catch (e) {
      // do nothing
    }
  }

  /**
   * Triggered on every new block (every 10-20s).
   *
   * It doesn't make sense to wrap it into `useCallback` because it will never use the memoized callback.
   * It is always called with a unique block number
   *
   * @param blockNumber
   */
  const handleBlock = (blockNumber: number) => {
    handleUpdateBalance()
  }

  /***********************************/
  /* Activating hooks                */
  /***********************************/
  const { connectWalletCallback: connectMetaMaskCallback } = useMetaMask({
    connectWallet,
    fetchWallet,
    handleChainChanged,
    handleAccountsChanged,
    handleDisconnect
  })

  const { connectWalletCallback: connectWalletConnectCallback } = useWalletConnect({
    connectWallet,
    fetchWallet,
    handleChainChanged,
    handleAccountsChanged,
    handleDisconnect
  })

  /***********************************/
  /* Events                          */
  /***********************************/
  /**
   *
   * Handle provider change
   *
   */
  const previousWalletProvider = usePrevious(state.wallet.connectedWallet?.name)
  useEffect(() => {
    handleProviderChange(previousWalletProvider)
  }, [state.wallet.connectedWallet?.name])

  /**
   *
   * Handle wallet checked state
   *
   */
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (!storageWalletConnected || state.wallet.connectedWallet || state.wallet.errorOnWalletConnection) {
      setWalletChecked(true)
    }
  }, [state.wallet.connectedWallet, state.wallet.errorOnWalletConnection])

  /**
   *
   * Check and update snackbar on wallet connected state.
   *
   */
  useEffect(() => {
    const walletUnconnected = walletChecked && !storageWalletConnected && !state.wallet.connectedWallet

    if (walletUnconnected) {
      openSnackPack({
        ...snackPackProps.walletUnconnected,
        children: formatMessage({ id: "BEFORE_STAKING_PLEASE_CONNECT_YOUR_WALLET" })
      })
    }

    if (!walletUnconnected && state.wallet?.network) {
      const walletHasEnoughBalance = state.wallet.balance && state.wallet.balance > MINIMUM_STAKING_VALUE
      const isMainnetNetwork = state.wallet.network.chainId === 1

      if (walletHasEnoughBalance ?? isMainnetNetwork) {
        return
      }

      openSnackPack({
        ...snackPackProps.insufficientFund,
        children: STAKE_INPUT_ERROR_MESSAGES.overWalletBalance
      })
    }
  }, [walletChecked])

  /**
   *
   * Block events
   *
   */
  useEffect(() => {
    if (provider) {
      provider.on("block", handleBlock)

      return () => {
        provider.off("block", handleBlock)
      }
    }
  }, [provider, state.wallet.address])

  return {
    walletChecked,
    connectWallet: handleConnectWallet
  }
}
